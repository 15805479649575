<template>
	<div class="maincontainer" :class="{ ' h-screen flex flex-col justify-center items-center': !propData }">
		<div class=" card screenCard flex flex-row h-full">
			<div class="p-6 w-3/5 font-opensans">
				<h5 class="titleheader text-2xl mt-6 text-wrap font-bold">
					{{ templatePayload.headerLabel }}
				</h5>
				<p class="text-xs mt-4 text-wrap font-normal" v-html="templatePayload.headerData"></p>
			</div>
			<div class="w-2/5 bg-cover bg-no-repeat bg-center" :style="backgroundImages(templatePayload)"></div>
			
		</div>
		<div >
		<audio ref="player" preload="metadata" @loadedmetadata="controlEvents('start',$event)" @timeupdate="controlEvents('time',$event)" @ended="controlEvents('ended',$event)" >
			<source :src="link" type="audio/mp3" />
		</audio>
		<audio-video-control-bar :control="controlBarEvent" :type="true"> </audio-video-control-bar>
		</div>
	</div>
</template>
<style scoped>
.text-xs {
	font-size: 14px;
	line-height: 25px;
}

.screenCard {
	height: 430px;
	width: 940px;
	background-size: 100% 100%;
	border-radius: 4px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
@media only screen and (max-width: 1280px) {
	.screenCard {
		height: 380px;
		width: 940px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
}
@media only screen and (max-width: 1024px) {
	.screenCard {
		height: 325px;
		width: 680px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
}
@media only screen and (max-width: 768px) {
	.screenCard {
		height: 300px;
		width: 500px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
}
</style>  
<script lang="ts">
import { defineComponent } from 'vue';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
import audioVideoControlBar from "@/components/audioVideoControlBar.vue";
export default defineComponent({
	props: ['propData'],
	mixins: [globalApiMixin],
	data(): any {
		return {
			dataPopulated: false,
			templatePayload: {},

			link:'',
			controlBarEvent:'',
			screenType:''
		};
	},
	components:{
		audioVideoControlBar
	},
	mounted() {
		this.fetchData();
	},
	watch: {
		propData: function (newVal, oldVal) {
			// [{id: '2', text: 'test2'}]
			console.log('Prop changed: ', newVal, ' | was: ', oldVal);
			this.$forceUpdate();
		}
	},
	methods: {
		controlEvents(status:any,e:any){
			if (status == 'start') {
					this.controlBarEvent = {'start':e};
			} else if (status == 'time') {
					this.controlBarEvent = {'time':e};
			} else {
					this.controlBarEvent = {'ended':'ended'};
			}
		},
		backgroundImages(payload: any) {
			if (payload.imageUrl != '') {
				return {
					backgroundImage: `url("${payload.imageUrl}")`
				};
			} else {
				return {
					backgroundImage: `url("${process.env.VUE_APP_COMMON_IMAGE}")`
				};
			}
		},
		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
				this.screenType = this.propData.screenType;
				this.link = this.templatePayload.audioUrl;
				this.dataPopulated = true;
			} else {
				await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					this.link = this.templatePayload.audioUrl;
					this.screenType = response.data[0].screenType;
					this.dataPopulated = true;
				});
			}
			this.$refs.player.load();
		}
	}
});
</script>