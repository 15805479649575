<template>
	<div class="maincontainer flex justify-center items-center">
		<div class="screenCard bg-white flex justify-between items-center p-4 rounded">
			<button class="btnprimary md:ml-10" @click="modules('modules')">Course Home</button>
			<button class="btnprimary" @click="modules('nextmodule')" v-if="moduleIndex!=courseModules.length">Next Module</button>
			<button  class="btnprimary" :class="examStatus==true?'btnprimary':'btndisabled pointer-events-none'" @click="modules('startExam')" v-if="moduleIndex==courseModules.length && showExam">Start Test</button>		
			<button class="btnprimary md:mr-10" @click="modules('currentmodule')">Revisit Module</button>
		</div>
	</div>
</template>
<style scoped>
.screenCard {
	height: 460px;
	width: 940px;
	background-size: 100% 100%;
}

@media only screen and (max-width: 1280px) {
	.screenCard {
		height: 460px;
		width: 940px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
}
@media only screen and (max-width: 1024px) {
	.screenCard {
		height: 405px;
		width: 680px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
}
@media only screen and (max-width: 768px) {
	.screenCard {
		height: 380px;
		width: 500px;
		background-size: 100% 100%;
		border-radius: 4px;
		padding: 0%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	button{
		margin-bottom: 45px ;
	}
}
</style>  
<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
export default defineComponent({
	props: ['currentModule'],
	data(): any {
		return {
			moduleIndex: '',
			nextModules: '',
			showExam:false,
			courseModules:[],
			modulesData:[],
			examStatus:null,
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
	},
	methods:{
		modules(type: any) {
			if (type == 'nextmodule') {
				this.$emit('module', 'nextmodule');
			} else if (type == 'currentmodule') {
				this.$emit('module', 'currentmodule');
			} 
			else if (type == 'startExam') {
				this.$emit('module', 'startExam');
			}
			else {
				this.$emit('module', 'modules');
			}
		},
		async getModulesForCourse(){
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/user/${this.userInfo._id}/course/${this.$route.params.courseId}/userActivity`).then((res: any) => {
				this.modulesData = res.data[0].modules;
			})
		}
	},
	async mounted(){
		await this.getModulesForCourse();
		let examCondition = this.modulesData.every((obj:any)=>{
			return obj.completedScreens == obj.totalScreens
		});
		console.log('examexamCondition',examCondition)
		if(examCondition == true){
			this.examStatus = true;
		}else{
			this.examStatus = false;
		}
		this.moduleIndex=this.currentModule.moduleIndex+1;
		this.courseModules = this.currentModule.courses.modules.filter((module: any) =>{return module.screens && module.isModuleActive==true});
		let exam=this.courseModules.filter((module:any)=> Object.keys(module).includes('questionaire') )
		if(exam.length>0){
			this.showExam = true;
		}else{
			this.showExam = false;
		}
	}
	
});
</script>