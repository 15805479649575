import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-382bd06e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card flex" }
const _hoisted_2 = { class: "tabs -mb-px flex" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { class: "border-grey" }
const _hoisted_5 = {
  key: 0,
  class: "flex flex-row h-full"
}
const _hoisted_6 = { class: "font-opensans w-3/5 p-4" }
const _hoisted_7 = {
  key: 1,
  class: "p-4"
}
const _hoisted_8 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_audio_video_control_bar = _resolveComponent("audio-video-control-bar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["maincontainer", { ' h-screen flex flex-col justify-center items-center': !_ctx.propData }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templatePayload.tabs, (tab, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "tabstyling tab",
            onClick: ($event: any) => (_ctx.toggleTabs(index))
          }, [
            _createElementVNode("a", {
              class: _normalizeClass(["tab-lg tab-lifted text-center", { 'tab-active': _ctx.openTab == index ,'tab-notactive': _ctx.openTab != index }])
            }, _toDisplayString(tab.label), 3)
          ], 8, _hoisted_3))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_4, [
        (_ctx.filteredCourses.imageUrl)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.filteredCourses.text), 1),
              _createElementVNode("div", {
                class: "w-2/5 bg-cover bg-no-repeat bg-center",
                style: _normalizeStyle({ backgroundImage: 'url(' + _ctx.filteredCourses.imageUrl + ')' })
              }, null, 4)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, _toDisplayString(_ctx.filteredCourses.text), 1))
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("audio", {
        ref: "player",
        preload: "metadata",
        onLoadedmetadata: _cache[1] || (_cache[1] = ($event: any) => (_ctx.controlEvents('start',$event))),
        onTimeupdate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.controlEvents('time',$event))),
        onEnded: _cache[3] || (_cache[3] = ($event: any) => (_ctx.controlEvents('ended',$event)))
      }, [
        _createElementVNode("source", {
          onError: _cache[0] || (_cache[0] = ($event: any) => (_ctx.controlEvents('noSrc',$event))),
          src: _ctx.filteredCourses.audioUrl,
          type: "audio/mp3"
        }, null, 40, _hoisted_8)
      ], 544),
      _createVNode(_component_audio_video_control_bar, {
        control: _ctx.controlBarEvent,
        type: true
      }, null, 8, ["control"])
    ])
  ], 2))
}