import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/rightArrowFilled.svg'
import _imports_1 from '@/assets/downArrow.svg'


const _withScopeId = (n: any) => (_pushScopeId("data-v-24493222"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card screenCard flex flex-col p-6" }
const _hoisted_2 = { class: "questionblock h-3/12 font-opensans" }
const _hoisted_3 = { class: "titleheader text-2xl mt-2 text-primary font-bold" }
const _hoisted_4 = { class: "datastyle mt-1 text-black font-normal text-xs" }
const _hoisted_5 = { class: "p-2 h-9/12 mt-1" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "flex accordion-head bg-white py-2.5 px-2.5 text-lg rounded" }
const _hoisted_8 = { class: "w-2 flex items-center" }
const _hoisted_9 = {
  key: 0,
  src: _imports_0,
  class: "h-4 w-4 cursor-pointer"
}
const _hoisted_10 = {
  key: 1,
  src: _imports_1,
  class: "h-4 w-4 cursor-pointer"
}
const _hoisted_11 = {
  key: 0,
  class: "accordion-body p-1"
}
const _hoisted_12 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_audio_video_control_bar = _resolveComponent("audio-video-control-bar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["maincontainer", { ' h-screen flex flex-col justify-center items-center': !_ctx.propData }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.templatePayload.headerLabel), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.templatePayload.headerData), 1)
      ]),
      _createElementVNode("div", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templatePayload.accordions, (accordion, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: _normalizeClass(["text-black no-underline mt-1 min-h-30 cursor-pointer", { accordion: accordion.isActive }]),
            key: index,
            onClick: ($event: any) => (_ctx.onopenAccordion(index))
          }, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                (!accordion.isActive)
                  ? (_openBlock(), _createElementBlock("img", _hoisted_9))
                  : (_openBlock(), _createElementBlock("img", _hoisted_10))
              ]),
              _createElementVNode("div", {
                class: "title font-opensans text-lg w-98 ml-1 flex",
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isToggle()))
              }, _toDisplayString(accordion.label), 1)
            ]),
            (accordion.isActive)
              ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(accordion.text), 1))
              : _createCommentVNode("", true)
          ], 10, _hoisted_6))
        }), 128))
      ])
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("audio", {
        ref: "player",
        preload: "metadata",
        onLoadedmetadata: _cache[1] || (_cache[1] = ($event: any) => (_ctx.controlEvents('start',$event))),
        onTimeupdate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.controlEvents('time',$event))),
        onEnded: _cache[3] || (_cache[3] = ($event: any) => (_ctx.controlEvents('ended',$event)))
      }, [
        _createElementVNode("source", {
          src: _ctx.link,
          type: "audio/mp3"
        }, null, 8, _hoisted_12)
      ], 544),
      _createVNode(_component_audio_video_control_bar, {
        control: _ctx.controlBarEvent,
        type: true
      }, null, 8, ["control"])
    ])
  ], 2))
}