<template>

	<div class="maincontainer" :class="{ ' h-screen flex flex-col justify-center items-center': !propData }">
		<div class="screenCard flex flex-row justify-center items-center w-full">
			<video ref="player" preload="metadata" @loadedmetadata="start($event)" @dblclick.stop="fullScreenToggle" @timeupdate="update($event)" @click.prevent="playPause" @ended="playPause('ended')">
				<source type="video/mp4" :src="link" />
				Your browser does not support the video tag.
			</video>
		</div>
		<div class="bg-lightgrey h-20 width relative controlBar rounded-b-lg">
			<div class="mx-2.5 top-3 w-inherit relative">
				<div class="h-1.5 bg-white rounded-2xl w-full absolute">
					<div class="bg-primary rounded-2xl h-full w-0 absolute z-50 flex justify-end" ref="progress" :style="progress">
						<img src="@/assets/progressButton.svg" class="playButton" v-if="playerButton" />
					</div>
				</div>
				<div class="flex justify-between relative top-3 text-xs"  :class="{'h-4':videoTotalDuration ==''}" >
					<div>{{ videoCurrentposition }}</div>
					<div>{{ videoTotalDuration }}</div>
				</div>
				<div class="flex justify-center items-center mt-4">
					<div class="mr-11" @click.stop="mute">
						<img src="@/assets/volume.svg" alt="Volume"  :class="{'hidden':toggleMute,'block':!toggleMute}" />
						<img src="@/assets/mute.svg" alt="Mute" :class="{'hidden':!toggleMute,'block':toggleMute}" />
					</div>
					<div @click.stop="playPause">
						<img src="@/assets/playButton.svg" alt="Pause" :class="{'hidden':togglePlay,'block':!togglePlay}" />
						<img src="@/assets/pause.svg" alt="Play" :class="{'hidden':!togglePlay,'block':togglePlay}" />
					</div>
				</div>
			</div>
		</div>
	    <!-- <audio-video-control-bar :start=""></audio-video-control-bar> -->
	</div>
</template>
<style scoped>
video {
	object-fit: unset;
	height: 430px;
	width: 940px;
}
.screenCard {
	height: 430px;
	width: 940px;
	background-size: 100% 100%;
	border-radius: 4px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.width {
	width: 940px;
}
video::-webkit-media-controls-enclosure {
	display: none !important;
}
.playButton {
	position: absolute;
	z-index: 50;
	top: -3px;
	right: -1px;
	transform: translateZ(0); /*to rid of Wobbling*/
}
@media only screen and (max-width: 1280px) {
	.screenCard {
		height: 380px;
		width: 940px;
		background-size: 100% 100%;
		border-radius: 4px;
		padding-top: 60px;
	}
	video {
		object-fit: unset;
		height: 380px;
		width: 940px;
	}
}
@media only screen and (max-width: 1024px) {
	.screenCard {
		height: 325px;
		width: 680px;
		background-size: 100% 100%;
		border-radius: 4px;
		padding-top: 0px;
	}
	.width {
		width: 680px;
	}
	video {
		object-fit: unset;
		height: 325px;
		width: 680px;
	}
}
@media only screen and (max-width: 768px) {
	.screenCard {
		height: 300px;
		width: 500px;
		background-size: 100% 100%;
		border-radius: 4px;
		padding-top: 0px;
	}
	.width {
		width: 500px;
	}
	video {
		object-fit: unset;
		height: 300px;
		width: 500px;
	}
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import moment from 'moment';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
// import audioVideoControlBar from '@/components/audioVideoControlBar.vue';
export default defineComponent({
	// props: ['src', 'poster'],

	props: ['propData'],
    mixins: [globalApiMixin],
	data(): any {
		return {
			playerControls: '',
			videoCurrentposition: '',
			videoTotalDuration: '',
			duration: '',
			progress: '',
			playerButton: false,
			toggleMute: false,
			togglePlay: false,
			link: '',
			poster: '',

			dataPopulated: false,
			templatePayload: {}
		};
	},
	async mounted() {
		await this.fetchData();
		this.$refs.player.muted = false;
		document.addEventListener('keydown', (event) => {
			if (event.key == 'MediaPlayPause') {
				this.playPause();
			}
		});
	},
	methods: {
		mute() {
			this.toggleMute == false ? ((this.toggleMute = true), (this.$refs.player.muted = true)) : ((this.toggleMute = false), (this.$refs.player.muted = false));
		},
		playPause(status: any) {
			if (status == 'ended') {
				this.$refs.player.pause(), (this.togglePlay = false);
				this.$emit('video',true);
			} else {
				this.$refs.player.paused == false ? (this.$refs.player.pause(), (this.togglePlay = false)) : (this.$refs.player.play(), (this.togglePlay = true));
			}
		},
		start(e: any) {
			this.videoCurrentposition = this.formatTime(e.srcElement.currentTime);
			this.videoTotalDuration = this.formatTime(e.srcElement.duration);
			this.duration = e.srcElement.duration;
			this.progressPercentage(e.srcElement.currentTime, this.duration);
			this.$emit('video',false);
		},
		fullScreenToggle() {
			if (document.fullscreenElement) {
				document.exitFullscreen();
			} else {
				this.$refs.player.requestFullscreen();
			}
		},
		formatTime(sec: any) {
			var hours: any = Math.floor(sec / 3600);
			hours >= 1 ? (sec = sec - hours * 3600) : (hours = '00');
			var min: any = Math.floor(sec / 60);
			min >= 1 ? (sec = sec - min * 60) : (min = '00');
			sec < 1 ? (sec = '00') : void 0;
			sec = parseInt(sec);
			min.toString().length == 1 ? (min = '0' + min) : void 0;
			sec.toString().length == 1 ? (sec = '0' + sec) : void 0;
			if (hours == '00') return min + ':' + sec;
			else return hours + ':' + min + ':' + sec;
		},
		update(e: any) {
			this.videoCurrentposition = this.formatTime(e.srcElement.currentTime);
			this.progressPercentage(e.srcElement.currentTime, this.duration);
		},
		progressPercentage(currentTime: any, duration: any) {
			var widthModified: any = ((currentTime / duration) * 100).toFixed(2);
			this.progress = { width: widthModified + '%' };
			((widthModified/100)*940 > 11) ? (this.playerButton = true) : '';
		},
		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
                this.link = this.templatePayload.videoUrl;
				this.dataPopulated = true;
			} else {
				await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					this.link = this.templatePayload.videoUrl;
					this.$refs.player.load();
				});
			}
		}
	}
});
</script>