<template>
	<div class="flexcontainer pt-3 px-5">
		<div>
				<breadCrumb :routesArray="routesArray"></breadCrumb>
		</div>
		<div class="pagetitle flex justify-between">
			<div class="header flex items-center justify-between">
				<!-- <div><img src="@/assets/arrowLeftNavigation.svg" class="w-6 h-6 mr-4" /></div> -->
				<h1>Questionnaire</h1>
			</div>
			<div>
				<button class="btnprimary" @click="addquestions()">Add Questions</button>
			</div>
		</div>
		<div>
		<div class="mt-3" v-if="questionaire.length > 0">
			<div class="text-black no-underline mt-3 min-h-30 cursor-pointer rounded" v-for="(singlequestion, moduleIndex) in questionaire" :key="singlequestion.id" :class="{ accordion: singlequestion.isActive }">
			
					
				<div class="flex bg-white py-2.5 px-2.5 font-opensans  text-sm w-full rounded" @click="questionSelect(singlequestion,moduleIndex)" :class="{ 'accordion-head': !singlequestion.isActive }">
					<div class="flex items-center mx-2.5" >
						<img src="@/assets/rightArrowFilled.svg" class="h-4 w-4 cursor-pointer"  v-if="!singlequestion.isActive"/>
						<img src="@/assets/downArrow.svg" class="h-4 w-4 cursor-pointer" v-else />
					</div>

					<!-- <div
						class="title flex font-opensans text-sm ml-1"
						@click.stop="toggleTheQuestionName(singlequestion,moduleIndex)"
						v-bind:class="{
							'error-border': singlequestion.validationScreenName
						}"
					> -->
						<div>
							<!-- <input 
								v-if="singlequestion.toggleSourceInput" 
								@blur.stop="questionUnique ? singlequestion.toggleSourceInput = false : singlequestion.toggleSourceInput = true"
							 	type="text" class="inputboxstyling"
							  	v-model="singlequestion.questionaireName" 
							  	@change="changedInputControlValue(singlequestion)"
								:class="{
									'is-invalid borderRed': questionUnique
								}"
							 /> -->
							<!-- <input 
								v-if="singlequestion.toggleSourceInput"
								@blur="singlequestion.toggleSourceInput = false" 
								type="text" 
								class="inputboxstyling" 
								v-model="singlequestion.questionaireName" 
								@change="changedInputControlValue(singlequestion)" 
							/> -->

							<!-- <div v-if="questionUnique && singlequestion.toggleSourceInput==true" class="text-red-500">
								<div class="error-text">Question Already Exists</div>			
							</div> -->

							<h6 >
								{{ singlequestion.questionaireName }}
							</h6>
						</div>
						
						<!-- <div class="controlicons flex p-1 mt-0.5 text-grey">
							<img src="@/assets/editIcon.svg" class="h-2.5 w-2.5" />
						</div> -->
					<!-- </div> -->

					<div class="titlehead w-68 "></div>
					
				</div>
				<div class="accordion-body bg-white" v-if="singlequestion.isActive">
					<hr class="border-t-2" />
					<form @change="changedInputControlValue(singlequestion)">
				<div class="p-2.5">

					<div>
						<label class="controllabel font-semibold text-xs mb-2 fontFamily-roboto">Question Name</label>
						<input 
							type="text" 
							class="inputboxstyling" 
							v-model="singlequestion.questionaireName"
							:class="{
								'error-border': singlequestion.validationScreenName && validCode == singlequestion.questionaireName
							}"
						/>
						<div v-if="singlequestion.validationScreenName && validCode == singlequestion.questionaireName">
							<div v-if="singlequestion.validationScreenName && validCode == singlequestion.questionaireName" class="text-red-500 text-xs">Question Name Already Exist</div>
						</div>
					</div>
				<div>
					<label class="controllabel font-semibold text-xs mb-2 fontFamily-roboto">Select Screen</label>
					<select class="inputboxstyling" v-model="singlequestion.questionaireType">
						<option value="" selected hidden disabled>Please select question type</option>
						<option>RadioQuestionType</option>
						<option>ChecBoxQuestionType</option>
					</select>
				</div>
				<div class="formgroup mt-3">
					<label class="controllabel font-semibold text-xs mb-2 fontFamily-roboto">Enter the Question</label>
					<input
						type="text"
						class="inputboxstyling"
						placeholder="Question"
						v-model="singlequestion.question"
						:class="{
							'error-border': (v$.questionaire.$error && (!v$.questionaire.$each.$response.$data[moduleIndex].question.required || !v$.questionaire.$each.$response.$data[moduleIndex].question.questionLimit))
						}"
						@input="v$.questionaire.$touch()"
					/>
					<div v-if="v$.questionaire.$error">
						<div v-if="(!v$.questionaire.$each.$response.$data[moduleIndex].question.required)" class="text-red-500 text-xs">Required</div>
						<div class="text-red-500 text-xs" v-else-if="!v$.questionaire.$each.$response.$data[moduleIndex].question.questionLimit">Limit Exceeded</div>
					</div>
				</div>
				<div class="radioblock" v-if="singlequestion.questionaireType == 'RadioQuestionType'">
					<div>
						<div class="optionsblock">
							<label class="controllabel font-semibold text-sm mt-3 fontFamily-roboto">Enter the option</label>
						</div>
						<div class="optionsblock card">
							<div v-for="(singleoption, optionIndex) in singlequestion.options" :key="singleoption.id" class="px-2">
								<div class=" accordion-head  mt-3 p-1.5 text-black flex justify-between bg-white min-h-30" :class="{
													'error-border p-1': singlequestion.options.length < 2
												}">
									<div class="imgoptioncontainer flex">
										<div v-if="singleoption.correct" class="mt-1">
											<img src="@/assets/correctIcon.svg" class="h-5 w-5" />
										</div>
										<div v-else class="mt-1">
											<img src="@/assets/cancel.svg" class="h-5 w-5" />
										</div>
										<div class="ml-2">
											{{ singleoption.text }}
										</div>
									</div>
									<div @click="onradioDelete(optionIndex, moduleIndex)">
										<img src="@/assets/deleteIcon.svg" class="h-5 w-5" />
									</div>

								</div>
								<div v-if="singlequestion.options.length < 2" class="text-red-500 mt-1">
 													 <div class="error-text">At least 2 options are required.</div>
												</div>
								
							</div>
							<div class=" text-black no-underline mt-6 min-h-30 ">
								<!-- <div class=" controllabel flex justify-between bg-white py-2.5 px-2  text-xs">options to add</div> -->
								<div class="accordion-body white p-5">
									<div class="formgroup ">
										<label class="controllabel font-semibold text-sm mb-2 fontFamily-roboto">Enter Option</label>
										<input type="text" class="inputboxstyling" placeholder="Name of the Option" v-model="singlequestionObj.optionsvalue" 
										:class="{
											'error-border p-1': (v$.singlequestionObj.$error && v$.singlequestionObj.optionsvalue && singlequestion.options.length < 2) || (singlequestionObj.optionsvalue != undefined && singlequestionObj.optionsvalue.length > 100)
										}" />
										<div v-if="v$.singlequestionObj.$error" class="text-red-500 mt-1">
											<div  v-if="v$.singlequestionObj.optionsvalue && singlequestion.options.length < 2" class="error-text">Required</div>
										</div>
										<div v-if="singlequestionObj.optionsvalue != undefined && singlequestionObj.optionsvalue.length > 100" class="text-red-500 mt-1">
											<div class="error-text">Limit Exceeded</div>
										</div>

									</div>
									<div class="formgroup mt-3">
										<label class="controllabel font-semibold text-sm mb-2 fontFamily-roboto">Enter Option Description</label>
										<input type="text" class="inputboxstyling " placeholder="If option is right why or wrong why" v-model="singlequestion.optiondescription" />
									</div>
									<div v-if="questionaire[moduleIndex].answerVisibility">
										<div class="formgroup mt-2">
											<input type="checkbox" class="checkbox" v-model="singlequestion.correctAnswer" />
											<span class="validateoption ml-3">If right please check</span>
										</div>
									</div>
								</div>
								<div class="buttonposition flex items-center justify-center my-1.5 mx-0">
									<button type="button" :class="singlequestionObj.optionsvalue == '' || singlequestionObj.optionsvalue == undefined || singlequestionObj.optionsvalue.length > 100 || (singlequestion.options.length == 5 || singlequestion.options.length > 5)?'btndisabled pointer-events-none':'btnprimary'"  class="m-2.5" @click="questionRadioScreen(moduleIndex)">Add</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="textboxblock" v-if="singlequestion.questionaireType == 'TextBoxQuestionType'">
					<div class="formgroup"><label class="controllabel font-semibold text-sm mb-1 fontFamily-roboto">Enter the Text</label> 
					<input type="text" class="inputboxstyling" placeholder="" v-model="singlequestion.answer" /></div>
					<div class="formgroup">
						<label class="controllabel font-semibold text-sm mb-1 fontFamily-roboto">Enter Option Description</label>
						<input type="text" class="inputboxstyling" placeholder="If it is right why or wrong why" v-model="singlequestion.answerDescription" />
					</div>
				</div>
				<div class="checkboxblock" v-if="singlequestion.questionaireType == 'ChecBoxQuestionType'">
					<div>
						<div class=" optionsblock mt-3">
							<label class="controllabel font-semibold text-xs mt-3 fontFamily-roboto">Enter the option</label>
						
							<div v-for="(singleoption, optionIndex) in singlequestion.options" :key="singleoption.id" >
								<div class="accordion-head mt-2.5 p-1.5 text-black flex justify-between bg-white min-h-30">
									<div class="imgoptioncontainer flex">
										<div v-if="singleoption.correct" class="mt-1">
											<img src="@/assets/correctIcon.svg" class="h-5 w-5" />
										</div>
										<div v-else class="mt-1">
											<img src="@/assets/cancel.svg" class="h-5 w-5" />
													</div>
										<div class="ml-2">
											{{ singleoption.text }}
										</div>
									</div>
									<div @click="oncheckBoxDelete(optionIndex, moduleIndex)">
										<img src="@/assets/deleteIcon.svg" class="h-5 w-5" />
									</div>
								</div>
							</div>
							<div class="text-black no-underline mt-6 min-h-30 ">
								<!-- <div class=" controllabel flex justify-between bg-white py-2.5 px-2 text-xs ">options to add</div> -->
								<div class="accordion-body white p-5">
									<div class="formgroup">
										<label class="controllabel font-semibold text-sm mb-1 fontFamily-roboto">Enter Option</label>
										<input type="text" class="inputboxstyling" placeholder="Name of the Option" v-model="singlequestion.optionsvalue" 
										:class="{
											'error-border p-1': (singlequestion.optionsvalue != undefined && singlequestion.optionsvalue.length > 100)
										}"/>
										<div v-if="singlequestion.optionsvalue != undefined && singlequestion.optionsvalue.length > 100" class="text-red-500 mt-1">
											<div class="error-text">Limit Exceeded</div>
										</div>

									</div>
									<div class="formgroup">
										<label class="controllabel font-semibold text-sm mb-1 fontFamily-roboto">Enter Option Description</label>
										<input type="text" class="inputboxstyling" placeholder="If option is right why or wrong why" v-model="singlequestion.optiondescription" />
									</div>
									<div>
										<div class="formgroup mt-2">
											<input type="checkbox" class="checkbox" v-model="singlequestion.correctAnswer" />
											<span class="validateoption ml-2.5">If right please check</span>
										</div>
									</div>
								</div>
								<div class="buttonposition flex items-center justify-center my-1.5 mx-0">
									<button type="button" :class="singlequestion.optionsvalue == '' || singlequestion.optionsvalue == undefined || singlequestion.optionsvalue.length > 100 || (singlequestion.options.length == 5 || singlequestion.options.length > 5)?'btndisabled pointer-events-none':'btnprimary'"  class="m-2.5" @click="questioncheckBoxScreen(moduleIndex)">Add</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				</div>
						<div class="p-4">
							<div class="flex justify-center">
								<div class="flex justify-center items-center" v-if="singlequestion._id">
									<button type="button" :class="JSON.stringify(singlequestion)!==JSON.stringify(dummyQuestionaire)? 'btnprimary':'btndisabled pointer-events-none'" @click="resetData(singlequestion,moduleIndex)" >Reset</button>
								</div>
								<div class="buttonposition flex justify-center items-center ml-6" v-else>
									<button  type="button" class="btnprimary" @click="resetData(singlequestion)" >Reset</button>
								</div>
								<div class="buttonposition flex justify-center items-center ml-6" v-if="singlequestion._id" >
									<button  type="button"  :class="singlequestion._id && (JSON.stringify(singlequestion)!==JSON.stringify(dummyQuestionaire)) ?  'btnprimary' : 'btndisabled pointer-events-none'" @click="createPayload(moduleIndex)">Update</button>
								</div>
								<div class="flex justify-center items-center ml-6" v-else >
									<button  type="button" :class="JSON.stringify(dummyQuestionaire)!==JSON.stringify(singlequestion) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="createPayload(moduleIndex)">Save</button>

								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
			<div class="text-center flex justify-center items-center text-sm mt-8" v-else>
				<div>No Questions. Please start adding by clicking on Add button on top.</div>
			</div>
	</div>
	</div>
</template>
<style scoped>
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { toast } from '../../main';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import breadCrumb from '@/components/breadCrumb.vue';
import _ from 'lodash';
const questionLimit = (value:any) => value.length <= 300;

export default defineComponent({
	components :{
		breadCrumb

	},
	data(): any {
		return {
			v$: useVuelidate(),
			questionaire: [],
			updatescreen: false,
			newScreen: false,
			routesArray:[],
			dummyQuestionaire:null,
			singlequestionObj:{},
			previousquestionaire:[],
			dummyQuestion:[],
			dummy:[],
			totalQuestions:[],
			modIndex:'',
			validCode:''

		};
	},
	validations(smIndex: any) {
		return {
			questionaire: {
				$each: helpers.forEach({
					screenName: { required },
					questionaireType: { required },
					question: { required,questionLimit },
				})
			},
			singlequestionObj:{
					optionsvalue:{required}

			}
		};
	},
	// computed:{
	// 	questionUnique: function (): any {
	// 		const uniqueValues = new Set(this.questionaire.map((v: any) => v.questionaireName));

	// 		if (uniqueValues.size < this.questionaire.length) {
	// 			return true;

	// 		}else{
	// 			return false
	// 		}
	// 	}
	// },
	methods: {
		changedInputControlValue(question: any) {
			if (question._id) {
				question.updatescreen = true;
			} else {
				question.newScreen = true;
			}
		},
			resetData(question: any,index:any) {
				if(question._id){	
					this.questionaire[this.modIndex] = JSON.parse(JSON.stringify(this.dummyQuestionaire));			
				}				
				else{
					question.questionaireName= 'Question',
					question.questionaireType= '',
					question.question= '',
					question.options= [],
					question.createdBy= '',
					question.createdAt= '',
					question.updatedBy= '',
					question.updatedAt= '',
					question.validationScreenName= false,
					question.toggleSourceInput= false,
					question.answerVisibility= true
				}
			
		},
		addquestions() {
			if(this.modIndex != ''){
				this.questionaire[this.modIndex]['isActive'] = false;
				this.questionaire[this.modIndex].toggleSourceInput = false;
			}
			
			this.questionaire.push({
				questionaireName: 'Question',
				questionaireType: '',
				question: '',
				isActive:false,
				options: [],
				createdBy: '',
				createdAt: '',
				updatedBy: '',
				updatedAt: '',
				validationScreenName: false,
				toggleSourceInput: false,
				answerVisibility: true
			});
		},
		async createPayload(moduleIndex: any) {
			var finalpayload;
			this.v$.$touch();
			var QuestionaireId = this.questionaire[moduleIndex]._id;
			this.questionaire[moduleIndex].validationScreenName = false;

			this.validCode = this.questionaire[moduleIndex].questionaireName;


			delete this.questionaire[moduleIndex].updatescreen;
			delete this.questionaire[moduleIndex].newScreen;
			// this.dummyQuestionaire={...this.questionaire[moduleIndex]}
			this.questionaire[moduleIndex]['isActive'] = true;

			const uniqueValues = new Set(this.questionaire.map((v: any) => v.questionaireName));

			if (uniqueValues.size < this.questionaire.length) {
				this.questionaire[moduleIndex].validationScreenName = true;
				this.questionaire[moduleIndex].toggleSourceInput = true
			}

			let errorCondition = this.v$.questionaire.$each.$message[moduleIndex];
			// stop here if form is invalid

			if (errorCondition.length == 0 && !this.questionaire[moduleIndex].validationScreenName) {
				this.v$.$reset();
				delete this.questionaire[moduleIndex]['isActive'];
				delete this.questionaire[moduleIndex].optionsvalue;
				delete this.questionaire[moduleIndex].correctAnswer;
				delete this.questionaire[moduleIndex].optiondescription;
				delete this.questionaire[moduleIndex].answerVisibility;
				delete this.questionaire[moduleIndex].validationScreenName;
				delete this.questionaire[moduleIndex].toggleSourceInput;

				this.questionaire[moduleIndex].moduleId = this.$route.params.moduleId;
				finalpayload = [this.questionaire[moduleIndex]];

				if (this.questionaire[moduleIndex]._id == undefined) {
					this.questionaire[moduleIndex]['isActive'] = false
					await this.saveQuestionaire(finalpayload,moduleIndex);

				} else {
					delete this.questionaire[moduleIndex]._id;
					this.questionaire[moduleIndex]['isActive'] = false;
					await this.updateQuestionaire(finalpayload, QuestionaireId);
				}
			}else{
				toast.error('validation failed', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},
		// toggleTheQuestionName(singlequestion:any,moduleIndex:any){
		// 	this.questionaire[moduleIndex].toggleSourceInput = true;
		// 	if(!this.questionaire[moduleIndex]['isActive'] == true && !this.questionaire[moduleIndex]['toggleSourceInput'] == true){
		// 		this.questionSelect(singlequestion,moduleIndex)
		// 	}
		// },
		async questionSelect(question:any,index:any){

			if(this.modIndex != index && this.modIndex!= ''){
			// if(this.modIndex != index){
				await this.resetData(this.questionaire[this.modIndex])
			}

			this.dummyQuestionaire = {...question};
			this.dummyQuestionaire.isActive = true;

			this.validCode = ''

			this.modIndex = index;
			
			this.questionaire[index]['isActive'] = !this.questionaire[index]['isActive'];

			this.questionaire.map((obj:any,itemIndex:any)=>{
				if(itemIndex != index){
					obj['isActive'] = false
				}
			})
			

			// this.questionaire.map((question:any,questionIndex:any)=>{
			
			// 	if(questionIndex == index){
			// 		this.questionaire[index].isActive = ! this.questionaire[index].isActive;
			// 		if(this.questionaire[index].isActive == true) {
			// 			let PresentQuestion = this.dummyQuestion.find((obj:any)=>{return question._id == obj._id})
			// 			this.dummyQuestionaire = {...PresentQuestion}
			// 			// this.dummyQuestion={...PresentQuestion}
			// 			this.modIndex = index
			// 			// this.singlequestion = {...this.dummyQuestionaire}
			// 			this.dummyQuestionaire.isActive = true;
			// 			// this.getallQuestionaireScreens();
						
			// 		}
			// 		else{
			// 			this.questionaire[questionIndex].isActive = false;
			// 		}
			// 	}
				
				
			// })
		},
		// backtocourseEdit() {
		// 	this.$router.push({ name: 'edit-course', params: { id: this.$route.params.courseId } });
		// },
		async saveQuestionaire(finalpayload: any,moduleIndex:any) {
			await this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/questionairescreens/add`, finalpayload)
				.then(async (response: any) => {
					// this.questionaire[moduleIndex]._id = response.data.upadtedQuestionId[0]
					toast.info('Questionaire Screen Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});

					await this.getallQuestionaireScreens();
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error('Questionaire Screen not Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},

		async updateQuestionaire(finalpayload: any, questionaireId: any) {
			var screenId = questionaireId;
			await this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/questionairescreen/${screenId}/update`, finalpayload[0])
				.then(async (res: any) => {
					toast.info(' Questionaire Screen updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					await this.getallQuestionaireScreens();
				})
				.catch((error: any) => {
					this.getallQuestionaireScreens();
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		questionRadioScreen(moduleIndex: any): any {
			var visibility = this.questionaire[moduleIndex].correctAnswer;
			// this.v$.$touch();
			// if(this.singlequestionObj.optionsvalue.length <= 3 ){
				this.v$.$reset();
				
				if (visibility == true) {
					this.questionaire[moduleIndex].answerVisibility = false;
				}
				this.questionaire[moduleIndex].options.push({
					text: this.singlequestionObj.optionsvalue,
					value: this.questionaire[moduleIndex].optionsvalue,
					description: this.questionaire[moduleIndex].optiondescription,
					correct: this.questionaire[moduleIndex].correctAnswer || false
				});
				this.singlequestionObj.optionsvalue = '';
				this.questionaire[moduleIndex].correctAnswer = '';
				this.questionaire[moduleIndex].optiondescription = '';
			// }
		},

		onradioDelete(optionIndex: any, moduleIndex: any) {
			if (this.questionaire[moduleIndex].options[optionIndex].correct == true) {
				this.questionaire[moduleIndex].answerVisibility = true;
			}
			this.questionaire[moduleIndex].options.splice(optionIndex, 1);
		},

		questioncheckBoxScreen(moduleIndex: any) {
			this.questionaire[moduleIndex].options.push({
				text: this.questionaire[moduleIndex].optionsvalue,
				value: this.questionaire[moduleIndex].optionsvalue,
				description: this.questionaire[moduleIndex].optiondescription,
				correct: this.questionaire[moduleIndex].correctAnswer || false
			});
			this.questionaire[moduleIndex].optionsvalue = '';
			this.questionaire[moduleIndex].correctAnswer = '';
			this.questionaire[moduleIndex].optiondescription = '';
		},
		oncheckBoxDelete(optionIndex: any, moduleIndex: any) {
			this.questionaire[moduleIndex].options.splice(optionIndex, 1);
		},

		async getallQuestionaireScreens() {
				this.dummyQuestion=[];
				this.questionaire=[];
				this.totalQuestion=[]

			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/questionairescreens/getAll`).then((res: any) => {
				this.spinnerLoading = false;				
				this.totalQuestion = res.data;
				
				for(var i=0; i < this.totalQuestion.length; i++) {
					
					let a = {
						_id:this.totalQuestion[i]._id,
						questionaireName:this.totalQuestion[i].questionaireName,
						questionaireType:this.totalQuestion[i].questionaireType,
						question:this.totalQuestion[i].question,
						options:this.totalQuestion[i].options,
						createdBy:this.totalQuestion[i].createdBy,
						createdAt:this.totalQuestion[i].createdAt,
						updatedBy:this.totalQuestion[i].updatedBy,
						updatedAt:this.totalQuestion[i].updatedAt,
						moduleId:this.totalQuestion[i].moduleId,
						isActive:this.totalQuestion[i].isActive
					}

					this.questionaire.push(a);
				}	
				this.dummy = [...this.questionaire];
			
			});			
		},
		async getSingleCourse() {
			console.log('ss');
			this.spinnerLoading = true;
			await this.$http
				.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/get`)
				.then((res: any) => {
					this.course = res.data;
					console.log('sss', this.course);
					this.course.forEach((course: any) => {
						this.moduleName = course.modules.filter((module: any) => module._id == this.$route.params.moduleId);
					});
				})
				.catch((error: any) => {
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			// console.log('this.screens', this.screens);
			// this.dummy = JSON.parse(JSON.stringify(this.screens));
			// console.log(',this.dummythis.dummy', this.dummy);
			this.spinnerLoading = false;
		},
	},
	async mounted() {
		this.getallQuestionaireScreens();
		await this.getSingleCourse();

		this.routesArray=[
			{name:'Courses',routeName:'courses'},
			{name:this.course[0].courseName,routeName:'edit-course',id:this.$route.params.courseId}, 	
			{name:'Questionaire',routeName:'questionairescreen'}
		]
	}
});
</script>