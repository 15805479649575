<template>
	<div>
		<div v-if="dataLoading" class="mt-2">
			<div class="flex items-center justify-center w-full h-full skeleton mt-4 tableCornerRadius bg-white">
				<dots-loader />
			</div>
		</div>
		<div class="pt-3 pl-5 pr-5" v-else>
			<div class="mb-2">
				<breadCrumb :routesArray="routesArray"></breadCrumb>
			</div>
			<div class="">
				<h1>Courses</h1>
			</div>
			<div class="w-full pt-3">
				<div class="card pt-3 px-5 pb-5 rounded">
					<div class="header flex justify-between">
						<div class="title">
							<h1 v-if="!myValueId">Add Course</h1>
							<h1 v-else>Edit Course</h1>
						</div>
						<div class="switch">
							<label class="switch">
								<input type="checkbox" v-model="courses[0].isActive" />
								<span class="slider round"></span>
							</label>
						</div>
					</div>

					<form>
						<div class="coursecontainer">
							<div class="row flex flex-wrap w-full pt-3">
								<div class="col-md-6 w-3/6  pr-2">
									<label class="controllabel font-normal text-sm mb-1">Name</label>
									<input
										type="text"
										class="inputboxstyling"
										v-model="courses[0].courseName"
										placeholder="Name"
										:class="{
											'is-invalid': v$.courses[0].courseName.$error
										}"
									/>
									
									<div v-if="v$.courses[0].courseName.$error" class="text-red-500">
										<div class="error-text" v-if="v$.courses[0].courseName.required.$invalid">Required</div>
									</div>
								</div>
								<div class="col-md-6 w-3/6  pl-2">
									<label class="controllabel font-normal text-sm mb-1">Type</label>

									<select
										class="inputboxstyling"
										v-model="courses[0].courseType"
										:class="{
											'is-invalid': v$.courses[0].courseType.$error
										}"
									>
										<option value='' disabled hidden selected>Please select type</option>
										<option :value="10601">Mandatory</option>
										<option :value="10602">Optional</option>
									</select>

									<div v-if="v$.courses[0].courseType.$error" class="text-red-500">
										<div class="error-text" v-if="v$.courses[0].courseType.required.$invalid">Required</div>
									</div>
								</div>
							</div>
							<div class="row flex flex-wrap w-full pt-3">
								<div class="col-md-12 w-full">
									<label class="controllabel font-normal text-sm mb-1">Description</label>
									<textarea
									rows="4" cols="50"
										type="text"
										class="inputboxstyling"
										v-model="courses[0].courseDescription"
										placeholder="Description"
										:class="{
											'is-invalid': v$.courses[0].courseDescription.$error
										}"
									/>
									<div v-if="v$.courses[0].courseDescription.$error" class="text-red-500">
										<div class="error-text" v-if="v$.courses[0].courseDescription.required.$invalid">Required</div>
										<div v-if="v$.courses[0].courseDescription.maxLengthValue.$invalid" class="text-red-500 text-xs">Characters should not more than 308</div>
									</div>
								</div>
							</div>

							<div class="row flex flex-wrap w-full pt-3 cursor-pointer">
								<div class="col-md-12 w-full cursor-pointer">
									<label class="controllabel font-normal text-sm mb-1">Image</label>
									<div>
										<label htmlFor="upload">
											<input type="file" id="upload" style="display:none" ref="fileInput" @change.stop="checkFile()" />
										</label>
									</div>
									<div class="flex justify-center items-center cursor-pointer border rounded py-2 border-lightgrey">
										<div>
											<div v-if="!courses[0].courseImageUrl && pageType=='add'">
												<div class="flex justify-center mt-2">
													<label htmlFor="upload">
														<img src="@/assets/addImage.svg" alt="" class="w-52 h-32 cursor-pointer" />
													</label>
												</div>
												<div class="flex justify-center items-center mt-2">
													<div v-if="fileName">
														Selected File: <span class="text-sm font-bold">{{ fileName }}</span>
													</div>
												</div>
											</div>

											<div v-else-if="!courses[0].courseImageUrl && pageType=='edit'">
												<div class="flex justify-center mt-2">
													<label htmlFor="upload">
														<img src="@/assets/editImage.svg" alt="" class="w-52 h-32 cursor-pointer" />
													</label>
												</div>
												<div class="flex justify-center items-center mt-2">
													<div v-if="fileName">
														Selected File: <span class="text-sm font-bold">{{ fileName }}</span>
													</div>
												</div>
											</div>

											<div v-else>
												<div class="flex justify-center flex-col mt-px">
													<div class="flex justify-center">
														<label v-if="courses[0].courseImageUrl != undefined" htmlFor="upload">
															<img :src="courses[0].courseImageUrl" alt="" class="w-96 h-52 cursor-pointer" />
														</label>
													</div>
													<label htmlFor="upload">
														<div class="-mt-10 flex justify-center">
															<p class="borderstyle p-1 font-semibold text-xs cursor-pointer">Edit Image</p>
														</div>
													</label>
													<div class="flex justify-center items-center mt-2">
														<div v-if="fileName">
															Selected File: <span class="text-sm font-bold">{{ fileName }}</span>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>


						</div>
					</form>

					<div class="flex justify-center">
						<div class="flex justify-center items-center" v-if="myValueId">
							<button class="btnprimary mt-4" @click="addModule()">Add Module</button>
						</div>

						<div>
							<div v-if="!myValueId">
								<div class="flex justify-center items-center " v-if="coursemodule">
									<button class="btnprimary mt-4" @click="addCourse()">Create Course</button>
								</div>
							</div>

							<div class="flex justify-center items-center ml-6" v-else>
								<button :class="(JSON.stringify(dummyData) !== JSON.stringify(courses[0]) || !fileStatusName)?'btnprimary':'btndisabled pointer-events-none'" class="mt-4" @click="updateSingleCourse()">Update Course</button>
							</div>
						</div>
						
					</div>
				</div>

				<div class="pt-3">
					<div class="text-black no-underline mt-3 moduleBox cursor-pointer" v-for="(singlemodule, smIndex) in modules" :key="singlemodule._id" :class="{ 'accordion': singlemodule.moduleToggle }">
						<div class="flex bg-white py-2.5 px-2.5 rounded " :class="{ 'accordion-head': !singlemodule.moduleToggle }" @click="toggleModuleContainer(singlemodule,smIndex)">
							<div class="w-full flex items-center">
								<div class="w-2 flex items-center">
									<img src="@/assets/rightArrowFilled.svg" class="h-4 w-4 cursor-pointer"  v-if="!singlemodule.moduleToggle"/>
									<img src="@/assets/downArrow.svg" class="h-4 w-4 cursor-pointer" v-else />		
								</div>
								<div class="heading font-opensans flex justify-between items-center text-sm  w-98 pl-1" >
									<div>{{ singlemodule.moduleName }}</div>
								</div>
							</div>

							<div class="mr-1">
								<div class="rounded-full w-5 h-5 bg-green mx-auto" v-if="modules[smIndex].isModuleActive == true"></div>
								<div class="rounded-full w-5 h-5 bg-error mx-auto" v-else></div>
							</div>

							<!-- <div class="switch mr-1">
								<label class="switch" 
									:class="{
										'not-allowed': (singlemodule.moduleToggle == false),
										'cursor-pointer': (singlemodule.moduleToggle == true)
									}"
								>
									<input 
										type="checkbox" 
										:disabled="singlemodule.moduleToggle == false" 
										v-model="modules[smIndex].isModuleActive"
									/>
									<span class="slider round"></span>
								</label>
							</div> -->
						</div>

						<div class="accordion-body bg-white" v-if="singlemodule.moduleToggle">
							<hr class="border-b border-solid border-lightgrey mt-2"/>
							
							<form :id="'module'+smIndex" >
								<div class="row flex flex-wrap w-full">
									<div class="row flex w-full">
										<div class="w-full pl-2">
											<label   class="controllabel font-normal text-sm mb-1">Module Name</label>
											<input
												:id="smIndex"
												type="text"
												class="inputboxstyling"
												v-model="singlemodule.moduleName"
												placeholder="ModuleName"
												:class="{
													'is-invalid': (v$.modules.$error && !v$.modules.$each.$response.$data[smIndex].moduleName.required) ||(singlemodule.validationModuleName)
												}"
											/>
											<div v-if="v$.modules.$error">
												<div v-if="!v$.modules.$each.$response.$data[smIndex].moduleName.required" class="error-text text-red-500">Required</div>
											</div>
											<div v-if="singlemodule.validationModuleName" class="error-text text-red-500">Same Module Name Exists</div>
										</div>

										<div class="flex justify-end items-end px-3">
											<label class="switch">
												<input 
													type="checkbox" 
													v-model="modules[smIndex].isModuleActive"
												/>
												<span class="slider round"></span>
											</label>
										</div>
										
									</div>

									<div class="col-md-6 w-full p-2.5">
										<label class="controllabel font-normal text-sm mb-1">Module Description</label>

										<input
											:id="smIndex"
											type="text"
											class="inputboxstyling"
											v-model="singlemodule.moduleDescriptions"
											placeholder="ModuleDescription"
											:class="{
												'is-invalid': v$.modules.$error && !v$.modules.$each.$response.$data[smIndex].moduleDescriptions.required
											}"
										/>
										<div v-if="v$.modules.$error">
											<div v-if="!v$.modules.$each.$response.$data[smIndex].moduleDescriptions.required" class=" error-text text-red-500">Required</div>
										</div>
									</div>

									<div class="row flex flex-wrap w-full">
												<div class="col-md-12 w-full p-2.5">
												<label class="controllabel font-normal text-xs mb-1">Image</label>
										
														<div>
															<label :htmlFor="'uploadImage'+ smIndex">
																<input  type="file" :id="'uploadImage'+ smIndex" style="display:none" :ref="'element' + smIndex"  @change.stop="checkFile(smIndex)"  />
															</label>
														</div>
														<div class="flex justify-center items-center cursor-pointer border rounded py-2 border-lightgrey">
															<div>
																<div v-if="!singlemodule.imageUrl && (pageType=='add'||pageType=='edit')">
																	<div>
																		<label  :htmlFor="'uploadImage'+ smIndex"  class="flex justify-center mt-2">
																		<img src="@/assets/addImage.svg" alt="" class="w-52 h-32 cursor-pointer">
																		</label> 
																	</div>
																	<div class="flex justify-center items-center mt-2">
																		<div v-if="fileNameMod[smIndex]">Selected File :  <span class="text-sm font-bold"> {{fileNameMod[smIndex]}}</span>	</div>
																	</div>
																</div> 
																<!-- <div v-else-if="singlemodule.imageUrl ==''&& pageType=='edit'">
																	<label :htmlFor="'uploadImage'+ smIndex" class="flex justify-center">
																	<img src="@/assets/editImage.svg" alt="" class="w-52 h-32 cursor-pointer">
																	</label>
																	<div class="flex justify-center items-center mt-2">
																		<div v-if="fileNameMod">Selected File :  <span class="text-sm font-bold"> {{fileNameMod}}</span>	</div>
																	</div>
																</div> -->
																<div v-else>
																		<div>
																			<label  :htmlFor="'uploadImage'+ smIndex" class="flex justify-center mt-0.5">
																				<img :src="singlemodule.imageUrl" alt="upload" class="w-96 h-52 cursor-pointer"/>
																			</label>
																		</div>
																		<label :htmlFor="'uploadImage'+ smIndex">
																			<div class="-mt-10 flex justify-center ">
																				<p class="borderstyle p-1 font-semibold text-xs cursor-pointer">Edit Image</p>
																			</div>
																		</label>
																		<div class="flex justify-center items-center mt-4">
																			<div v-if="fileNameMod[smIndex]">Selected File :  <span class="text-sm font-bold">{{fileNameMod[smIndex]}}</span>	</div>
																		</div>
																</div>
														</div>
													</div>
												</div>
											</div>
								</div>
							<div class="p-2.5">
								<div class="text-black no-underline mt-6 min-h-30" v-if="singlemodule._id" :class="{ accordion: visibilityscreens }">
									<div class="flex justify-between bg-white py-2.5 px-2.5 rounded min-h-30" :class="{ 'accordion-head': !visibilityscreens }">
										<div class="heading w-11/12 text-sm" @click="getallScreens(singlemodule)">Screens</div>
										<div class="actionicon">
											<div @click="addScreen(smIndex)" class="p-1 text-greys cursor-pointer">
												<img src="@/assets/externalLink.svg" class="h-5 w-5" />
											</div>
										</div>
									</div>
									<div class="accordion-body bg-white p-2">
										<hr class="border-b border-solid border-lightgrey" />
										<div class="mt-2"  v-if="visibilityscreens">
											<div v-if="singlemodule.screensArray">
												<div class="accordion-head text-black no-underline mt-3 min-h-3" v-for="screen in singlemodule.screensArray" :key="screen" id="screendata">
													<div class="flex justify-between bg-white py-2.5 px-2 text-sm rounded">
														{{ screen.screenName }}
													</div>
												</div>
											</div>
											<div class="flex justify-between bg-white py-2.5 px-2" v-else>
												<h2 class="text-black">Please Add Screens</h2>
											</div>
										</div>
									</div>
								</div>

								<div class="text-black no-underline min-h-30 mt-2" v-if="singlemodule._id" :class="{ accordion: visibilityQuestionaire }">
									<div class="flex justify-between bg-white py-2.5 px-2 rounded" :class="{ 'accordion-head': !visibilityQuestionaire }">
										<div class="heading w-11/12 text-sm" @click="getallQuestionaireScreens(singlemodule)">Questionaire</div>
										<div class="actionicon" >
											<div @click="addQuestionaire(smIndex)" class="p-1 text-greys cursor-pointer">
												<img src="@/assets/externalLink.svg" class="h-5 w-5" />
											</div>
										</div>
									</div>
									<div class="accordion-body bg-white p-2" v-if="visibilityQuestionaire">
										<hr class="border-t-2" />
										<div class="mt-2">
								<div v-if="singlemodule.questionaireScreensArray">
									<div class="accordion-head text-black no-underline mt-3 min-h-3" v-for="questionairescreen in singlemodule.questionaireScreensArray" :key="questionairescreen" id="screendata">
													<div class="flex justify-between bg-white py-2.5 px-2 text-sm rounded">
												{{ questionairescreen.questionaireName }}
											</div>
										</div>
									</div>
									<div class="flex justify-between bg-white py-2.5 px-2 text-black" v-else>
										<h2>Please Add Questions</h2>
											</div>
										</div>
									</div>
											</div>
										</div>
								<div class="flex justify-center p-4">
									
									<div class="flex justify-center items-center">
										<button type="button" :class="!singlemodule._id || JSON.stringify(singlemodule) != JSON.stringify(dummyMoudleObject) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="resetData(singlemodule,smIndex,$event)">Reset</button>
									</div>

									<div>
										<div>
											<div class="flex justify-center items-center ml-6" v-if="!singlemodule._id">
												<button type="button" :class="!singlemodule._id ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="actionModule(smIndex, courses, singlemodule)">Save</button>
											</div>
										
											<div class="buttonposition flex justify-center items-center ml-6" v-else>
												<button type="button" :class="singlemodule._id && JSON.stringify(singlemodule) != JSON.stringify(dummyMoudleObject) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="actionModule(smIndex, courses, singlemodule)">Update</button>
											</div>
										</div>
									</div>
									
								</div>
							</form>
						</div>
					</div>
				</div>



			</div>
			<div>
				<spinner v-if="isloading" />
			</div>
		</div>
	</div>
</template>
<style scoped>
.not-allowed {
	cursor: not-allowed !important;
}
.borderstyle{
	border: 1px solid #e9e9e9;
	background-color: #e9e9e9;
	border-radius:4px
}
.moduleBox:last-child{
	padding-bottom: 35px;
}
</style>

<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { required, helpers, maxLength } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import { toast } from '../../main';
import spinner from '../../components/spinner.vue';
import breadCrumb from '@/components/breadCrumb.vue'
import { v4 as uuidv4 } from 'uuid';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import dotsLoader from '@/components/dotsLoader.vue'
export default defineComponent({
	components: {
		spinner,
		breadCrumb,dotsLoader
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo',dotsLoader:'dotsLoader' }),

		myValueId:function(): any{
			if(this.$route.params.id){
				return true
			}else{
				return false;
			}
		}
	
	},
	data(): any {
		return {
			v$: useVuelidate(),
			spinnerLoading: false,
			teamsList: [],
			value: null,
			totalQuestions: 20,
			optionsarray: [],
			pageType: '',
			cousrseWithModuleId: '',
			coursemodule: true,
			createscreen: true,
			finalarray: [],
			moduleToggle: false,
			isloading: false,
			// visibilityscreens: false,
			visibilityQuestionaire: false,
			visibilityscreens: false,
			dummyId: '000000000000000000',
			validationModuleName: false,
			allQuestions: [],
			activateButton: false,
			updatescreen: false,
			newScreen: false,
			showImageBlock:false,

			currentScreen:{},
			modIndex:'',
			dummy:[],
			updateDisable:[],

			courses: [
				{
					courseName: '',
					courseDescription: '',
					courseAuthor: '',
					courseImageUrl: '',
					courseDuration: '',
					courseCategory: '',
					courseType: '',
					courseImage: '',
					isActive: false,
					createdBy: '',
					createdAt: '',
					updatedBy: '',
					updatedAt: ''
				}
			],
			dummyData:{},
			// dummyModule:{},
			checkIds:[],
			imageUrl:'',
			moduleImageUrl:'',
			errorClass: false,
			modules: [],
			actionIcons: true,
			allScreensInactive:null,
			fileName:'',
			fileNameMod:[],
			fileStatus: [],
			fileStatusName:true,
			routesArray:[],
			dataLoading:true,
			dummyMoudleObject:{}
		};
	},
	validations() {
		return {
			courses: [
				{
					courseName: { required },
					courseDescription: { required, maxLengthValue: maxLength(308), },
					courseType: { required }
					// courseImageUrl:{required}
				}
			],
			modules: {
				$each: helpers.forEach({
					moduleName: { required },
					moduleDescriptions: { required }
				})
			}
		};
	},

	methods: {
	
		setUpdateDisable(condition:any){
			this.updateDisable[this.modIndex] = condition;
			console.log("this.updateDisable",this.updateDisable)
		},
		
		resetData(singlemodule: any,index:any,e:any) {
			if(singlemodule._id){
				console.log("reset index",this.modIndex)
				this.modules[index] = {...JSON.parse(JSON.stringify(this.dummy[index])),moduleToggle:true};
			}
			else{
				(singlemodule.moduleName = ''), (singlemodule.moduleDescriptions = '');
				this.resetFileUploads(index);
			}
			console.log("eventReset",e)
		},
		allScreen(courses: any, singlemodule: any) {
			this.$router.push({
				name: 'updatescreen',
				params: { courseId: this.courses[0]._id, moduleid: singlemodule._id },
				// query:"daataGet"
			});
		},
		checkFile(index:any) {
			let file = [];
			if(index !== undefined){
				console.log("inside mod");
				this.fileNameMod[index] = '';
								console.log("filesinformation",this.$refs[`element${index}`][0].files[0]);

				file = this.$refs[`element${index}`][0].files[0];

				file != undefined ? this.imgCheck	(file,'module'):'';
			}
			else{
				console.log("inside Course");
				this.fileName = '';
				file = this.$refs.fileInput.files[0];
				file != undefined ? this.imgCheck(file,'course'):'';
				console.log("file",file);
			}	
		},
		imgCheck(file: any,status:any) {
			if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png' || file.type == 'image/svg') {
				let size = Number((file.size / 1048576).toFixed(2));
				if (size > 80) {
					toast.error('Image should be atmost 80 MB', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.resetFileUploads(this.modIndex);

				}else {
					status == 'module' ? (this.fileNameMod[this.modIndex] = file.name, this.fileStatus[this.modIndex] = false) : (this.fileName = file.name,this.fileStatusName = false);
				}
			} else {
				toast.error('Supported only jpg, jpeg, png, svg', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				this.resetFileUploads(this.modIndex)
			}
		},
		resetFileUploads(index:any){
			console.log("in reset ")
				if(this.$refs[`element${index}`].value){
					console.log("in reset")
					this.$refs[`element${index}`].value='';
					this.fileNameMod[index]='';
				}
		},

		async getallScreens(singlemodule: any) {
			// console.log("screens",singlemodule);
			
			this.visibilityscreens = !this.visibilityscreens;
			if (singlemodule.screens) {
				this.spinnerLoading = true;
				await this.$http
					.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.id}/module/${singlemodule._id}/screens/getAll`)
					.then((res: any) => {
						this.spinnerLoading = false;
						singlemodule.screensArray = res.data;
						// this.finalarray = res.data;
						console.log('singlemodule.screensArraysinglemodule.screensArray',singlemodule.screensArray)
						let screensCheck = singlemodule.screensArray.every((obj:any)=>{
							return obj.data.isScreenActive == false;
						});
						if(screensCheck == true){
							this.allScreensInactive = true;
						}else{
							this.allScreensInactive = false;
						}
					})
					.catch((error: any) => {
						this.spinnerLoading = false;
						toast.error(error, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					});
			}
		},
		async getallImagesfromS3(){
			try {
					await this.$http
					.get(`${process.env.VUE_APP_FILE_API_URL}/bucket/files/getall`)
						 //.get(`http://localhost:4030/services/filemanagement/bucket/files/getall`)
						.then((response: any) => {
					if (response.status == 200) {
							for(var i=0;i<response.data.Contents.length;i++){
								let ids= response.data.Contents[i].Key.substring(0, response.data.Contents[i].Key.lastIndexOf('.'))
								this.checkIds.push(ids)
							}
						} 
					})
					} catch (err) {
					console.error(err);
					}

		},
		toggleModuleContainer(singlemodule:any,index:any) {

			this.currentScreen = {...singlemodule};
			this.modIndex = index;
			
			this.modules[index]['moduleToggle'] = !this.modules[index]['moduleToggle'];

			this.modules.map((obj:any,itemIndex:any)=>{
				if(itemIndex != index){
					obj['moduleToggle'] = false
				}
			})


			this.dummyMoudleObject = {...this.modules[index]};
			
			
		},
		async uploadImageData (data:any) {
						return new Promise(resolve => {
						const reader:any = new FileReader()
						reader.onload = function () {
						resolve(reader.result.replace('data:', '')
						.replace(/^.+,/, '')) 
						}
						reader.readAsDataURL(data)
						})
		},
		async sendDataImage(data:any){
				this.isloading=true
				console.log("imageUrlimageUrlimageUrlimageUrlimageUrlimageUrl")
					// console.log("$refs.fileInput.files[0]",this.$refs.fileInput.files[0])
					const imageFile = await this.uploadImageData(data)
					let fileExtension=data.name.split('.').pop()
					let id=uuidv4();
					let findId =this.checkIds.filter((x:any) => x ===id )
					if(findId.length>0){
						id=uuidv4()
					}
					let payload={fileData:imageFile,name:`${id}.${fileExtension}`}
					

					try {
					await this.$http
					.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${process.env.VUE_APP_FOLDER_NAME}/file/upload`, payload)
						// .post(`http://localhost:4030/services/filemanagement/bucket/${process.env.VUE_APP_TRAINING_API_PATH}/file/upload`, payload)
						.then((response: any) => {
					if (response.status == 200) {
							console.log(response.data)
							this.imageUrl=response.data
							this.isloading=false
							console.log("imageUrlimageUrlimageUrlimageUrlimageUrlimageUrl",this.moduleImageUrl)
						} 
					else {
							console.log('response status', response.status);
						}
					})
					} catch (err) {
					console.error(err);
					}
			},

		async getallQuestionaireScreens(singlemodule: any) {
			this.visibilityQuestionaire = !this.visibilityQuestionaire;

			if (singlemodule.questionaire) {
				await this.$http
					.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${singlemodule._id}/questionairescreens/getAll`)
					.then((res: any) => {
						singlemodule.questionaireScreensArray = res.data;
					})
					.catch((error: any) => {
						toast.error(error, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					});
			}
		},
		async addScreen(smIndex: any) {
			this.$router.push({
				name: 'manage-screens',
				params: {
					courseId: this.$route.params.id,
					moduleId: this.modules[smIndex]._id
				}
			});
		},
		async addQuestionaire(smIndex: any) {
			this.$router.push({
				name: 'questionairescreen',
				params: {
					courseId: this.$route.params.id,
					moduleId: this.modules[smIndex]._id
				}
			});
		},
		async actionModule(smIndex: any, courses: any, singlemodule: any) {
			this.v$.modules.$touch();

			this.modules[smIndex].validationModuleName = false;
			delete this.modules[smIndex].updatescreen;
			delete this.modules[smIndex].newScreen;

			const uniqueValues = new Set(this.modules.map((v: any) => v.moduleName));
			
			if (uniqueValues.size < this.modules.length) {
				this.modules[smIndex].validationModuleName = true;
			}

			let errorCondition = this.v$.modules.$each.$message[smIndex];
			if (errorCondition.length == 0 && !this.modules[smIndex].validationModuleName) {
				this.v$.$reset();

				let imageData = this.$refs[`element${smIndex}`][0].files[0]

				if(imageData !=undefined){
				  	if(imageData.type =='image/jpeg' || imageData.type =='image/jpg' || imageData.type =='image/png' || imageData.type =='image/svg'){
						await this.sendDataImage(this.$refs[`element${smIndex}`][0].files[0])
						this.fileNameMod[smIndex] = '';
					}
				}

				if (singlemodule._id) {
					await this.updateModule(courses, singlemodule);
				} else {
					await this.saveModule(smIndex);
				}
				
			} else {
				singlemodule.errorClass = true;
				toast.error('validation failed', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.fileNameMod[smIndex] = '';
			this.fileStatus[smIndex] = true;
		},

		async addCourseInformation(){
			let date = new Date();
				this.courses[0].createdBy = this.userInfo.sub;
				this.courses[0].createdAt = date;
				this.courses[0].updatedBy = this.userInfo.sub;
				this.courses[0].updatedAt = date;
				this.courses[0].courseImageUrl = this.imageUrl;
				this.courses[0].courseType = parseInt(this.courses[0].courseType);
				this.$http
					.post(`${process.env.VUE_APP_TRAINING_API_URL}/courses/add`, this.courses)
					.then((response: any) => {
						if (response.status == 200) {
							this.$router.push({ name: 'courses' });
							toast.info(`Created Course`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						} else {
							console.log('response status', response.status);
						}
					})
					.catch((error: any) => {
						toast.error(`Save failed with ${error}`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					});
		},
		async addCourse() {
			this.v$.$touch();
			if (!this.v$.$invalid) {
				let imageData=this.$refs.fileInput.files[0]
				if(this.$refs.fileInput.files[0] !=undefined){
				  	if(imageData.type =='image/jpeg' || imageData.type =='image/jpg' || imageData.type =='image/png' || imageData.type =='image/svg'){
						await this.sendDataImage(this.$refs.fileInput.files[0])
						 this.addCourseInformation();
					}
					else {
					toast.error('Image Invalid Format', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
					});
				}
			}
				else{
					this.imageUrl=this.courses[0].courseImageUrl
					 this.addCourseInformation()
					}
			} else {
				toast.error('validation failed', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}

			
		},
		async getSingleCourse() {
			this.spinnerLoading = true;
			await this.$http
				.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.id}/get`)
				.then((res: any) => {
					this.spinnerLoading = false;
					this.courses = res.data;
					this.dummyData = {...this.courses[0]} 
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
	  async	saveModule(smIndex: any) {
			this.modules[smIndex]['moduleToggle'] = true;
			delete this.modules[smIndex]['errorClass'];
			
			let date = new Date();
			this.modules[smIndex].createdBy = this.userInfo.sub;
			this.modules[smIndex].createdAt = date;
			
			this.modules[smIndex].imageUrl = this.imageUrl;

			var updatedModule = [this.modules[smIndex]];
			this.spinnerLoading = true;

			this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.id}/modules/add`, updatedModule)
				.then(async (response: any) => {
					toast.info('Module Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.modules[smIndex]['isModuleActive'] = false;
					await this.getSingleCourse();
					await this.getAllModules();
					this.spinnerLoading = false;
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error('Module not Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},

	async getAllModules() {
			this.spinnerLoading = true;
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.id}/modules/getAll`)
				.then((res: any) => {
					this.spinnerLoading = false;
					this.modules = [];
					this.modules = res.data;
					this.modules.forEach((module: any) => {
						module.moduleToggle = false;
					});
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
				this.dummy = JSON.parse(JSON.stringify(this.modules));
				this.dummy.forEach((item:any)=>{this.updateDisable.push(false );this.fileNameMod.push('');this.fileStatus.push(true)});
		},
		async updateModule(courses: any, singlemodule: any) {
			await this.getallScreens(singlemodule);
			if(this.allScreensInactive == undefined || this.allScreensInactive == false){
				this.spinnerLoading = true;
				var moduleid = singlemodule._id;
				singlemodule.moduleToggle = true;
				delete singlemodule._id;
				
				delete singlemodule.errorClass;
				singlemodule.updatedBy = this.userInfo.sub;
				singlemodule.imageUrl=this.imageUrl
				singlemodule.updatedAt = new Date();
				delete singlemodule.screensArray;
				delete singlemodule.questionaire;
				delete singlemodule.screens;
				delete singlemodule.questionaireScreensArray;

				await this.$http.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${courses[0]._id}/module/${moduleid}/update`, singlemodule)
				.then(async (response: any) => {
					this.spinnerLoading = false;
					toast.info('Module Updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.updateDisable=[];
					await this.getSingleCourse();
				 	await this.getAllModules();
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error('Module not Updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			}else{
				toast.error('No Screens are Active in this Module', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
		},

		addModule(): any {
			this.modules.push({
				moduleName: '',
				moduleDescriptions: '',
				createdBy: '',
				createdAt: '',
				updatedBy: '',
				updatedAt: '',
				imageUrl:'',
				moduleToggle: false,
				isModuleActive: false,
			});
		},
		async updateCourseInformation(){
			console.log("sss",this.imageUrl)
				delete this.courses[0]['_id'];
				delete this.courses[0]['modules'];
				this.courses[0].updatedBy = this.userInfo.sub;
				this.courses[0].updatedAt = new Date();
				this.courses[0].courseType = parseInt(this.courses[0].courseType);
				this.courses[0].courseImageUrl = this.imageUrl;
				this.$http
					.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.id}/update`, this.courses[0])
					.then((response: any) => {
						if (response.status == 200) {
							this.$router.push({ name: 'courses' });
						} else {
							console.log('response status', response.status);
						}
						toast.info('Updated', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
					})
					.catch((error: any) => {
						toast.error(`Save failed with ${error}`, {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
					});
					});
		},
		async updateSingleCourse() {
			this.v$.$touch();
			if (!this.v$.$invalid) {
				let imageData=this.$refs.fileInput.files[0]
				if(this.$refs.fileInput.files[0] !=undefined){
				  	if(imageData.type =='image/jpeg' || imageData.type =='image/jpg' || imageData.type =='image/png' || imageData.type =='image/svg'){
						await this.sendDataImage(this.$refs.fileInput.files[0])
						 this.updateCourseInformation()
					}
					else {
					toast.error('Image Invalid Format', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				}
			}
				else{
					this.imageUrl=this.courses[0].courseImageUrl
					 this.updateCourseInformation()
					}
			} else {
				toast.error('validation failed', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			this.fileStatusName = true;
			
		},
		async deleteModule(courseid: any, singlemodule: any, smIndex: any) {
			this.spinnerLoading = true;
			await this.$http
				.delete(`${process.env.VUE_APP_TRAINING_API_URL}/course/${courseid[0]._id}/module/${singlemodule._id}/delete`)
				.then(async (response: any) => {
					this.spinnerLoading = false;
					toast.info('Module Deleted', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					await this.getSingleCourse();
					await this.getAllModules();
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error('Module Not Deleted', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		}
	},
	async created() {
		this.dataLoading = true;
		this.getallImagesfromS3()
		this.pageType = this.$route.fullPath.includes('add') ? 'add' : 'edit';
		if (this.$route.params.id) {
			await this.getSingleCourse();
			await this.getAllModules();

			this.routesArray =[
				{name:'Courses',routeName:'courses'},
				{name:this.courses[0].courseName,routeName:'edit-course' },
			]
		}
		this.dataLoading = false;
	}
});
</script>