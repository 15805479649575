<template>
	<div class="w-full pt-3 pl-5 pr-5">
		<div class="mb-2">
			<breadCrumb :routesArray="routesArray"></breadCrumb>
		</div>
		<div >
			<h1 class="font-bold">My Courses</h1>
		</div>
		<div>
			<!-- <div v-if="dotsLoading" class="mt-2">
				<div class="flex items-center justify-center w-full h-full skeleton mt-4 tableCornerRadius bg-white">
					<dots-loader />
				</div>
			</div>		 -->
			<div class="rounded mt-4" >
			<div >
				<div class="flex cursor-pointer">
					<div class="flex flex-1 flex-wrap cursor-pointer">
						<div class="flex-auto text-center a" v-for="(singlenav, navIndex) in navBarheading" :key="singlenav.key">
							<a class="tab text-xs font-medium" v-on:click="toggleTabs(navIndex)" :class="{ correct: openTab == navIndex }">
								{{ singlenav }}
							</a>
						</div>
						<div class="mr-2 flex-auto text-center iconscolor" @click="ontoggle()">
							<div class="inline-flex justify-center items-center" v-if="isvertically">
								<!-- <img src="@/assets/listView.svg" class="w-5 h-4" /> -->
								<div class="pt-1">
									<svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path d="M2.3313 2.29609C2.20454 2.36235 2.11812 2.44878 2.05474 2.56978C2.00288 2.67061 2 2.75703 2 4.08511V5.49385L2.06626 5.62061C2.10371 5.68975 2.19014 5.78481 2.2564 5.83379L2.38027 5.92021L3.81206 5.92886L5.24385 5.9375L5.37061 5.86836C5.45127 5.82803 5.53481 5.75024 5.59531 5.66382L5.6875 5.52554V4.09663V2.67061L5.6126 2.54385C5.56938 2.46895 5.48296 2.37964 5.40518 2.33066L5.27266 2.24712H3.84663C2.6021 2.25 2.4062 2.25576 2.3313 2.29609Z" fill="#8f949b"/>
									<path d="M7.24316 2.30469C7.09912 2.37959 6.99253 2.50059 6.94067 2.64751C6.90322 2.7541 6.89746 2.97881 6.89746 4.09082C6.89746 5.20283 6.90322 5.42754 6.94067 5.53413C6.99541 5.68394 7.102 5.80493 7.24893 5.87983C7.35264 5.93169 7.48516 5.93457 11.8324 5.93457H16.3092L16.436 5.86831C16.5138 5.82798 16.6002 5.74731 16.6578 5.66377L16.75 5.52549V4.09658V2.67056L16.6751 2.5438C16.6319 2.4689 16.5455 2.37959 16.4677 2.33062L16.3352 2.24707H11.841C7.5082 2.24995 7.34111 2.25283 7.24316 2.30469Z" fill="#8f949b"/>
									<path d="M2.3313 7.82734C2.20454 7.8936 2.11812 7.98003 2.05474 8.10103C2.00288 8.20186 2 8.28828 2 9.61636V11.0251L2.06626 11.1519C2.10371 11.221 2.19014 11.3161 2.2564 11.365L2.38027 11.4515L3.81206 11.4601L5.24385 11.4688L5.37061 11.3996C5.45127 11.3593 5.53481 11.2815 5.59531 11.1951L5.6875 11.0568V9.62788V8.20186L5.6126 8.0751C5.56938 8.0002 5.48296 7.91089 5.40518 7.86191L5.27266 7.77837H3.84663C2.6021 7.78125 2.4062 7.78701 2.3313 7.82734Z" fill="#8f949b"/>
									<path d="M7.24316 7.83594C7.09912 7.91084 6.99253 8.03184 6.94067 8.17876C6.90322 8.28535 6.89746 8.51006 6.89746 9.62207C6.89746 10.7341 6.90322 10.9588 6.94067 11.0654C6.99541 11.2152 7.102 11.3362 7.24893 11.4111C7.35264 11.4629 7.48516 11.4658 11.8324 11.4658H16.3092L16.436 11.3996C16.5138 11.3592 16.6002 11.2786 16.6578 11.195L16.75 11.0567V9.62783V8.20181L16.6751 8.07505C16.6319 8.00015 16.5455 7.91084 16.4677 7.86187L16.3352 7.77832H11.841C7.5082 7.7812 7.34111 7.78408 7.24316 7.83594Z" fill="#8f949b"/>
									<path d="M2.3313 13.3586C2.20454 13.4249 2.11812 13.5113 2.05474 13.6323C2.00288 13.7331 2 13.8195 2 15.1476V16.5563L2.06626 16.6831C2.10371 16.7522 2.19014 16.8473 2.2564 16.8963L2.38027 16.9827L3.81206 16.9914L5.24385 17L5.37061 16.9309C5.45127 16.8905 5.53481 16.8127 5.59531 16.7263L5.6875 16.588V15.1591V13.7331L5.6126 13.6063C5.56938 13.5314 5.48296 13.4421 5.40518 13.3932L5.27266 13.3096H3.84663C2.6021 13.3125 2.4062 13.3183 2.3313 13.3586Z" fill="#8f949b"/>
									<path d="M7.24316 13.3672C7.09912 13.4421 6.99253 13.5631 6.94067 13.71C6.90322 13.8166 6.89746 14.0413 6.89746 15.1533C6.89746 16.2653 6.90322 16.49 6.94067 16.5966C6.99541 16.7464 7.102 16.8674 7.24893 16.9423C7.35264 16.9942 7.48516 16.9971 11.8324 16.9971H16.3092L16.436 16.9308C16.5138 16.8905 16.6002 16.8098 16.6578 16.7263L16.75 16.588V15.1591V13.7331L16.6751 13.6063C16.6319 13.5314 16.5455 13.4421 16.4677 13.3931L16.3352 13.3096H11.841C7.5082 13.3125 7.34111 13.3153 7.24316 13.3672Z" fill="#8f949b"/>
									</svg>
								</div>
							</div>
							<div class="inline-flex justify-center items-center" v-else>
								<!-- <img src="@/assets/gridView.svg" class="w-5 h-4 " /> -->
								<div class="pt-1">
									<svg width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect x="1" y="1" width="6.5625" height="6.5625" rx="1" fill="#8f949b"/>
									<rect x="10.1874" y="1" width="6.5625" height="6.5625" rx="1" fill="#8f949b"/>
									<rect x="1" y="10.1877" width="6.5625" height="6.5625" rx="1" fill="#8f949b"/>
									<rect x="10.1874" y="10.1877" width="6.5625" height="6.5625" rx="1" fill="#8f949b"/>
									</svg>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div :class="classObject" class="flexPosition">
					<div class="cursor-pointer" v-for="singlecourse in filteredCourses" :key="singlecourse.id">
						<displaycard :class="{ vertical: !isvertically }" :allCourses="singlecourse" @click="openCourseDetail(singlecourse._id)"></displaycard>
					</div>
				</div>
			</div>
		</div>
		</div>
	</div>
</template>
<style scoped>
.tab {
	width: 100%;
	border-right: 1px solid #e9e9e9;
	border-top: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
	font-weight: 600;
	/* font-size: 14px; */
	line-height: 14px;
	background-color: #ffffff;

	color: #4d4d4d;
}
.flexPosition::after {
	content: '';
	flex: auto;
	flex-basis: 285px;
	flex-grow: 0;
}

.iconscolor {
	color: rgba(31, 41, 55, 0.5);
}
.correct {
	width: 100%;
	background: rgba(21, 42, 67, 0.1);
	border: 1px solid rgba(21, 42, 67, 0.5) !important;
	color: #152a43;
}
.tabs-boxed :first-child a {
	border-left: 1px solid #e9e9e9;
	border-top-left-radius: 4px;
	border-bottom-left-radius: 4px;
}
.mr-2 {
	border-right: 1px solid #e9e9e9;
	border-top: 1px solid #e9e9e9;
	border-bottom: 1px solid #e9e9e9;
	border-right: 1px solid #e9e9e9;
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	background-color: #ffffff;
}
.tabs-boxed {
	width: 100%;
	border-radius: 4px;
	background-color: transparent;
	padding: 0px;
}
.tab{
	height: 28px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import displaycard from '@/features/root-admin/display-card.vue';
import { mapGetters } from 'vuex';
import { globalApiMixin } from '../../GlobalMixins/apiGlobalMixins';
import { emitter } from '../../main';
import dotsLoader from '@/components/dotsLoader.vue'
export default defineComponent({
	mixins: [globalApiMixin],
	data(): any {
		return {
			x: '',
			isvertically: true,
			openTab: '',
			allClientCourses:[],
			filteredCourses: [],
			userAssignedCourses:[],
			navBarheading: ['All', 'In Progress', 'Overdue', 'Not Started', 'Completed'],
			finalCourses: [],
			routesArray:[]
		};
	},
	components: {
		displaycard,
		dotsLoader
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo'}),
		classObject: function (): any {
			return {
				// grid: this.isvertically,
				// relative: this.isvertically,
				// 'grid-cols-1': this.isvertically,
				// 'md:grid-cols-2': this.isvertically,
				// 'lg:grid-cols-3': this.isvertically,
				// 'gap-6': this.isvertically

				flex:this.isvertically,
				relative: this.isvertically,
				'gap-4': this.isvertically,
				
				//  'justify-between':this.isvertically,
				 'flex-wrap':this.isvertically,
				 'customVertically':this.isvertically,
			};
		}
	},
	methods: {
		toggleTabs(navIndex: any) {
			this.openTab = navIndex;
			this.filteredCourses = [];
			switch (navIndex) {
				case 0:
					this.filteredCourses = this.finalCourses;
					break;
				case 1:
					this.filteredCourses = this.finalCourses.filter((course: any) => {
						return course.statusCodeId == 10402;
					});
					break;
				case 2:
					this.filteredCourses = this.finalCourses.filter((course: any) => {
						return course.statusCodeId == 10404;
					});
					break;
				case 3:
					this.filteredCourses = this.finalCourses.filter((course: any) => {
						return course.statusCodeId == 10401;
					});
					break;
				case 4:
					this.filteredCourses = this.finalCourses.filter((course: any) => {
						return course.statusCodeId == 10403;
					});
					break;
			}
		},
		openCourseDetail(courseId: any) {
			
			this.$router.push({
				name: 'course-details',
				params: { courseId: courseId }
			});
		},
		async getAllCourses() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/user/${this.userInfo._id}/courses`).then((res: any) => {
				this.userAssignedCourses=res.data;
				/* If course is activated to organisation and course completed and again course is unassigned for organisation the course should hide */
				for(var i=0;i<this.allClientCourses.length;i++){
					for(var j=0;j<this.userAssignedCourses.length;j++){
						if(this.userAssignedCourses[j]._id==this.allClientCourses[i].masterCourseId){
							this.finalCourses.push(this.userAssignedCourses[j])	
						}
					}
				}
				return this.finalCourses 
			});
		},
		async getAllClientCourses() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/courses`).then((res: any) => {
				this.allClientCourses = res.data;
			})
			},

		ontoggle() {
			// console.log('HIIIIIIIIIIIIIIIIIIIi')
			// if (toggledstate == 'vertical') {
			// 	this.isvertically = true;
			// } else {
			// 	this.isvertically = false;
			// }
			this.isvertically = !this.isvertically
		}
	},
	async mounted() {
		await this.getAllClientCourses()
		await this.getAllCourses();
		this.toggleTabs(0);

	}
});
</script> 