
<template>
 <!-- <div v-if="dotsLoading" class="mt-2">
		<div class="flex items-center justify-center w-full h-full skeleton mt-4 tableCornerRadius bg-white">
			<dots-loader />
		</div>
	</div>	 -->
	<div class="pt-3 pl-5 pr-5">
		<div class="">
			<h1>Clients</h1>
		</div>
			<div>
			<div class=" text-black no-underline mt-3  moduleBox cursor-pointer" v-for="singleclient,indexx in tenantsList" :key="singleclient._id" :class="{'accordion':singleclient.isShow}">
			<div
				class="flex justify-start bg-white py-2.5 px-2.5 rounded"
				:class="{
					'accordion-head':!singleclient.isShow,
					deactive: !singleclient.isActive,
					active: singleclient.isActive
				}"
				@click="toggleAccordion(singleclient,indexx)"
			>
			<div class=" flex  items-center px-2.5">
				<img src="@/assets/rightArrowFilled.svg" class="h-4 w-4  cursor-pointer"  v-if="!singleclient.isShow"/>
					<img src="@/assets/downArrow.svg" class="h-4 w-4 cursor-pointer" v-else />	
					<!-- <img src="@/assets/downArrow.svg" class="h-4 w-4 cursor-pointer"  v-if="!singleclient.isShow"/>
					<img src="@/assets/upArrow.svg" class="h-4 w-4 cursor-pointer" v-else /> -->
			</div>
				<div class="title font-opensans  text-sm w-98  flex items-center" >
					{{ singleclient.organizationName }}
				</div>
				
			</div>

			<div class=" accordion-body bg-white " v-if="singleclient.isShow">
				<hr class="border-b border-solid border-lightgrey"/>
				<div class="flex justify-center flex-col px-5 pb-5 pt-5">
					
					<!-- <div > -->

						<table :key="singleclient._id">
							<thead>
								<tr>
									<th class="cursor-pointer">Course Name</th>
									<th class="cursor-pointer">Action</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="singleCourse of singleclient.Courses" :key="singleCourse._id">
								
									<td class="font-normal whitespace-nowrap overflow-hidden overflow-ellipsis">
										{{ singleCourse.courseName }}
									</td>
									<td class="font-normal whitespace-nowrap overflow-hidden overflow-ellipsis">
										<div>
											<label class="switch">
												<input type="checkbox" v-model="singleCourse.isClientCourseActive" @click="onActivate(singleCourse, singleclient)" />
												<span class="slider round"></span>
											</label>
										</div>
									</td>
								</tr>
							</tbody>
						</table>
				
				</div>
			</div>
		</div>
		</div>
		
	</div>
</template>

<style scoped>
.deactive {
	background-color: red;
}
.active {
	background-color: white;
}
.accordion-body{
	max-height: 380px;
	overflow-y: scroll;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { toast } from '../../main';
import { mapGetters } from 'vuex';
import dotsLoader from '@/components/dotsLoader.vue';

export default defineComponent({
	props: ['allClients'],
	components:{
		dotsLoader

	},
	data(): any {
		return {
			allCourses: [],
			teamsList: [],
			selected: [],
			isShow: false,
			isActive: false,
			clientActive: false,
			deleteCourseId: [],
			pageSize: 10,
			currentPage: 1,
			currentSort: 'name',
			currentSortDir: 'asc',
			activeCourses: [],
			clients: [
			],
			tenantsList: [],
			activeIndex:''
		};
	},
	methods: {
		async toggleAccordion(org:any,index:any){

			// this.activeIndex = index;

			// org.isShow = !org.isShow;

			// if(org.isShow == true){
			// 	org.activeCourses = await this.getClientAllCourses(org);
			// 	this.getActivatedCourses(org.activeCourses,org.Courses)
			// }

			// this.tenantsList.map((obj:any,itemIndex:any)=>{
			// 	if(itemIndex != index){
			// 		obj['isShow'] = false
			// 	}
			// })

			


			if(!org.isShow){
				org.activeCourses = await this.getClientAllCourses(org);
				this.getActivatedCourses(org.activeCourses,org.Courses)
			}
			org.isShow = !org.isShow;
		},
		
		async getAllTenants() {
				this.$http.get(`${process.env.VUE_APP_AUTH_API_URL}/tenants/getAll`).then((res: any) => {
				this.tenantsList = res.data;
				this.tenantsList.forEach((organisation:any) => {
					// organisation.Courses = [... this.allCourses]
					organisation.Courses=JSON.parse(JSON.stringify(this.allCourses))
				});
			});
		},

		onActivate(singleCourse: any, singleclient: any) {
			setTimeout((): any => {
				if (singleCourse.isClientCourseActive) {
					var courseDetails = [];
					courseDetails.push({
						masterCourseId: singleCourse._id,
						courseName: singleCourse.courseName,
						assignedBy: this.userInfo.sub,
						assignedAt: new Date()
					});
					this.$http.post(`${process.env.VUE_APP_TRAINING_API_URL}/api/course/${singleCourse._id}/assign/${singleclient.tenantName}`, courseDetails).then(async(response: any) => {
						if (response.status == 200) {
							singleclient.activeCourses = await this.getClientAllCourses(singleclient);
							toast.info('Updated', {
								timeout: 1000,
								closeOnClick: true,
								closeButton: 'button',
								icon: true
							});
							this.createscreen = true;
						}
					});
				} else {
					for (var i = 0; i < singleclient.Courses.length; i++) {
						this.deleteCourseId = singleclient.activeCourses.filter((item: any) => item.masterCourseId == singleclient.Courses[i]['_id']);
						if (this.deleteCourseId.length >= 1 && singleCourse._id == this.deleteCourseId[0].masterCourseId) {
							this.$http.delete(`${process.env.VUE_APP_TRAINING_API_URL}/client/${singleclient._id}/course/${this.deleteCourseId[0]._id}`).then((response: any) => {
								if (response.status == 200) {
									toast.info('Updated', {
										timeout: 1000,
										closeOnClick: true,
										closeButton: 'button',
										icon: true
									});
									this.createscreen = true;
								}
							});
						}
					}
				}
			}, 10);
		},
		// nextPage: function () {
		// 	if (this.currentPage * this.pageSize < this.allCourses.length) this.currentPage++;
		// },
		// prevPage: function () {
		// 	if (this.currentPage > 1) this.currentPage--;
		// },
		// cantGoBack() {
		// 	return this.currentPage === 1;
		// },

		// cantGoForward() {
		// 	return this.allCourses.length / this.pageSize <= this.currentPage;
		// },

		async getAllCourses() {
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/courses/getAll`).then((res: any) => {
				this.allCourses = res.data.filter((course: any) => {
					return course.isActive;
				});
			});
		},
		async getClientAllCourses(org:any) {
			return await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${org._id}/courses`).then((res: any) => {
				return res.data;
			});
		},

	getActivatedCourses(activeCourses:any,allCourses:any) {
			for (var i = 0; i < allCourses.length; i++) {
				var result = activeCourses.filter((item: any) => item.masterCourseId == allCourses[i]['_id']);
				if (result.length >= 1) {
					allCourses[i].isClientCourseActive = true;
					this.clientActive = true;
				} else {
					allCourses[i].isClientCourseActive = false;
				}
			}
		}
	},
	computed: {
		sortedData: function (): any {
			return [...this.allCourses]
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		...mapGetters({ userInfo: 'userInfo' })

	},
	async mounted() {
		await this.getAllCourses();
		await this.getAllTenants();
		// await this.getClientAllCourses();
		// await this.getActivatedCourses();
	}
});
</script>