<template>
	<div v-if="courses.length > 0 && slides.length > 0">
		<div class="navbar bg-white sticky z-50 top-0 left-0 flex shadow p-2">
			<div class="navbar-start">
				<div class="w-40">
					<div class="lg:flex">
						<span class="text-lg font-bold cursor-pointer">
							<img src="@/assets/dynematrix.svg" alt="" class="w-96 h-8" />
						</span>
					</div>
				</div>
			</div>
			<div class="navbar-center">
				<div class="items-center justify-center lg:flex">
					<h1>{{ courses[0].courseName }}</h1>
				</div>
			</div>
			<div class="navbar-end">
				<button class="btnprimary" @click="onExit()">EXIT</button>
			</div>
		</div>
		<div class="overflow-scroll h-screen">
			<div class="flex items-center flex-col p-2">
				<!-- <div class="items-baseline"> -->
				<!-- <p> Module 1 of 2 </p>
					<h1>Introduction</h1> -->
				<p class="modules">Module {{ modulesIndex + 1 }} of {{ activemodules.length }}</p>
				<h1>{{ activemodules[modulesIndex].moduleName }}</h1>
				<!-- </div> -->
			</div>
			<div class=" maincontainer flex flex-col items-center p-2">
				<div class="parent-carousel">
					<component v-if="slides.length > 0" :is="currentTabComponent" v-bind="currentProperties" :currentModule="currentModuleProperties" @module="getModuleAction" @video="toggleNextButton" :key="currentKey" class="card"></component>
					<p class="flex justify-end screens">Screen {{ slideIndex + 1 }} of {{ slides.length }}</p>
					<!-- <p class="flex justify-end screens" >End of Screens</p> -->
					<div class="controls">					
						<a role="button" data-slide="prev" class="carousel-control-prev"  @click="moveSlide('backward')" v-if="slideIndex != 0" >
							<svg width="42" height="42" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="30" cy="30" r="30" fill="white"/>
								<path d="M35.4344 19.5235C35.2328 18.9094 34.8438 18.4594 34.2859 18.1922C33.9719 18.0469 33.8781 18.0235 33.4844 18.0094C32.9781 17.986 32.6688 18.0563 32.2891 18.2766C32.0641 18.4079 21.5734 27.8672 21.0719 28.3875C20.6406 28.8422 20.5 29.2313 20.5 30C20.5 30.7688 20.6406 31.1579 21.0719 31.6125C21.5734 32.1375 32.0641 41.5922 32.2891 41.7235C32.6688 41.9438 32.9781 42.0141 33.4844 41.9907C33.8781 41.9766 33.9719 41.9532 34.2859 41.8079C34.75 41.5829 35.0828 41.2547 35.3078 40.786C35.4719 40.4391 35.4766 40.411 35.4766 39.8907C35.4766 39.2672 35.3875 38.9766 35.0781 38.5875C34.9797 38.461 32.8328 36.4829 30.3016 34.1907C27.7703 31.8985 25.7031 30.0141 25.7031 30C25.7031 29.986 27.7703 28.1016 30.3016 25.8094C32.8328 23.5172 34.9797 21.5391 35.0781 21.4125C35.3594 21.0563 35.4672 20.7282 35.4859 20.1797C35.4906 19.8844 35.4766 19.6407 35.4344 19.5235Z" fill="#4D4D4D"/>
							</svg>
							<span class="sr-only">Previous</span>
						</a>
						<div :class="{'inline':nextButton,'hidden':!nextButton}">
						<a role="button" data-slide="next" class="carousel-control-next"  @click="moveSlide('forward')" v-if="slideIndex != slides.length - 1">
							<svg width="42" height="42" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
								<circle cx="30" cy="30" r="30" fill="white"/>
								<path d="M24.5656 40.4765C24.7672 41.0906 25.1562 41.5406 25.7141 41.8078C26.0281 41.9531 26.1219 41.9765 26.5156 41.9906C27.0219 42.014 27.3312 41.9437 27.7109 41.7234C27.9359 41.5921 38.4266 32.1328 38.9281 31.6125C39.3594 31.1578 39.5 30.7687 39.5 30C39.5 29.2312 39.3594 28.8421 38.9281 28.3875C38.4266 27.8625 27.9359 18.4078 27.7109 18.2765C27.3312 18.0562 27.0219 17.9859 26.5156 18.0093C26.1219 18.0234 26.0281 18.0468 25.7141 18.1921C25.25 18.4171 24.9172 18.7453 24.6922 19.214C24.5281 19.5609 24.5234 19.589 24.5234 20.1093C24.5234 20.7328 24.6125 21.0234 24.9219 21.4125C25.0203 21.539 27.1672 23.5171 29.6984 25.8093C32.2297 28.1015 34.2969 29.9859 34.2969 30C34.2969 30.014 32.2297 31.8984 29.6984 34.1906C27.1672 36.4828 25.0203 38.4609 24.9219 38.5875C24.6406 38.9437 24.5328 39.2718 24.5141 39.8203C24.5094 40.1156 24.5234 40.3593 24.5656 40.4765Z" fill="#4D4D4D"/>
							</svg>					
							<span class="sr-only">Next</span>
						</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.navbar{
	min-height: 2.5rem;
	height:2.5rem
}
.parent-carousel {
	position: relative;
	margin-bottom: 2%;
}
.carousel-control-prev,
.carousel-control-next,
.carousel-control-audio {
	position: absolute;
	top: 0;
	bottom: 0;
	margin: auto 0;
	height: 42px;
	width: 42px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background-image: none;
	opacity: unset;
}

.carousel-control-next {
	right: -60px;
}
.carousel-control-prev {
	left: -60px;
}
.carousel-control-audio {
	left: 50%;
	bottom: -105%;
	background-color: #c4c4c4;
}
svg:hover circle{
	fill: #A4A4A4;
}

</style>
<script lang="ts">
import VerticallyAccordionScreen from '@/templates/vAccordion.vue';
import HorizantallyAccordionScreen from '@/templates/hAccordion.vue';
import CheckBoxScreen from '@/templates/checkboxTemplate.vue';
import RadioScreen from '@/templates/radioTemplate.vue';
import TextBoxScreen from '@/templates/textTemplate.vue';
import commonTemplate from '@/templates/commonTemplate.vue';
import TabScreen from '@/templates/tabTemplate.vue';
import TextWithBulletPointsScreen from '@/templates/textwithbulletsTemplate.vue';
import VideoScreen from "@/templates/videoTemplate.vue";
import { emitter, toast } from '../../main';
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';
// import 'vue3-carousel/dist/carousel.css';
// import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default defineComponent({
	components: {
		// Carousel,
		// Pagination,
		// Navigation,
		// Slide,
		commonTemplate,
		HorizantallyAccordionScreen,
		VerticallyAccordionScreen,
		CheckBoxScreen,
		TabScreen,
		TextWithBulletPointsScreen,
		RadioScreen,
		TextBoxScreen,
		VideoScreen,
	},
	data(): any {
		return {
			slideIndex: 0,
			slides: [],
			screenNames: [],
			courses: [],
			modules: [],
			modulesIndex: 0,
			tabIndex:1,
			leftArrow:false,	
			nextButton:true,
			activeModules:[],		
		};
	},

	computed: {
		currentTabComponent: function (): any {
			console.log('cureent', this.slides[this.slideIndex].screenType);
			return this.slides[this.slideIndex].screenType;
		},
		currentProperties: function (): any {
			console.log("propData: this.slides[this.slideIndex].data ", this.slides[this.slideIndex].data )
			return {
				propData: this.slides[this.slideIndex].data };
		},
		currentKey:function():any{
			return this.slides[this.slideIndex]._id
		},
		currentModuleProperties: function (): any {
			return { moduleIndex: this.modulesIndex, courses: this.courses[0] };
		},

		...mapGetters({ userInfo: 'userInfo' })
	},
	methods: {
		toggleNextButton(status:any){
		   this.nextButton = status;
		},
		moveSlide(direction: string) {
			this.nextButton = true;
			if (direction == 'forward') {
				if (this.slideIndex == this.slides.length - 1) {
					this.endOfModule();
				} else {
					this.updateStatus(false);
				}
				this.screenNames[this.slideIndex].isActive = false;
				this.slideIndex += 1;
				this.screenNames[this.slideIndex].isActive = true;
			} else {
				this.screenNames[this.slideIndex].isActive = false;
				this.slideIndex -= 1;
				this.screenNames[this.slideIndex].isActive = true;
			}
		},
		goToTest() {
			this.$router.push({ name: 'test-screen', params: { courseId: this.$route.params.courseId } });
			// var closedroute: any = window.open(routeData.href, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
		},
		updateStatus(isEndOfModule: boolean) {
			let updatePayload;
			if (!isEndOfModule) {
				updatePayload = [
					{
						endOfModule: isEndOfModule,
						moduleId: this.$route.params.moduleId,
						statusCodeId: 10402,
						updatedAt: new Date(),
						screen: {
							screenId: this.slides[this.slideIndex]._id,
							statusCodeId: 10403
						},
						totalScreens: this.slides.length,
						completedScreens: this.slideIndex
					}
				];
			} else {
				updatePayload = [
					{
						endOfModule: isEndOfModule,
						moduleId: this.$route.params.moduleId,
						statusCodeId: 10403,
						updatedAt: new Date(),
						screen: {
							screenId: this.slides[this.slideIndex]._id,
							statusCodeId: 10403
						},
						totalScreens: this.slides.length,
						completedScreens: this.slideIndex
					}
				];
			}
			this.$http.post(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/user/${this.userInfo._id}/course/${this.$route.params.courseId}`, updatePayload);
		},
		endOfModule() {
			this.updateStatus(true);
			this.$router.push({
				name: 'course-modules',
				params: { courseId: this.$route.params.courseId }
			});
		},
		 handleBeforeUnload() {
      // Notify the parent window
      window.opener.postMessage('pageClosed', '*');
   		 },
		onExit() {
			window.close();
		},
		async getSingleCourse() {
			await this.$http
				.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/get`)
				.then((res: any) => {
					this.spinnerLoading = false;
					this.courses = res.data;
					this.modules = this.courses[0].modules.filter((module: any) => {return module.isModuleActive == true});
					this.modules.push({
						moduleName: 'Start Exam',
						moduleId:'11111',
						createdBy: '',
						createdAt: '',
						updatedBy: '',
						updatedAt: '',
						screens:['1334556577']
					})
					console.log("ssss",this.modules)
					this.activemodules = this.courses[0].modules.filter((module:any)=>{return module.isModuleActive == true && Object.keys(module).includes('screens')})
					this.modulesIndex = this.activemodules.findIndex((object: any) => {
						return object._id === this.$route.params.moduleId;
					});
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
				});
		},

		async getModuleAction(value: any) {
			if (value == 'nextmodule') {
				this.updateStatus(true);
				this.modulesIndex = this.modulesIndex + 1;
				let updatedModule = this.activemodules[this.modulesIndex];
				
				(this.slides = []), 
					(this.screenNames = []),
					await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${updatedModule._id}/screens/getAll`).then((res: any) => {
						res.data.forEach((item:any)=>{if(!Object.keys(item.data).includes("isScreenActive")){item.data.isScreenActive = true}
						})
						var filteredScreens = res.data.filter((item:any)=>{{return item.data.isScreenActive == true}});
						this.slides = filteredScreens;
						this.slides.push({
							_id: '626aa23b0620124dec6c548s6',
							createdBy: '118101476679108383767',
							updatedBy: '118101476679108383767',
							createdAt: '2022-04-28T14:18:35.639Z',
							updatedAt: '2022-04-28T14:18:35.639Z',
							screenName: 'Screen',
							screenType: 'commonTemplate',
							order: '',
							screenStyles: {
								backgroundimage: '',
								color: 'red'
							},
							data: {},
						});
						this.slides.forEach((singleScreen: any) => {
							this.screenNames.push({ screenName: singleScreen.screenName, isActive: false });
						});
					});
				this.slideIndex = 0;
				this.$router.push({ name: 'screens', params: { courseId: this.courses[0]._id, moduleId: this.activemodules[this.modulesIndex]._id, activeScreen: 0 } });
				this.screenNames[this.slideIndex].isActive = true;
			} else if (value == 'currentmodule') {
				this.updateStatus(true);
				this.slideIndex = 0;
				this.$router.push({ name: 'screens', params: { courseId: this.$route.params.courseId, moduleId: this.$route.params.moduleId, activeScreen: 0 } });
			} else if (value == 'startExam') {
				this.updateStatus(true);
				this.$router.push({ name: 'test-screen', params: { courseId: this.$route.params.courseId } });
				// var closedroute: any = window.open(routeData.href, '_blank', 'toolbar=yes,scrollbars=yes,resizable=yes');
			} else if (value == 'modules') {
				console.log('logged');
				this.endOfModule();
			}
		}
	},
	async mounted() {
		// Listen for the 'beforeunload' event on Page B
		 window.addEventListener('beforeunload', this.handleBeforeUnload);
		// await this.getSingleCourse();
		emitter.on('tabs', (isToggle: any) => {
			this.tabIndex = isToggle;
		});
	},
	beforeUnmount() {
    // Clean up the event listener when the component is about to be unmounted
   		 window.removeEventListener('beforeunload', this.handleBeforeUnload);
  		},
	async created() {
		await this.getSingleCourse();
		this.slideIndex = parseInt(this.$route.params.activeScreen) || 0;
		
		await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/screens/getAll`).then((res: any) => {
			res.data.forEach((item:any)=>{if(!Object.keys(item.data).includes("isScreenActive")){item.data.isScreenActive = true}})
			var filteredScreens = res.data.filter((item:any)=>{return item.data.isScreenActive == true});
			this.slides = filteredScreens;
			this.slides.push({
				_id: '626aa23b0620124dec6c548s6',
				createdBy: '118101476679108383767',
				updatedBy: '118101476679108383767',
				createdAt: '2022-04-28T14:18:35.639Z',
				updatedAt: '2022-04-28T14:18:35.639Z',
				screenName: 'Screen',
				screenType: 'commonTemplate',
				order: '',
				screenStyles: {
					backgroundimage: '',
					color: 'red'
				},
				data: {}
			});
			this.slides.forEach((singleScreen: any) => {
				this.screenNames.push({ screenName: singleScreen.screenName, isActive: false });
			});
			this.screenNames[this.slideIndex].isActive = true;
		});
	}
});
</script>