<template>
       <div class="flex items-center justify-between bottom-0 px-5 py-3" v-if="display==true" :class="(currentPage * pageSize) > (dynamicPagination) && (entityId==20010||entityId==20011) ?'border-t border-lightgrey':'border-none'">
            <div class="responsive-pagination">
                <div>
                    <p class="text-sm text-gray-700">
                        Showing
                        <span class="font-semibold">{{ (currentPage - 1) * pageSize + 1 }}</span>
                        -
                        <span v-if="dynamicPagination > pageSize" class="font-semibold"> {{ currentPage * pageSize }} </span>
                        <span class="font-semibold" v-else> {{ dynamicPagination }} </span>
                        of
                        <span class="font-semibold">{{ dynamicPagination }}</span>
                        results
                    </p>
                </div>
                <div class="flex">
                    <div class="inline-flex items-center justify-center border border-lightgrey rounded w-8 h-8 cursor-pointer" :class="cantGoBack ? 'disabled pointer-events-none' : ''" @click="goToPreviousPage()">
                        <div class="flex items-center justify-center">
                            <img src="@/assets/leftArrowFilled.svg" class="w-3 h-4" />
                        </div>
                    </div>
 
                    <div class="mx-2 flex">
                        <select v-model="currentPage" class="inputboxstyling dropDownWidth">
                            <option v-for="i in totalPages" :key="i" :value="i">{{i}}</option>
                        </select>
                        <div class="flex items-center justify-center w-10 h-8 ml-2">
                            <p class="pr-2">of</p>
                            <p>{{totalPages}}</p>
                        </div>
                    </div>
 
                    <div class="inline-flex items-center justify-center border border-lightgrey rounded w-8 h-8 cursor-pointer" :class="cantGoForward ? 'disabled pointer-events-none':''" @click="goToNextPage()">
                        <div class="flex items-center justify-center ">
                            <img src="@/assets/rightArrowFilled.svg" class="w-3 h-4"  />
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>
<style scoped>
.dropDownWidth{
    width: 52px !important;
}
.beforeIndex{
    border-right:0px;
}
.afterIndex{
    border-left:0px;
}
.responsive-pagination{
    display: flex;
    justify-content:space-between;
    align-items: center;
    flex: 1;
}
 
@media (max-width: 560px) {
  .responsive-pagination{
    display: flex;
    justify-content:center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    flex: 1;
}
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { emitter } from '@/main';
 
export default defineComponent({
    props: ['dynamicPagination','sortedData','entityId'],
    data(): any {
        return {
            pageIndexStart: [],
            currentPage: 1,
            totalPages:'',
            pageSize: 20,
            pagesArray:[],
            display:true,
        };
    },
    watch: {
        dynamicPagination: {
            handler(newVal, oldVal) {
                console.log('newVal', newVal);
                if (newVal !== oldVal) {
                    this.getPagination();
                    this.$emit('currentPageUpdate', this.currentPage, this.pageSize);
                }
            },
            deep: true
        },
        sortedData: {
            handler() {
                this.sortedData.length == 0 ? (this.display = false) : (this.display = true);
            },
            deep: true
        }
    },
    created() {
        this.getPagination();
    },
    mounted(){
        if(this.sortedData.length > 0){
            this.display = true
        }else{
            this.display = false
        }
    },
    computed: {
        cantGoBack(): any {
            this.$emit('currentPageUpdate', this.currentPage, this.pageSize);
            return this.currentPage === 1;
        },
        cantGoForward(): any {
            return this.currentPage == this.totalPages;
        }
    },
 
    methods: {
        getPagination() {
            this.totalPages = Math.ceil(this.dynamicPagination / this.pageSize);
 
            this.pagesArray = Array.from({ length: this.totalPages }, (_, index) => index + 1);
 
            this.currentPage = 1;
        },
        goToNextPage(){
            this.currentPage = this.currentPage + 1
        },
        goToPreviousPage(){
            this.currentPage = this.currentPage - 1
        },
    }
});
</script>