import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2ab76ca0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card screenCard flex flex-row font-opensans bodered" }
const _hoisted_2 = { class: "titleheader text-2xl mt-3 font-bold" }
const _hoisted_3 = { class: "datastyle mt-4 text-sm font-normal" }
const _hoisted_4 = ["onClick", "id"]
const _hoisted_5 = {
  key: 0,
  class: "ml-1 text-3xl"
}
const _hoisted_6 = {
  key: 0,
  class: "chang p-4"
}
const _hoisted_7 = { class: "font-normal text-black text-base" }
const _hoisted_8 = { class: "text-black font-normal text-sm mt-4" }
const _hoisted_9 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_audio_video_control_bar = _resolveComponent("audio-video-control-bar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["maincontainer", { ' h-screen flex flex-col justify-center items-center': !_ctx.propData }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass(["questionblock text-black p-4 w", {contentsize: _ctx.changeWidth}])
      }, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.templatePayload.headerLabel), 1),
        _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.templatePayload.headerData), 1)
      ], 2),
      _createElementVNode("div", {
        class: _normalizeClass(["optionsblock w-1 flex justify-end", {contentsizes: _ctx.changeWidth}])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templatePayload.accordions, (singleslide, index) => {
          return (_openBlock(), _createElementBlock("div", { key: index }, [
            _createElementVNode("div", {
              class: _normalizeClass([[
							{
								maxWidth: singleslide.isActive,
								minWidth: !singleslide.isActive && _ctx.isFlag
							},
							{ smWidth: !singleslide.isActive && !_ctx.isFlag }
						], "h-full flex flex-col"]),
              onClick: ($event: any) => (_ctx.onopen(singleslide, index)),
              id: 'block' + index
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["header flex text-white p-4", !singleslide.isActive ? 'justify-center' : 'justify-end'])
              }, [
                _createElementVNode("div", null, [
                  (!singleslide.isActive)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(index + 1), 1))
                    : _createCommentVNode("", true)
                ])
              ], 2),
              (singleslide.isActive)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, _toDisplayString(singleslide.label), 1),
                    _createElementVNode("div", _hoisted_8, _toDisplayString(singleslide.text), 1)
                  ]))
                : _createCommentVNode("", true)
            ], 10, _hoisted_4)
          ]))
        }), 128))
      ], 2)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("audio", {
        ref: "player",
        preload: "metadata",
        onLoadedmetadata: _cache[0] || (_cache[0] = ($event: any) => (_ctx.controlEvents('start',$event))),
        onTimeupdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.controlEvents('time',$event))),
        onEnded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.controlEvents('ended',$event)))
      }, [
        _createElementVNode("source", {
          src: _ctx.link,
          type: "audio/mp3"
        }, null, 8, _hoisted_9)
      ], 544),
      _createVNode(_component_audio_video_control_bar, {
        control: _ctx.controlBarEvent,
        type: true
      }, null, 8, ["control"])
    ])
  ], 2))
}