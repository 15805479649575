	<template>
	<div class="pt-3 pl-5 pr-5">
		<div>
			<h1 class="font-bold">Manage Courses</h1>
		</div>
		<div class="mt-3">
			<div class="flex flex-wrap gap-2">
				<div v-for="(singlecourse, scIndex) in allCourses" :key="singlecourse.id" class="mt-2">
					<div class="card cursor-pointer rounded" @click="onLoad(singlecourse, scIndex)">
						<div class="h-1/2">
							<figure>
								<img v-if="singlecourse.courseImage" :src="singlecourse.courseImage" class="bg-cover bg-no-repeat bg-center rounded-t" />
								<div v-else class="bg-primary flex justify-center items-center p-1 rounded-t">
									<h1 class="text-white text-lg" :class="singlecourse.courseName.length > 40? 'table-ellipsis':''">{{ singlecourse.courseName }}</h1>
								</div>
							</figure>
						</div>
						<div class="card-body p-4 h-1/2">
							<h1 :class="singlecourse.courseName.length > 40? 'table-ellipsis':''">
								{{ singlecourse.courseName }}
							</h1>
							<p class="text-sm overflow mt-2 text-justify">{{ singlecourse.courseDescription }}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	
	</div>
</template>
	<style scoped>
.overflow {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: 7;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: normal;
}
.z-index {
	z-index: 99999;
}
.m-4::after {
	content: '';
	flex: auto;
	flex-basis: 320px;
	flex-grow: 0;
}
.card {
	height: 380px;
	width: 320px;
}
.card-title {
	font-size: 16px;
	margin-bottom: 2px;
}
p {
	font-size: 12px;
	line-height: 18px;
}
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
	<script lang="ts">
import useVuelidate from '@vuelidate/core';
import { required, helpers, requiredIf } from '@vuelidate/validators';
import { defineComponent } from 'vue';
import axios from 'axios';
// import Multiselect from '@vueform/multiselect';
import moment from 'moment';
import { useToast } from 'vue-toastification';
import { mapGetters } from 'vuex';
import popUp from '@/components/popup.vue';
import { toast } from '@/main';
const numeric = helpers.regex(/^[0-9]*$/);
export default defineComponent({
	data(): any {
		return {
			v$: useVuelidate(),
			allCourses: [],
			isShow: false,
			teamsList: [],
			value: null,
			// optionsarray: [],
			optionsUserArray: [],
			department: [],
			users: [],
			allUsers: [],
			singlecourse: {},
			locationBasedOptions: {},
			locationBasedOptions_dummy: {},
			courseAssigned: [],
			dummyListForAllCourses: [],
			activateButton: false,
			updatescreen: false,
			statusCodeId: '',
			dummyStatusCodeId: '',
			dummyTeam: [],
			dummyUser: [],
			index: -1,
			newScreen: false,
			fileData: '',
			image: '',
			data: '',
			base64String: '',
			imageBase64Stringsep: '',
			dummySingleCourse: {},
			teamsDataArray: [],
			entitesDataArray: [],
			regionDataArray: [],
			globalTeams: [],
			globalTeamsDataArray: [],
			entitysTeams: [],
			entitysUsers: [],
			allUserArray: [],
			type: [
				{
					typeId: 20301,
					data: 'Global'
				},
				{
					typeId: 20302,
					data: 'Region'
				},
				{
					typeId: 20303,
					data: 'Entity'
				}
			],
			accessLevelTwo: [
				{ courseTargetTypeCodeId: 10501, data: 'EveryOne' },
				{
					courseTargetTypeCodeId: 10502,
					data: 'Teams'
				},
				{ courseTargetTypeCodeId: 10503, data: 'Users' }
			],
			orgUsersList: [],
			entityData1: [],
			coursePermissions: [],
			coursePermissions_dummy: [],
			dataSubmitted: false,
			// formTouched: false,
			newlyAddedCoursePermissions: [],
			readonlyDepts: [],
			existingReadonlyDepts: [],
			readonlyDepts_dummy: [],
			readonlyUsers: [],
			existingReadonlyUsers: [],
			readonlyUsers_dummy: []
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},			
	methods: {
		onLoad(singlecourse: any, scIndex: any) {
			console.log("singlecourse:",singlecourse);
			
			this.v$.$reset();
			this.newlyAddedCoursePermissions = [];
			this.singlecourse = { ...singlecourse };

			// this.cancelCoursePermissions();
			// Check if singlecourse is defined and not null
			if (!singlecourse) {
				// console.log('Single course data is missing.');
				return;
			}
			// Toggle the display of single course
			this.$router.push({ name: 'course_details', params: { courseId: singlecourse._id } });
			this.singlecourse.isShow = !this.singlecourse.isShow;

			// Set index and statusCodeId
			this.index = scIndex;

			// Check if courseTargetTypeCodeId is available and not an empty string
			if (!singlecourse.courseTargetTypeCodeId || singlecourse.courseTargetTypeCodeId === '') {
				// console.log('Course target type code ID is missing or empty.');
				this.statusCodeId = null;
			} else {
				this.statusCodeId = singlecourse.courseTargetTypeCodeId;
			}

			// Reset options and set coursePermissions
			// this.locationBasedOptions = { type: '', courseTargetTypeCodeId: '', courseTypeCodeId: '', entity: '', region: '', departments: [], users: [] };
			// this.locationBasedOptions_dummy = { type: '', courseTargetTypeCodeId: '', courseTypeCodeId: '', entity: '', region: '', departments: [], users: [] };
			// this.locationBasedOptions.forceUpdate = false;

			// Check if coursePermissions is an array and not empty
			// console.log(singlecourse.coursePermissions, 'check');
			// if (!Array.isArray(singlecourse.coursePermissions)) {
			// 	singlecourse.coursePermissions = [];
			// 	this.coursePermissions = [...singlecourse.coursePermissions];
			// } else if (singlecourse.coursePermissions.length === 0) {
			// 	this.coursePermissions = [];
			// } else {
			// 	this.coursePermissions = [...singlecourse.coursePermissions];
				// this.coursePermissions.forEach((item: any) => {
				// 	let departmentArray: any = [];
				// 	if (item.departments) {
				// 		item.departments.filter((dept: any) => {
				// 			departmentArray.push(dept.departmentId);
				// 		});
				// 		this.existingReadonlyDepts.push(departmentArray);
				// 	} else {
				// 		this.existingReadonlyDepts.push([]);
				// 	}
				// });
			// 	this.readonlyDepts_dummy = [...this.existingReadonlyDepts];
			// 	// console.log('deps', this.readonlyDepts);
			// 	this.coursePermissions.forEach((item: any) => {
			// 		let userArray: any = [];
			// 		if (item.users) {
			// 			item.users.filter((userObj: any) => {
			// 				userArray.push(userObj.userId);
			// 			});
			// 			this.existingReadonlyUsers.push(userArray);
			// 		} else {
			// 			this.existingReadonlyUsers.push([]);
			// 		}
			// 	});
			// }
			// this.readonlyUsers_dummy = [...this.existingReadonlyUsers];

			// Create a copy of single course for reference
			this.dummySingleCourse = { ...singlecourse };
			// console.log('dummy course single :', this.dummySingleCourse);
			// console.log(' course single :', this.singlecourse);
			this.locationBasedOptions_dummy = { ...this.locationBasedOptions };
			this.coursePermissions_dummy = [...this.coursePermissions];
			// console.log(typeof this.coursePermissions, 'type of');
			// console.log(this.coursePermissions_dummy, 'dummy permissions');
		},
		// async getAllUsers() {
		// 	await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
		// 		this.users = res.data;
		// 	});
		// 	let activeUsers = this.users.filter((user: any) => {
		// 		return user.isActive == true;
		// 	});
		// 	for (var i = 0; i < activeUsers.length; i++) {
		// 		this.optionsUserArray.push({ label: activeUsers[i].name, value: activeUsers[i]._id });
		// 	}
		// },
		// async getallTeamMembers() {
		// 	// Initialize arrays
		// 	this.allUserArray = [];
		// 	this.optionsUserArray = [];
		// 	// Fetch user data from the API
		// 	await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
		// 		// Filter out inactive users
		// 		this.orgUsersList = res.data.filter((user: any) => user.isActive == true);
		// 		// Build arrays for user options
		// 		for (var i = 0; i < this.orgUsersList.length; i++) {
		// 			let obj: any = { label: this.orgUsersList[i].name, value: this.orgUsersList[i]._id };
		// 			// Exclude current user from options
		// 			if (this.orgUsersList[i]._id !== this.userInfo._id) {
		// 				this.optionsUserArray.push(obj);
		// 			}
		// 			this.allUserArray.push(obj);
		// 		}
		// 	});
		// },

		async getAllRootCourses() {
			// Fetch all root courses from the API
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/courses/getAll`).then((res: any) => {
				this.allRootCourses = res.data;
			});
		},

		async getAllCourses() {
			// Fetch all courses from the API
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/api/${this.userInfo.organisationId}/courses`).then((res: any) => {
				this.allCourses = res.data;
				console.table("allcourses:",this.allCourses);
				// Update course data with corresponding root course data
				for (var i = 0; i < this.allCourses.length; i++) {
					for (var j = 0; j < this.allRootCourses.length; j++) {
						if (this.allCourses[i].masterCourseId == this.allRootCourses[j]._id) {
							this.allCourses[i].courseDescription = this.allRootCourses[j].courseDescription;
							this.allCourses[i].courseImage = this.allRootCourses[j].courseImageUrl;
							if (this.allRootCourses[j].coursePermissions) {
								this.allCourses[i].coursePermissions = this.allRootCourses[j].coursePermissions;
							}
						}
					}
				}
			});
		},

		// async getAllTeams() {
		// 	// Initialize teams data array
		// 	this.teamsDataArray = [];
		// 	// Fetch teams data from the API
		// 	await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/departments/get`).then((res: any) => {
		// 		// Process each team object
		// 		res.data.map((teamObj: any) => {
		// 			// Calculate entities count based on team type
		// 			if (Object.keys(teamObj).includes('entities') && teamObj.type == 20302) {
		// 				teamObj.entitiesCount = teamObj.entities.length;
		// 			} else if (teamObj.type == 20301) {
		// 				teamObj.entitiesCount = 'Global';
		// 				this.globalTeamsDataArray.push(teamObj);
		// 			} else {
		// 				teamObj.entitiesCount = 0;
		// 			}
		// 			// Push processed team object to teams data array
		// 			this.teamsDataArray.push(teamObj);
		// 		});
		// 	});
		// },

		// async getAllEntities() {
		// 	// Initialize entities data array
		// 	this.entitesDataArray = [];
		// 	// Fetch entities data from the API
		// 	await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
		// 		// Filter entities based on query parameters if present
		// 		if (Object.keys(this.$route.query).includes('from') && this.$route.query.from == 'region') {
		// 			res.data.map((obj: any) => {
		// 				if (obj.region == this.$route.params.regionId) {
		// 					this.entitesDataArray.push(obj);
		// 				}
		// 			});
		// 		} else {
		// 			this.entitesDataArray = res.data;
		// 		}
		// 	});
		// },

		// async getAllRegions() {
		// 	// Initialize region data array
		// 	this.regionDataArray = [];
		// 	// Fetch regions data from the API
		// 	await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/regions/getall`).then((res: any) => {
		// 		// Process each region object
		// 		res.data.map((obj: any) => {
		// 			obj.entities = [];
		// 			// Filter entities for the current region
		// 			let entityFilter = this.entitesDataArray.filter((entity: any) => obj._id == entity.region);

		// 			// Process filtered entities
		// 			if (entityFilter.length > 0) {
		// 				obj.entityCount = entityFilter.length;
		// 				entityFilter.map((entity: any) => {
		// 					obj.entities.push(entity._id);
		// 				});
		// 			} else {
		// 				obj.entityCount = 0;
		// 			}
		// 			// Push processed region object to region data array
		// 			this.regionDataArray.push(obj);
		// 		});
		// 	});
		// },
	},
	async mounted() {
		await this.getAllRootCourses();
		// await this.getAllUsers();
		// await this.getallTeamMembers();
		await this.getAllCourses();
		// await this.getAllTeams();
		// await this.getAllEntities();
		// await this.getAllRegions();
	}
});
</script>