import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6c1c9d3c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "maincontainer flex justify-center items-center" }
const _hoisted_2 = { class: "screenCard bg-white flex justify-between items-center p-4 rounded" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        class: "btnprimary md:ml-10",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.modules('modules')))
      }, "Course Home"),
      (_ctx.moduleIndex!=_ctx.courseModules.length)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            class: "btnprimary",
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.modules('nextmodule')))
          }, "Next Module"))
        : _createCommentVNode("", true),
      (_ctx.moduleIndex==_ctx.courseModules.length && _ctx.showExam)
        ? (_openBlock(), _createElementBlock("button", {
            key: 1,
            class: _normalizeClass(["btnprimary", _ctx.examStatus==true?'btnprimary':'btndisabled pointer-events-none']),
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.modules('startExam')))
          }, "Start Test", 2))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        class: "btnprimary md:mr-10",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.modules('currentmodule')))
      }, "Revisit Module")
    ])
  ]))
}