import store from "@/store";

export function adminNavigationGuard(to:any, from:any, next:any){
    console.log("store",store.getters.userInfo.organisationType)
    if(store.getters.userInfo.organisationType  == 10702){
        console.log("admin Type if")
        next('/org/courses')
    } else{
        console.log("admin Type else")
        next() //navigate to admin as he was of type admin
    }
}

export function organisationManagementNavigationGuard(to:any, from:any, next:any){
    console.log("store",store.getters.userInfo.organisationType)
    if(store.getters.userInfo.organisationType  == 10702){
        console.log("organasation Type")
        if(store.getters.userInfo.currentRole == 10208){
            console.log("organasation user Role")
            next('/org/courses')
        } else{
            console.log("organasation Not user Role")
            next();
        }
    } else{
        console.log("root Admin")
        next('/admin/courses/getall') //navigate to admin as he was of type admin
    }
}