<template>
	<div class="maincontainer" :class="{ ' h-screen flex flex-col justify-center items-center': !propData }">
		<div class="card flex">
			<div class="tabs -mb-px flex">
				<div v-for="(tab, index) in templatePayload.tabs" :key="index" class="tabstyling tab" v-on:click="toggleTabs(index)">
					<a class="tab-lg tab-lifted text-center"  :class="{ 'tab-active': openTab == index ,'tab-notactive': openTab != index }">
						{{ tab.label }}
					</a>
				</div>
			</div>
			<div class="border-grey">
					<div class="flex flex-row h-full" v-if="filteredCourses.imageUrl">
						<div class="font-opensans w-3/5 p-4">
							{{ filteredCourses.text }}
						</div>
						<div class="w-2/5 bg-cover bg-no-repeat bg-center" v-bind:style="{ backgroundImage: 'url(' + filteredCourses.imageUrl + ')' }"></div>
					</div>
				<div v-else class="p-4">
					{{ filteredCourses.text }}
				</div>
			</div>
		</div>
		<div >
		<audio  ref="player"  preload="metadata" @loadedmetadata="controlEvents('start',$event)" @timeupdate="controlEvents('time',$event)" @ended="controlEvents('ended',$event)" >
			<source @error="controlEvents('noSrc',$event)" :src="filteredCourses.audioUrl" type="audio/mp3" />
		</audio>
		<audio-video-control-bar  :control="controlBarEvent" :type="true"> </audio-video-control-bar>
		</div>
	</div>
</template>

<style scoped>
.tab{
	padding-left: 0px !important;
	padding-right: 0px !important;
}
.tab-lifted.tab-active{
	background: #E9E9E9;
	border-color:#4D4D4D;
	/* border-radius: 2px; */
	padding-left: 6px;
	padding-right: 6px;
	width: 100%;
	height:100%;
	display: flex;
	justify-content: center;
	align-items: center;
	/* font-weight:bold  */
}
.tab-notactive{
	width: 200px;	
	padding-left: 6px;
	padding-right: 6px;

}
.tab-lifted{
border-bottom: none;
}
.tabs{
	flex-wrap:nowrap;
}
.tabstyling{ 
	/* color: rgba(31, 41, 55, 0.5); */
	color:black;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap; 
	height: 40px;
}
.tabs-lg{
	font-size: 14px;
	font-weight:bold ;

}
a{
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap; 	
		
}
.p-4{
	font-size: 14px;
	line-height: 25px;
}
.border {
	height: 430px;
	border: 1px solid #4D4D4D !important
}
.card {
	height: 430px;
	width: 940px;
	background-size: 100% 100%;
	border-radius: 0px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;	
}
.tab.tab-active{
	color: rgba(31, 41, 55);
}
.iconscolor {
	color: rgba(31, 41, 55, 0.5);
}
.correct {
	background-color: #152a43;
	border-radius: 5px;
	color: white; 
}
@media only screen and (max-width: 1280px) {
	.card {
		height: 380px;
		width: 940px;
	}
}
@media only screen and (max-width: 1024px) {
	.card {
		height: 325px;
		width: 680px;
	}
}
@media only screen and (max-width: 768px) {
	.card {
		height: 300px;
		width: 500px;
	}
}
</style>
<script lang="ts">
import { emitter } from '@/main';
import { defineComponent } from 'vue';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
import audioVideoControlBar from "@/components/audioVideoControlBar.vue";
export default defineComponent({
	mixins: [globalApiMixin],
	props: ['propData'],
	data(): any {
		return {
			dataPopulated: false,
			templatePayload: {},
			labels: [],
			data: [],
			filteredCourses: [],
			
			link:'',
			controlBarEvent:'',
			screenType:'',
			openTab:0,
		};
	},
	components:{
		audioVideoControlBar
	},
	async mounted() {
		await this.fetchData();
		this.toggleTabs(0);
	},
	methods: {
		controlEvents(status:any,e:any){

			if (status == 'start') {
					this.controlBarEvent = {'start':e};
			} else if (status == 'time') {
					this.controlBarEvent = {'time':e};
			} else if(status == 'ended') {
					this.controlBarEvent = {'ended':'ended'};
			}else{
					this.controlBarEvent = {'noSrc':'e'};
			}
		},
		toggleTabs(navIndex: any) {
			this.openTab = navIndex;
			emitter.emit('tabs', navIndex);
			this.filteredCourses = [];
			this.filteredCourses = this.data[navIndex];
			this.$refs.player.load();
		},
		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
				this.screenType = this.propData.screenType;
				// this.link = this.templatePayload.audioUrl;
				this.dataPopulated = true;
				this.templatePayload.tabs.forEach((tab: any) => {
					this.labels.push(tab.label), this.data.push(tab);
				});
			} else {
				await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					console.log
					// this.link = this.templatePayload.audioUrl;
					this.screenType = response.data[0].screenType;
					this.templatePayload.tabs.forEach((tab: any) => {
						this.labels.push(tab.label), this.data.push(tab);
					});
				});
			}

		}
	}
});
</script>