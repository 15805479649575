<template>
	<div class="maincontainer" :class="{ ' h-screen flex flex-col justify-center items-center': !propData }">
		<div class=" card screenCard flex flex-col p-6">
			<div class="questionblock h-3/12 font-opensans">
				<p class="titleheader text-2xl mt-2 text-primary font-bold">
					{{ templatePayload.headerLabel }}
				</p>
				<p class="datastyle mt-1 text-black font-normal text-xs">{{ templatePayload.headerData }}</p>
			</div>
			<div class="p-2 h-9/12 mt-1">
				<div class="text-black no-underline mt-1 min-h-30 cursor-pointer" v-for="(accordion, index) in templatePayload.accordions" :key="index" @click="onopenAccordion(index)" :class="{ accordion: accordion.isActive }">
					
						<div class="flex accordion-head bg-white py-2.5 px-2.5 text-lg rounded" >
						<div class="w-2 flex items-center">
							<img src="@/assets/rightArrowFilled.svg" class="h-4 w-4 cursor-pointer" v-if="!accordion.isActive" />
							<img src="@/assets/downArrow.svg" class="h-4 w-4 cursor-pointer" v-else />
							<!-- <img src="@/assets/rightArrowAccordion.svg" class="h-4 w-4 cursor-pointer"  v-if="!singlecourse.isShow"/>
					<img src="@/assets/downarrowaccordion.svg" class="h-4 w-4 cursor-pointer" v-else />  -->
						</div>
						<div class="title font-opensans text-lg w-98 ml-1 flex " @click="isToggle()">
							{{ accordion.label }}
						</div>
					</div>
					<div class="accordion-body p-1" v-if="accordion.isActive">
						{{ accordion.text }}
					</div>
				</div>
			</div>
		</div>
		<div >
		<audio ref="player" preload="metadata" @loadedmetadata="controlEvents('start',$event)" @timeupdate="controlEvents('time',$event)" @ended="controlEvents('ended',$event)" >
			<source :src="link" type="audio/mp3" />
		</audio>
		<audio-video-control-bar :control="controlBarEvent" :type="true" > </audio-video-control-bar>
		</div>
	</div>
</template>

<style scoped>
.text-xs {
	font-size: 14px;
	line-height: 25px;
}
.accordion-head {
	background: #e9e9e9;

	color: black;
	font-size: 16px;
	box-shadow: none;
	border: none;
}
.screenCard {
	height: 430px;
	width: 940px;
	background-size: 100% 100%;
	border-radius: 4px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
.accordion {
	box-shadow: none;
	border-radius: 0px 0px 4px 4px;
	border: none;
}
/* .accordion {
	font-size: 16px;
} */
.accordion-body {
	background: white;
	border-radius: 0px 0px 4px 4px;
	margin-top: -2px;
	font-size: 14px;
	line-height: 18px;
	color: black;
	padding: 5px;
	border: 1px solid #e9e9e9;
	border-top: 0;
}
@media only screen and (max-width: 1280px) {
	.screenCard {
		height: 380px;
		width: 940px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
}
@media only screen and (max-width: 1024px) {
	.screenCard {
		height: 325px;
		width: 680px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
}
@media only screen and (max-width: 768px) {
	.screenCard {
		height: 300px;
		width: 500px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
import audioVideoControlBar from "@/components/audioVideoControlBar.vue";

export default defineComponent({
	mixins: [globalApiMixin],
	props: ['propData'],
	data(): any {
		return {
			dataPopulated: false,
			templatePayload: {},

			link:'',
			controlBarEvent:'',
			screenType:''
		};
	},
	components:{
		audioVideoControlBar
	},
	async mounted() {
		await this.fetchData();
	},
	methods: {
		controlEvents(status:any,e:any){
			if (status == 'start') {
					this.controlBarEvent = {'start':e};
			} else if (status == 'time') {
					this.controlBarEvent = {'time':e};
			} else {
					this.controlBarEvent = {'ended':'ended'};
			}
		},
		onopenAccordion(index: any) {
			for (var i = 0; i < this.templatePayload.accordions.length; i++) {
				if (i == index) {
					this.templatePayload.accordions[i].isActive = !this.templatePayload.accordions[i].isActive;
				} else {
					this.templatePayload.accordions[i].isActive = false;
				}
			}
		},

		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
				this.screenType = this.propData.screenType;
				this.link = this.templatePayload.audioUrl;
				this.dataPopulated = true;
			} else {
				await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					this.link = this.templatePayload.audioUrl;
					this.screenType = response.data[0].screenType;
					this.dataPopulated = true;
				});
			}
			this.$refs.player.load();
		}
	}
});
</script>