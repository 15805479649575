<template>
	<div class="pt-2">
		<div class="flexcontainer gap-2">
			<div class="card">
				<div class="image">
					<figure>
						<img v-if="allCourses.courseImageUrl" :src="Images(allCourses)" class=" bg-cover bg-no-repeat bg-center rounded-t" />
						<div v-else class="bg-primary flex justify-center items-center p-1 rounded-t">
								<h1 class="text-white text-lg">{{ allCourses.courseName}}</h1>
								</div>
					</figure>
				</div>
				<div class="card-body p-4 flex flex-col">
					<div class="content">
						<div>
							<h1 class="font-opensans  text-black font-semibold">{{ allCourses.courseName }}</h1>
						</div>
						<div class="card-data text-black mt-2">
						<p class="text-justify">{{ allCourses.courseDescription }}</p>
						</div>
					</div>
					<div class="cursor-pointer cardfooter h-5 items-center font-opensans font-bold text-xs flex justify-between mt-2" v-if="allCourses.statusCodeId == 10403" @click.stop="">
						<div class="flex rounded-full btnpills font-opensans text-xs font-bold bg-green text-white px-2 py-1">
							<div class="text-xs">Completed</div>
						</div>
					</div>

					<div class="cursor-pointer cardfooter h-5 items-center font-opensans font-bold text-xs flex mt-2" v-if="allCourses.statusCodeId == 10402" @click.stop="">
						<div class="flex rounded-full btnpills font-opensans text-xs font-bold bg-inprogress text-white px-2 py-1">
							<div class="text-xs">In Progress</div>
						</div>
					</div>
						<div class="cursor-pointer cardfooter h-5 items-center font-opensans font-bold text-xs flex mt-2" v-if="allCourses.statusCodeId == 10404" @click.stop="">
						<div class="flex rounded-full btnpills font-opensans text-xs font-bold bg-red text-white px-2 py-1">
							<div  class="text-xs">Overdue</div>
						</div>
					</div>
					<div class="cursor-pointer cardfooter h-5 items-center font-opensans font-bold text-xs flex mt-2" v-if="allCourses.statusCodeId == 10401" @click.stop="">
						<div class="flex rounded-full btnpills font-opensans text-xs font-bold bg-notStarted text-white px-2 py-1">
							<div class="text-xs">Not Started</div>
						</div>
						</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
.bg-notStarted{
  background-color: #4D4D4D;
}
.bg-inprogress{
	background-color: #FFC000;
}
.bg-green{
	background-color:#00B050;
}
.bg-red{
	background-color: #FF0000;
}
.bg-gray{
	background-color: #00B0F0;
}
.customVertically .bg-notStarted,
.customVertically .bg-inprogress,
.customVertically .bg-green,.customVertically .bg-red,
.customVertically .bg-gray
{
	position: absolute;
	left:13px;
	bottom:6px
}
.card-data {
	display: -webkit-box;
	-webkit-line-clamp: 5;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}
.customVertically .card {
	height: 380px;
	width: 320px;
	background: #ffffff;
	border: 1px solid #E9E9E9;
border-radius: 4px;
}
.customVertically .image,
.customVertically .card-body {
	height: 50%;
}
.vertical .card .cardfooter {
	display: flex;
	justify-content: space-between;
}
.card .card-body .content {
	flex: 1 1 auto;
}
.vertical .card {
	width: 100%;
	height: 190px;
	border: 1px solid #E9E9E9;
border-radius: 4px;

}
.vertical .image {
	width: 30%;
	background-color: #ffffff;
}
.vertical .card-body {
	width: 70%;
	background-color: #ffffff;
}
.vertical .card {
	align-items: stretch;
	flex-direction: row;
}
.vertical .data {
	display: flex;
}
.card-data {
	font-family: 'Open Sans';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 25px;
	color: #000000;
}
p{
	font-size: 12px !important;
	line-height: 18px;
}
h1{
	font-size: 16px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import moment from 'moment';
import { mapGetters } from 'vuex';
import { toast, emitter } from '../../main';
export default defineComponent({
	props: ['allCourses'],
	data(): any {
		return {
			time: moment().format('DD-MM-YYYY'),
			isHorizonatalView: false,
			completedBy: '',
			startedAt :''
		};
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' })
	},
	methods: {
		Images(payload: any) {
			console.log(payload.courseImageUrl);
			if (payload.courseImageUrl != '' && payload.courseImageUrl != undefined) {
				return payload.courseImageUrl;
			} else {
				return process.env.VUE_APP_COMMON_IMAGE;
			}
		},
		async getAllModules() {
			console.log("sss")
			await this.$http
				.get(`${process.env.VUE_APP_TRAINING_API_URL}/user/${this.userInfo._id}/course/${this.allCourses._id}/userActivity`)
				.then((a: any) => {
					this.userActivity = a.data[0];
					if(this.userActivity.modules){
						let module = this.userActivity.modules.length - 1;
					let date = new Date(this.userActivity.modules[module].updatedAt);
					this.completedBy = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
					let updateDate = new Date(this.userActivity.modules[0].updatedAt);
							this.startedAt = updateDate.getDate() + '-' + (updateDate.getMonth() + 1) + '-' + updateDate.getFullYear();
					}
					
				})

				.catch((error: any) => {
					toast.error("ddd", {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		downloadCerificate: function (userInfo: any): any {
			return `${process.env.VUE_APP_TRAINING_API_URL}/user/${userInfo._id}/course/${this.allCourses._id}/certificate`;
		}
	},
	async mounted() {
		await this.getAllModules();
	}
});
</script>