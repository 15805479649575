import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '@/assets/progressButton.svg'
import _imports_1 from '@/assets/volume.svg'
import _imports_2 from '@/assets/mute.svg'
import _imports_3 from '@/assets/playButton.svg'
import _imports_4 from '@/assets/pause.svg'


const _withScopeId = (n: any) => (_pushScopeId("data-v-4551eff6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "screenCard flex flex-row justify-center items-center w-full" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "bg-lightgrey h-20 width relative controlBar rounded-b-lg" }
const _hoisted_4 = { class: "mx-2.5 top-3 w-inherit relative" }
const _hoisted_5 = { class: "h-1.5 bg-white rounded-2xl w-full absolute" }
const _hoisted_6 = {
  key: 0,
  src: _imports_0,
  class: "playButton"
}
const _hoisted_7 = { class: "flex justify-center items-center mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["maincontainer", { ' h-screen flex flex-col justify-center items-center': !_ctx.propData }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("video", {
        ref: "player",
        preload: "metadata",
        onLoadedmetadata: _cache[0] || (_cache[0] = ($event: any) => (_ctx.start($event))),
        onDblclick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.fullScreenToggle && _ctx.fullScreenToggle(...args)), ["stop"])),
        onTimeupdate: _cache[2] || (_cache[2] = ($event: any) => (_ctx.update($event))),
        onClick: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.playPause && _ctx.playPause(...args)), ["prevent"])),
        onEnded: _cache[4] || (_cache[4] = ($event: any) => (_ctx.playPause('ended')))
      }, [
        _createElementVNode("source", {
          type: "video/mp4",
          src: _ctx.link
        }, null, 8, _hoisted_2),
        _createTextVNode(" Your browser does not support the video tag. ")
      ], 544)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", {
            class: "bg-primary rounded-2xl h-full w-0 absolute z-50 flex justify-end",
            ref: "progress",
            style: _normalizeStyle(_ctx.progress)
          }, [
            (_ctx.playerButton)
              ? (_openBlock(), _createElementBlock("img", _hoisted_6))
              : _createCommentVNode("", true)
          ], 4)
        ]),
        _createElementVNode("div", {
          class: _normalizeClass(["flex justify-between relative top-3 text-xs", {'h-4':_ctx.videoTotalDuration ==''}])
        }, [
          _createElementVNode("div", null, _toDisplayString(_ctx.videoCurrentposition), 1),
          _createElementVNode("div", null, _toDisplayString(_ctx.videoTotalDuration), 1)
        ], 2),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", {
            class: "mr-11",
            onClick: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.mute && _ctx.mute(...args)), ["stop"]))
          }, [
            _createElementVNode("img", {
              src: _imports_1,
              alt: "Volume",
              class: _normalizeClass({'hidden':_ctx.toggleMute,'block':!_ctx.toggleMute})
            }, null, 2),
            _createElementVNode("img", {
              src: _imports_2,
              alt: "Mute",
              class: _normalizeClass({'hidden':!_ctx.toggleMute,'block':_ctx.toggleMute})
            }, null, 2)
          ]),
          _createElementVNode("div", {
            onClick: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.playPause && _ctx.playPause(...args)), ["stop"]))
          }, [
            _createElementVNode("img", {
              src: _imports_3,
              alt: "Pause",
              class: _normalizeClass({'hidden':_ctx.togglePlay,'block':!_ctx.togglePlay})
            }, null, 2),
            _createElementVNode("img", {
              src: _imports_4,
              alt: "Play",
              class: _normalizeClass({'hidden':!_ctx.togglePlay,'block':_ctx.togglePlay})
            }, null, 2)
          ])
        ])
      ])
    ])
  ], 2))
}