import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-1942bb10"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "screenCard flex flex-row h-full" }
const _hoisted_2 = { class: "p-6 w-3/5 font-opensans" }
const _hoisted_3 = { class: "titleheader text-2xl mt-6 text-primary font-bold" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "mt-2 pl-6" }
const _hoisted_6 = {
  type: "I",
  class: "list-disc"
}
const _hoisted_7 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_audio_video_control_bar = _resolveComponent("audio-video-control-bar")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["maincontainer", { ' h-screen flex flex-col justify-center items-center': !_ctx.propData }])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.templatePayload.headerLabel), 1),
        _createElementVNode("p", {
          class: "text-xs mt-4 text-primary font-normal",
          innerHTML: _ctx.templatePayload.headerData
        }, null, 8, _hoisted_4),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("ol", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.templatePayload.textbullets, (text) => {
              return (_openBlock(), _createElementBlock("li", {
                key: text,
                class: "mt-1"
              }, _toDisplayString(text.text), 1))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", {
        class: "w-2/5 bg-cover bg-no-repeat bg-center",
        style: _normalizeStyle(_ctx.backgroundImages(_ctx.templatePayload))
      }, null, 4)
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("audio", {
        ref: "player",
        preload: "metadata",
        onLoadedmetadata: _cache[0] || (_cache[0] = ($event: any) => (_ctx.controlEvents('start',$event))),
        onTimeupdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.controlEvents('time',$event))),
        onEnded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.controlEvents('ended',$event)))
      }, [
        _createElementVNode("source", {
          src: _ctx.link,
          type: "audio/mp3"
        }, null, 8, _hoisted_7)
      ], 544),
      _createVNode(_component_audio_video_control_bar, {
        control: _ctx.controlBarEvent,
        type: true
      }, null, 8, ["control"])
    ])
  ], 2))
}