<template>
	<div class="maincontainer" :class="{ ' h-screen flex flex-col justify-center items-center': !propData }">
		<div class=" card screenCard flex flex-row font-opensans bodered">
			<div class="questionblock text-black p-4 w"
			:class="{contentsize: changeWidth}">
				<p class="titleheader text-2xl mt-3  font-bold">
					{{ templatePayload.headerLabel }}
				</p>
				<p class="datastyle mt-4  text-sm font-normal">{{ templatePayload.headerData }}</p>
			</div>
			<div class="optionsblock w-1 flex justify-end" 	:class="{contentsizes: changeWidth}">
				<div v-for="(singleslide, index) in templatePayload.accordions" :key="index">
					<div

					:class="[
							{
								maxWidth: singleslide.isActive,
								minWidth: !singleslide.isActive && isFlag
							},
							{ smWidth: !singleslide.isActive && !isFlag }
						]"
						
						@click="onopen(singleslide, index)"
						:id="'block' + index"
						class="h-full flex flex-col"
					>
						<div class="header flex text-white p-4" :class="!singleslide.isActive ? 'justify-center' : 'justify-end'">
							<div>
								<!-- <div v-if="!singleslide.isActive" class="text-silver cursor-pointer">
									<PlusCircleIcon class="h-6 w-6" />
								</div>
								<div v-else class=" cursor-pointer text-primary">
									<MinusCircleIcon class="h-6 w-6" />
								</div> -->
								<div v-if="!singleslide.isActive" class="ml-1 text-3xl">
									{{ index + 1 }}
								</div>
							</div>
						</div>
						<div v-if="singleslide.isActive" class="chang p-4">
							<div class=" font-normal text-black text-base">
								{{ singleslide.label }}
							</div>

							<div class=" text-black font-normal text-sm mt-4">
								{{ singleslide.text }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div >
		<audio ref="player" preload="metadata" @loadedmetadata="controlEvents('start',$event)" @timeupdate="controlEvents('time',$event)" @ended="controlEvents('ended',$event)" >
			<source :src="link" type="audio/mp3" />
		</audio>
		<audio-video-control-bar :control="controlBarEvent" :type="true"> </audio-video-control-bar>
		</div>
	</div>
</template>

<style scoped>
#block3{
	border-right:1px solid #E9E9E9;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 2px 2px 0px rgba(0, 0, 0, 0.1);
border-radius: 0px 4px 4px 0px;
}
.ml-1{
	color: #4D4D4D;
	font-size: 70px;
	margin-top: 24px;
}
.w{
width: 540px;

}
.w-1{
width: 400px;
}
.screenCard {
	height: 430px;
	width: 940px;
	background-size: 100% 100%;
	border-radius: 4px;
	border-bottom-left-radius: 0px;
	border-bottom-right-radius: 0px;
}
/* #block2 {
	border-radius: 0px 20px 20px 0px;
} */
.contentsize{
	width: 300px;
}
.contentsizes{
	width: 640px;
}
.smWidth {
	/* width: 90px; */
	width: 80px;
	background: #E9E9E9;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 2px 2px 0px rgba(0, 0, 0, 0.1);
	display: flex;
}
.maxWidth {
	width: 420px;
	background: #D9D9D9;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 2px 2px 0px rgba(0, 0, 0, 0.1);
	/* width: 300px; */
	/* background: #E9E9E9;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 2px 2px 0px rgba(0, 0, 0, 0.1);
	cursor: pointer; */
}
.minWidth {
	width: 80px;
	background: #E9E9E9;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 2px 2px 0px rgba(0, 0, 0, 0.1);
	cursor: pointer;
}
.text-sm{
	font-size: 14px;
line-height: 25px;
}
@media only screen and (max-width: 1280px) {
	.screenCard {
		height: 380px;
		width: 940px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
}
@media only screen and (max-width: 1024px) {
	.screenCard {
		height: 325px;
		width: 680px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
	.maxWidth {
		width: auto;
	}
}
@media only screen and (max-width: 768px) {
	.screenCard {
		height: 300px;
		width: 500px;
		background-size: 100% 100%;
		border-radius: 4px;
	}
}
</style>


<script lang="ts">
import { defineComponent } from 'vue';
import { globalApiMixin } from '../GlobalMixins/apiGlobalMixins';
import audioVideoControlBar from "@/components/audioVideoControlBar.vue";
export default defineComponent({
	props: ['propData'],
	mixins: [globalApiMixin],
	data(): any {
		return {
			isFlag: true,
			list: [],
			a:false,
			dataPopulated: false,
			templatePayload: {},

			link:'',
			controlBarEvent:'',
			screenType:''
		};
	},
	components:{
		audioVideoControlBar
	},
	computed:{
		changeWidth: function ():any{
			return this.a
		}
	},
	methods: {
		controlEvents(status:any,e:any){
			if (status == 'start') {
					this.controlBarEvent = {'start':e};
			} else if (status == 'time') {
					this.controlBarEvent = {'time':e};
			} else {
					this.controlBarEvent = {'ended':'ended'};
			}
		},
		onopen(singleslide: any, index: any) {
			console.log("dhhdh")
			if (singleslide.isActive == true) {
				for (var i = 0; i < this.templatePayload.accordions.length; i++) {
					if (i == index) {
						this.isFlag = true;
						this.templatePayload.accordions[i].isActive = !this.templatePayload.accordions[i].isActive;
						this.a=this.templatePayload.accordions[i].isActive
					} else {
						this.templatePayload.accordions[i].isActive = false;
						
					}
				}
			} else {
				for (var j = 0; j < this.templatePayload.accordions.length; j++) {
					if (j == index) {
						this.isFlag = false;
						this.templatePayload.accordions[j].isActive = !this.templatePayload.accordions[j].isActive;
						this.a=this.templatePayload.accordions[j].isActive;
						console.log(this.a)
					
					} else {
						this.templatePayload.accordions[j].isActive = false;
						
					}
				}
			}
		},

		async fetchData() {
			this.dataPopulated = false;
			if (this.propData) {
				this.templatePayload = this.propData;
				this.screenType = this.propData.screenType;
				this.link = this.templatePayload.audioUrl;
				this.dataPopulated = true;
			} else {
				await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/screen/${this.$route.params.screenId}`).then((response: any) => {
					this.templatePayload = response.data[0].data;
					this.link = this.templatePayload.audioUrl;
					this.screenType = response.data[0].screenType;
					this.dataPopulated = true;
				});
			}
			this.$refs.player.load();
		}
	},
	mounted() {
		this.fetchData();
	}
});
</script>