<template>
	<div class=" pt-3 px-5">
		<div>
			<breadCrumb :routesArray="routesArray"></breadCrumb>
		</div>
		<div class="w-full pt-2" v-if="course.length > 0">
			<div class="header flex items-center justify-between">
				<div class="flex cursor-pointer">
					<!-- <div><img src="@/assets/arrowLeftNavigation.svg" class="w-6 h-6 mr-4" @click="backtocourseEdit()" /></div> -->
					<h1>{{ moduleName[0].moduleName }}</h1>
				</div>
				<div>
					<div class="buttonposition flex justify-center items-center my-1.5 mx-0">
						<button class="btnprimary m-2.5" @click="addScreen()">Add Screen</button>
					</div>
				</div>
			</div>
			
			<div v-if="screens.length > 0">
				<div class="text-black no-underline mt-3 min-h-30 cursor-pointer" v-for="(singlescreen, moduleIndex) in screens" :key="singlescreen.id" :class="{ accordion: singlescreen.isActive }">
					
					<div class="flex justify-between bg-white py-2.5 px-2.5 text-sm rounded" :class="{ 'accordion-head': !singlescreen.isActive }"  @click="screenSelect(singlescreen, moduleIndex,'titleBar')">
						<div class="w-full flex items-center">
							<div class="mx-2.5">
								<img src="@/assets/rightArrowFilled.svg" class="h-4 w-4 cursor-pointer" v-if="!singlescreen.isActive" />
								<img src="@/assets/downArrow.svg" class="h-4 w-4 cursor-pointer" v-else />
							</div>
						
							<div class="title flex justify-between items-center font-opensans text-sm w-30 ml-1">
								<!-- <div @change.stop="changedInputControlValue(singlescreen, moduleIndex)" class="flex justify-between items-center" > -->
									<!-- <input v-if="singlescreen.toggleSourceInput" @click.stop @blur="singlescreen.toggleSourceInput = false" type="text" class="inputboxstyling" v-model="singlescreen.screenName" placeholder="Screen Name" /> -->
									<!-- v-if="!singlescreen.toggleSourceInput" -->
									<h6  class="screenName">
										{{ singlescreen.screenName }}
									</h6>
									<!-- <div class="controlicons flex p-1">
										<img src="@/assets/editIcon.svg" class="h-2.5 w-2.5" />
									</div> -->
								<!-- </div> -->
							</div>
							
							<div class="titlehead w-68"></div>
						</div>

						<div class="mr-1">
							<div class="rounded-full w-5 h-5 bg-green mx-auto" v-if="screens[moduleIndex].isScreenActive == true"></div>
							<div class="rounded-full w-5 h-5 bg-error mx-auto" v-else></div>
						</div>
						
						
						<!-- <div class="switch mr-1" >
							<label class="switch" :class="{
									'not-allowed': (singlescreen.isActive == undefined || singlescreen.isActive == false),
									'cursor-pointer': (singlescreen.isActive)
								}"
							>
								<input
									type="checkbox" 
									:disabled="singlescreen.isActive == undefined || singlescreen.isActive == false" 
									v-model="screens[moduleIndex].isScreenActive" 
								/>
								<span class="slider round"></span>
							</label>
						</div> -->

					</div>

					<div class="accordion-body bg-white" v-if="singlescreen.isActive" @click.stop="updateIndex(moduleIndex,$event)">
						<hr class="border-t-2" />
					
						<div>
							<div class="pt-3 px-5">

								<div class="flex">
									<div class="w-full">
										<label class="controllabel font-normal text-xs mb-2">Screen Name</label>
										<input
											type="text"
											class="inputboxstyling"
											placeholder="Name of the heading"
											v-model="singlescreen.screenName"
											:class="{
												'error-border p-1': singlescreen.validationScreenName && validCode == singlescreen.screenName
											}"
										/>
										<div v-if="singlescreen.validationScreenName && validCode == singlescreen.screenName">
											<div v-if="singlescreen.validationScreenName && validCode == singlescreen.screenName" class="text-red-500 text-xs">Screen Name Already Exist</div>
										</div>
									</div>

									<div class="flex justify-end items-end pl-6">
										<label class="switch">
											<input
												type="checkbox" 
												v-model="screens[moduleIndex].isScreenActive" 
											/>
											<span class="slider round"></span>
										</label>
									</div>
								</div>

								<div class="mt-3">
									<label class="controllabel font-normal text-sm mb-2">Select Screen</label>

									<select
										class="inputboxstyling"
										v-model="singlescreen.screenType"
										:class="{
											'error-border': v$.currentScreen.$error && v$.currentScreen.screenType.$error
										}"
										:disabled="singlescreen._id"
										@change.stop="changedInputControlValue(singlescreen, moduleIndex)"
									>
										<option value="" selected disabled hidden>Please select screen</option>
										<option value="RadioScreen">Radio Screen</option>
										<option value="TextBoxScreen">TextBox Screen</option>
										<option value="CheckBoxScreen">CheckBox Screen</option>
										<option value="TabScreen">Tab Screen</option>
										<option value="VerticallyAccordionScreen">VerticallyAccordion Screen</option>
										<option value="HorizantallyAccordionScreen">HorizantallyAccordion Screen</option>
										<option value="TextWithBulletPointsScreen">TextWith BulletPoints Screen</option>
										<option value="VideoScreen">Video Screen</option>
									</select>

									<div v-if="v$.currentScreen.$error">
										<div v-if="v$.currentScreen.screenType.$error" class="text-red-500 text-xs">Required</div>
									</div>
								</div>

								<div v-if="singlescreen.screenType == 'VideoScreen'">
									<div class="formgroup mt-2.5 ">
										<label class="controllabel font-normal text-sm mb-1">Video</label>
										<div>
											<label :htmlFor="moduleIndex">
												<input type="file" :id="moduleIndex" style="display: none" :ref="'element' + moduleIndex" @change.stop="imageCheckFile(moduleIndex,'element')" />
											</label>
										</div>
										<div class="flex justify-center items-center cursor-pointer border rounded py-2 border-lightgrey">
											<div v-if="singlescreen.videoUrl == '' && pagetype == 'edit'">
												<div class="flex justify-center">
													<label :htmlFor="moduleIndex" class="mt-2">
														<img src="@/assets/videoAdd.svg" alt="" class="w-52 h-32 cursor-pointer" @click="showImageBlock = !showImageBlock" />
													</label>
												</div>
												<div class="flex justify-center items-center mt-2">
														<div v-if="fileName">Selected File :  <span class="text-sm font-bold"> {{fileName}}</span>	</div>
												</div>
											</div>

											<div v-else-if="singlescreen.videoUrl == '' && pagetype == 'update'">
												<label :htmlFor="moduleIndex">
													<img src="@/assets/editImage.svg" alt="" class="w-52 h-32 cursor-pointer" @click="showImageBlock = !showImageBlock" />
												</label>
												<div class="flex justify-center items-center mt-2">
														<div v-if="fileName">Selected File :  <span class="text-sm font-bold"> {{fileName}}</span>	</div>
												</div>
											</div>
											
											<div v-else>
												<div class="flex justify-center">
													<label :htmlFor="moduleIndex" class="mt-px">
														<video ref="player" preload="metadata" class="w-96 h-52">
															<source type="video/mp4" :src="singlescreen.videoUrl" />
															Your browser does not support the video tag.
														</video>
													</label>
												</div>
												<div class="-mt-10 flex justify-center">
													<p class="borderstyle p-1">Edit Video</p>
												</div>
												<div class="flex justify-center items-center mt-2">
														<div v-if="fileName">Selected File: <span class="text-sm font-bold"> {{fileName}}</span>	</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<div v-if="singlescreen.screenType !== 'VideoScreen'">
									<div class="formgroup mt-3">
										<label class="controllabel font-normal text-xs mb-2">Enter the Header</label>
										<input
											type="text"
											class="inputboxstyling"
											placeholder="Name of the heading"
											v-model="singlescreen.headerLabel"
											:class="{
												'error-border': v$.currentScreen.$error && v$.currentScreen.headerLabel.$error
											}"
										/>
										<!-- {{v$.screens.$each.$response.$data[moduleIndex]}}-{{111}} -->
										<div v-if="v$.currentScreen.$error">
											<div v-if="v$.currentScreen.headerLabel.$error" class="text-red-500 text-xs">Required</div>
										</div>
									</div>
									<div class="formgroup mt-3">
										<label class="controllabel font-normal text-sm mb-2">Enter the Header Text</label>
										<textarea
											placeholder="Text"
											name=""
											class="inputboxstyling"
											id=""
											cols="3"
											rows="3"
											v-model="singlescreen.headerData"
											:class="{
    											'error-border': v$.currentScreen.$error && v$.currentScreen.headerData.$error
											}"
										>
										</textarea>
										<div v-if="v$.currentScreen.$error">
											<div v-if="v$.currentScreen.headerData.maxLengthValue.$invalid" class="text-red-500 text-xs">
												Characters should not be more than 300
											</div>
											<div v-else-if="v$.currentScreen.headerData.$error" class="text-red-500 text-xs">Required</div>
										</div>
											
									</div>
									<div class="formgroup mt-3" v-if="singlescreen.screenType !== 'TabScreen' && singlescreen.screenType !== 'VerticallyAccordionScreen' && singlescreen.screenType !== 'HorizantallyAccordionScreen'">
										<label class="controllabel font-normal text-sm mb-2">Image</label>
										<div>
											<label :htmlFor="moduleIndex">
												<input type="file" :id="moduleIndex" style="display: none" :ref="'element' + moduleIndex"  @change.stop="imageCheckFile(moduleIndex,'element')" />
											</label>
										</div>
										<div class="flex justify-center items-center  cursor-pointer border rounded py-2  border-lightgrey">
											<div>
												<div v-if="singlescreen.imageUrl == '' && pagetype == 'edit'">
													<div @click="isScreenselected" class="flex justify-center">
														<label :htmlFor="moduleIndex"  :class="{'pointer-events-none':enableUpload}" class="mt-2">
															<img src="@/assets/addImage.svg" alt="" class="w-52 h-32 cursor-pointer"/>
														</label>
													</div>
													<div class="flex justify-center items-center mt-2">
														<div v-if="fileName">Selected File :  <span class="text-sm font-bold"> {{fileName}}</span>	</div>
													</div>
												</div>

												<div v-else-if="singlescreen.imageUrl == '' && pagetype == 'update'">
													<label :htmlFor="moduleIndex">
														<img src="@/assets/editImage.svg" alt="" class="w-52 h-32 cursor-pointer" />
													</label>
													<div class="flex justify-center items-center mt-2">
														<div v-if="fileName">Selected File: <span class="text-sm font-bold"> {{fileName}}</span>	</div>
													</div>
												</div>
												<div v-else class="mt-px">
													<div class="flex justify-center">
														<label :htmlFor="moduleIndex">
															<img :src="singlescreen.imageUrl" alt="" class="w-96 h-52 cursor-pointer" />
														</label>
													</div>
													<label :htmlFor="moduleIndex">
														<div class="-mt-10 flex justify-center ">
															<p class="borderstyle p-1 font-semibold text-xs cursor-pointer">Edit Image</p>
														</div>
													</label>
													<div class="flex justify-center items-center mt-2">
														<div v-if="fileName">Selected File: <span class="text-sm font-bold"> {{fileName}}</span>	</div>
													</div>
												</div>
											</div>
											
										</div>
										<!-- <input type="file" :ref="'element' + moduleIndex" /> -->
										<!-- <input type="text" class="inputboxstyling" placeholder="Image Url" :ref="screens[moduleIndex].imageUrl" v-model="singlescreen.imageUrl" /> -->
									</div>
									<div class="formgroup mt-3" v-if="singlescreen.screenType !== 'TabScreen'">
											<!-- <label class="controllabel font-normal text-sm mb-1">Audio URL</label> -->
											<!-- <input type="text" class="inputboxstyling" placeholder="Audio Url of the content" v-model="singlescreen.audioUrl" /> 

											<div class="text-xl">or</div> -->

										<div class="formgroup mt-3" >
											<label class="controllabel font-normal text-sm mb-2">Audio</label>

											<div >
												<label :htmlFor="moduleIndex+'audio'">
													<input type="file" :id="moduleIndex+'audio'" style="display: none" :ref="'elementAudio' + moduleIndex"  @change.stop="audioCheckFile(moduleIndex)" />
												</label>
											</div>

											<div class="flex justify-center items-center cursor-pointer border rounded py-2  border-lightgrey">
												<div>
													<div v-if="singlescreen.audioUrl == '' && pagetype == 'edit'">
														<div @click="isScreenselected" class="flex justify-center">
															<label :htmlFor="moduleIndex+'audio'"  :class="{'pointer-events-none':enableUpload}" class="mt-2">
																<img src="@/assets/audio.svg" alt="" class="w-52 h-32 cursor-pointer"/>
															</label>
														</div>
														<div class="flex justify-center items-center mt-2">
															<div v-if="fileNameAudio">Selected File :  <span class="text-sm font-bold"> {{fileNameAudio}}</span>	</div>
														</div>
													</div>

													<div v-else-if="singlescreen.audioUrl == '' && pagetype == 'update'">
														<label :htmlFor="moduleIndex+'audio'">
															<img src="@/assets/editImage.svg" alt="" class="w-52 h-32 cursor-pointer" />
														</label>
														<div class="flex justify-center items-center mt-2">
															<div v-if="fileNameAudio">Selected File: <span class="text-sm font-bold"> {{fileNameAudio}}</span>	</div>
														</div>
													</div>
													<div v-else  class="mt-px">
														<div class="flex justify-center">
															<label :htmlFor="moduleIndex+'audio'" class="w-96 h-52 cursor-pointer flex justify-center border border-lightgrey rounded ">
																<audio  preload="metadata" controls controlsList="nodownload noplaybackrate" :htmlFor="moduleIndex+'audio'" class="mt-6">
																	<source :src="singlescreen.audioUrl" type="audio/mp3" />
																</audio>
																<!-- <img :src="singlescreen.audioUrl" alt="" class="w-96 h-52 cursor-pointer" /> -->
															</label>
														</div>
														<div class="-mt-14 flex justify-center">
															<label :htmlFor="moduleIndex+'audio'">
															<p class="borderstyle p-1" >Edit Audio</p>
															</label>
														</div>
														<div class="flex justify-center items-center mt-6">
															<div v-if="fileNameAudio">Selected File: <span class="text-sm font-bold"> {{fileNameAudio}}</span>	</div>
														</div>
													</div>
												</div>
											</div>
									   </div>
									</div>
									<div class="formgroup mt-3" v-if="singlescreen.screenType == 'RadioScreen' || singlescreen.screenType == 'CheckBoxScreen'">
										<label class="controllabel font-normal text-sm mb-2">Enter the options Header</label>
										<input type="text" class="inputboxstyling mb-3" placeholder="Name of the options header" v-model="singlescreen.optionsLabel" />
										<div class="optionsblock">
											<label class="controllabel font-normal text-sm">Enter the option</label>
											<div v-for="(singleoption, optionIndex) in singlescreen.options" :key="singleoption.id">
												<div class="accordion-head optionscontainer border bg-white mt-2.5 p-1.5 text-black flex justify-between min-h-30" :class="{
													'error-border p-1': singlescreen.options.length < 2
												}">
													<div class="imgoptioncontainer flex">
														<div v-if="singleoption.isCorrect" class="mt-1">
															<img src="@/assets/correctIcon.svg" class="h-5 w-5" />
														</div>
														<div v-else class="mt-1">
															<img src="@/assets/cancel.svg" class="h-5 w-5" />
														</div>
														<div class="ml-2">
															{{ singleoption.label }}
														</div>
													</div>
													<div @click="onDelete(optionIndex, moduleIndex)">
														<img src="@/assets/deleteIcon.svg" class="h-5 w-5" />
													</div>
												</div>
												<div v-if="singlescreen.options.length < 2" class="text-red-500 mt-1">
 													 <div class="error-text">At least 2 options are required.</div>
												</div>
											</div>
											<div class="text-black no-underline mt-6 min-h-30">
												<div class="flex justify-between font-normal controllabel bg-white text-xs">options to add</div>
												<div class="accordion-body p-3">
													<input type="text" class="inputboxstyling" placeholder="Options" v-model="radioScreenObject.optionsData"
													 :class="{
															'error-border': v$.radioScreenObject.$error && v$.radioScreenObject.optionsData.$error && singlescreen.options.length < 2
														}"/>
														<div v-if="v$.radioScreenObject.$error">
															<div v-if="v$.radioScreenObject.optionsData && singlescreen.options.length<2" class="text-red-500 text-xs">Required</div>
														</div>
														
													<div v-if="singlescreen.answerVisibility">
														<div class="formgroup mt-2.5 flex items-center">
															
															<label htmlFor="check">
															<input type="checkbox" id="check" class="pt-px" v-model="singlescreen.correctAnswer" />
															<span class="validateoption ml-2.5">Only correct answer</span>
															</label>
														</div>
													</div>
													<!-- {{single}} -->
													<!-- <div v-if="singlescreen.options.length < 2">
														<div v-if="singlescreen.optionslength < 2" class="text-red-500 text-xs">Required</div>
													</div> -->
												</div>
												<div class="buttonposition flex justify-center items-center my-1.5 mx-0">
													<button type="button" class="btnprimary m-2.5" @click="addOptions(moduleIndex, radioScreenObject.optionsData)">Add</button>
												</div>
											</div>
										</div>
									</div>
									<div v-if="singlescreen.screenType == 'TextWithBulletPointsScreen'">
										<label class="controllabel font-normal text-sm mt-2 block">Enter the Data</label>
									</div>
									<div class=" card formgroup mt-2.5" v-if="singlescreen.screenType == 'TextWithBulletPointsScreen'">
										<div v-for="(singletext, optionIndex) in singlescreen.textbullets" :key="singletext.id" class="px-2">
											<div class="accordion-head optionscontainer border bg-white mt-2.5 p-1.5 text-black flex justify-between min-h-30">
												<div class="imgoptioncontainer flex">
													<div class="ml-2 text-xs">
														{{ singletext.text }}
													</div>
												</div>
												<div class="flex">
													<div @click="ontextbulletEdit(optionIndex, moduleIndex)">
														<img src="@/assets/editIcon.svg" class="h-5 w-5" />
													</div>
													<div @click="ontextbulletDelete(optionIndex, moduleIndex)">
														<img src="@/assets/deleteIcon.svg" class="h-5 w-5" />
													</div>
												</div>
											</div>
										</div>
										<div class="text-black no-underline mt-6 min-h-30">
											<div class=" controllabel flex justify-between bg-white text-xs pl-2">options to add</div>
											<div class="accordion-body p-6">
												<textarea name="" class="inputboxstyling mt-4" id="" cols="3" rows="3" placeholder="Accordion Data" v-model="presentbulletPoint.text" :class="{'error-border':v$.presentbulletPoint.$error && v$.presentbulletPoint.text.$error}"></textarea>
												<div v-if="v$.presentbulletPoint.$error">
													<!-- <div v-if="v$.presentbulletPoint.text.maxLengthValue.$invalid" class="text-red-500 text-xs">Characters should not be more than 670 </div> -->
													<div v-if="v$.presentbulletPoint.text.$error" class="text-red-500 text-xs">Required</div>
													
												</div>
											</div>
											<div class="buttonposition flex justify-center items-center my-1.5 mx-0">
												<button type="button" :class="(presentbulletPoint.label==''|| presentbulletPoint.label==undefined)&& (presentbulletPoint.text==''||presentbulletPoint.text==undefined) ?'btndisabled pointer-events-none':'btnprimary'" @click="savetextbulletData(moduleIndex)" v-if="!updateButton">Add</button>
												<button type="button" :class="JSON.stringify(presentbulletPoint)!=JSON.stringify(dummybulletPoint) ? 'btnprimary' : 'btndisabled pointer-events-none '" @click="updatetextbulletData(moduleIndex)" v-if="updateButton">Update</button>
											</div>
										</div>
									</div>
									
									<div v-if="singlescreen.screenType == 'HorizantallyAccordionScreen' || singlescreen.screenType == 'VerticallyAccordionScreen'">
										<label class="controllabel font-normal text-sm mt-2 block">Enter the Accordion</label>
									</div>
									<div class=" card optionsblock mt-6" v-if="singlescreen.screenType == 'HorizantallyAccordionScreen' || singlescreen.screenType == 'VerticallyAccordionScreen'">
										<div v-for="(singleoption, optionIndex) in singlescreen.accordions" :key="singleoption.id" class="px-2">
											<div class="accordion-head optionscontainer border bg-white mt-2.5 p-1.5 text-black flex justify-between min-h-30">
												<div class="imgoptioncontainer flex">
													<div class="ml-2 text-xs">
														{{ singleoption.label }}
													</div>
												</div>
												<div class="flex">
													<div @click="onVerticallyChildAccordionEdit(optionIndex, moduleIndex)">
														<img src="@/assets/editIcon.svg" class="h-5 w-5" />
													</div>
													<div @click="onVerticallyChildAccordionDelete(optionIndex, moduleIndex)">
														<img src="@/assets/deleteIcon.svg" class="h-5 w-5" />
													</div>
												</div>
											</div>
										</div>
										<div class="text-black no-underline mt-6 min-h-30">
											<div class=" controllabel flex justify-between bg-white py-2.5 px-2 text-xs">options to add</div>
											<div class="accordion-body p-6">
												<input type="text" class="inputboxstyling" placeholder="Name of the Accordion" v-model="accordionOriginalObject.label" 
												  :class="{'error-border':v$.accordionOriginalObject.$error && v$.accordionOriginalObject.label.$error && singlescreen.accordions.length > 4
												  }" />
												<div v-if="v$.accordionOriginalObject.$error">
												<div v-if="v$.accordionOriginalObject.label.$error" class="text-red-500 text-xs">Required</div>
												<div v-if="singlescreen.accordions.length > 4" class="text-red-500" > should not be greater than 4</div>
												</div>
												
												<textarea name="" class="inputboxstyling mt-3" id="" cols="3" rows="3" placeholder="Accordion Data" v-model="accordionOriginalObject.text" :class="{'error-border':v$.accordionOriginalObject.$error && v$.accordionOriginalObject.text.$error}"></textarea>
												<div v-if="v$.accordionOriginalObject.$error">
													<div v-if="v$.accordionOriginalObject.text.maxLengthValue.$invalid" class="text-red-500 text-xs">Characters should not be more than 400 </div>
													<div v-else-if="v$.accordionOriginalObject.text.$error" class="text-red-500 text-xs">Required</div>
 													 <!-- <div v-else-if="v$.singlescreen.options.length <4" class="text-red-500 text-xs">only 4 options are required.</div>													 -->
												</div>
											</div>
											<div class="buttonposition flex justify-center items-center my-1.5 mx-0">
												<button type="button" :class="(accordionOriginalObject.label==''|| accordionOriginalObject.label==undefined)&& (accordionOriginalObject.text==''||accordionOriginalObject.text==undefined) ?'btndisabled pointer-events-none':'btnprimary'" @click="saveVerticallyChildAccordionData(moduleIndex)" v-if="!updateButton">Add</button>
												<button type="button" :class="JSON.stringify(accordionOriginalObject)!=JSON.stringify(dummyAccordionObj) ?'btnprimary':'btndisabled pointer-events-none'" @click="updateVerticallyChildAccordionData(moduleIndex)" v-if="updateButton">Update</button>
											</div>
										</div>
									</div>
									<div v-if="singlescreen.screenType == 'TabScreen'">
										<label class="controllabel font-normal text-sm mt-2 block">Enter the Tabs Data</label>
									</div>
									<div class="accordion optionsblock" v-if="singlescreen.screenType == 'TabScreen'">
										<div v-for="(singleoption, optionIndex) in singlescreen.tabs" :key="singleoption.id" class="px-2">
											
											<div class="optionscontainer accordion-head border rounded border-grey mt-2.5 p-1.5 text-white flex justify-between bg-darkslategrey min-h-30">
												<div class="imgoptioncontainer flex">
													<div class="ml-2 text-black text-xs">
														{{ singleoption.label }}
													</div>
												</div>
												<div class="flex">
													<div @click="onTabEdit(optionIndex, moduleIndex)">
														<img src="@/assets/editIcon.svg" class="h-5 w-5" />
													</div>
													<div @click="onTabDelete(optionIndex, moduleIndex)">
														<img src="@/assets/deleteIcon.svg" class="h-5 w-5" />
													</div>
												</div>
											</div>
										</div>
										<div class="text-black no-underline mt-1 min-h-30">
											<div class="flex  controllabel justify-between bg-white py-2.5 px-2  text-xs rounded">options to add</div>
											<div class="p-6">
											
												<input type="text" class="inputboxstyling" placeholder="Name of the Tab" v-model="tabScreenObject.label" :class="{'error-border':v$.tabScreenObject.$error && v$.tabScreenObject.label.$error}" />
												<div v-if="v$.tabScreenObject.$error">
												<div v-if="v$.tabScreenObject.label.$error" class="text-red-500 	text-xs">Required</div>
												</div>

												<textarea name="" class="inputboxstyling mt-4" id="" cols="3" rows="3" placeholder="Accordion Data" v-model="tabScreenObject.text" :class="{'error-border':v$.tabScreenObject.$error && v$.tabScreenObject.text.$error}"></textarea>
												<div v-if="v$.tabScreenObject.$error">
													<div v-if="v$.tabScreenObject.text.maxLengthValue.$invalid" class="text-red-500 text-xs">Characters should not be more than 1000 </div>
													<div v-else-if="v$.tabScreenObject.text.$error" class="text-red-500 text-xs">Required</div>
												</div>

													<div class="mt-2.5">
														<label class="controllabel font-normal text-sm mb-1">Image</label>
														<label :htmlFor="singlescreen.tabs.length">
															<input type="file" :id="singlescreen.tabs.length" style="display: none" ref="fileInput" @change.stop="imageCheckFile(moduleIndex,'fileInput')"/>
														</label>
														
													</div>
												<div class="border rounded py-2  border-lightgrey">
													<div class="flex justify-center" v-if="!singlescreen.tabs.imageUrl">
														<div>
															<div class="flex justify-center">
																<label :htmlFor="singlescreen.tabs.length" class="mt-2">
																	<img src="@/assets/addImage.svg" alt="" class="w-52 h-32 cursor-pointer" @click="showImageBlock = !showImageBlock" />
																</label>
															</div>
														<div class="flex justify-center items-center mt-2">
															<div v-if="fileName">Selected File :  <span class="text-sm font-bold"> {{fileName}}</span>	</div>
														</div>
														</div>
													</div>
													<div v-else >
														<div class="flex justify-center mt-px">
															<label :htmlFor="singlescreen.tabs.length">
																<img alt="" :src="singlescreen.tabs.imageUrl" class="w-96 h-52 cursor-pointer" />
															</label>
														</div>
														<label :htmlFor="singlescreen.tabs.length">
															<div class="-mt-10 flex justify-center ">
																<p class="borderstyle p-1 font-semibold text-xs cursor-pointer">Edit Image</p>
															</div>
														</label>
														<div class="flex justify-center items-center mt-2">
															<div v-if="fileName">Selected File :  <span class="text-sm font-bold"> {{fileName}}</span>	</div>
														</div>
													</div>
												</div>
												
												<label class="controllabel font-normal text-sm mt-2.5">Audio</label>
												<div class="flex justify-center items-center cursor-pointer border rounded py-2 border-lightgrey">
														<div >
															<div class="formgroup" >
																<div>
																	<label :htmlFor="singlescreen.tabs.length+'audio'">
																		<input type="file" :id="singlescreen.tabs.length+'audio'" style="display: none" ref="fileInputAudio"  @change.stop="audioCheckFile(moduleIndex)" />
																	</label>
																</div>
															</div>
															<div v-if="!singlescreen.tabs.audioUrl " class="mt-2">
																<div @click="isScreenselected" class="flex justify-center">
																	<label :htmlFor="singlescreen.tabs.length+'audio'"  :class="{'pointer-events-none':enableUpload}">
																		<img src="@/assets/audio.svg" alt="" class="w-52 h-32 cursor-pointer"/>
																	</label>
																</div>
																<div class="flex justify-center items-center mt-2">
																	<div v-if="fileNameAudio">Selected File :  <span class="text-sm font-bold"> {{fileNameAudio}}</span>	</div>
																</div>
															</div>
															<div v-else class="mt-px">
																<div class="flex justify-center border border-lightgrey rounded">
																	<label :htmlFor="singlescreen.tabs.length+'audio'" class="w-96 h-52 cursor-pointer flex justify-center  ">
																		<audio  preload="metadata" controls controlsList="nodownload noplaybackrate" :htmlFor="singlescreen.tabs.length+'audio'" class="mt-6">
																			<source :src="singlescreen.tabs.audioUrl" type="audio/mp3" />
																		</audio>
																	</label>
																</div>
																<div class="-mt-14 flex justify-center">
																	<label :htmlFor="singlescreen.tabs.length+'audio'">
																	<p class="borderstyle p-1" >Edit Audio</p>
																	</label>
																</div>
															<div class="flex justify-center items-center mt-6">
																<div v-if="fileNameAudio">Selected File: <span class="text-sm font-bold"> {{fileNameAudio}}</span>	</div>
															</div>
													     </div>
													 </div>
												</div>

											</div>
											</div>
											
											<div class="buttonposition flex justify-center items-center my-1.5 mx-0" v-if="singlescreen.tabs.length <= 4">
												<button type="button" :class="(tabScreenObject.label==''|| tabScreenObject.label==undefined)&& (tabScreenObject.text==''||tabScreenObject.text==undefined) ?'btndisabled pointer-events-none':'btnprimary'" @click="saveTabData(moduleIndex)" v-if="!updateButton">Add</button>

												<button type="button" :class="JSON.stringify(tabScreenObject)!=JSON.stringify(dummyTabObj) ? 'btnprimary' : 'btndisabled pointer-events-none '" @click="updateTabData (moduleIndex)"  v-if="updateButton">Update</button>
											</div>
										</div>
									</div>
								</div>
							<!-- </div> -->
							<div class="p-4">
								
								<div class="flex justify-center" :id="moduleIndex+singlescreen.screenName">
									<div class="buttonposition flex justify-center items-center">
										<input type="button" :id="moduleIndex+'Reset'" :class=" (JSON.stringify(originalObject) != JSON.stringify(singlescreen)) ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="resetData(singlescreen,moduleIndex)" value="Reset">
									</div>
									<div class="buttonposition flex justify-center items-center ml-6" v-if="singlescreen._id">
										<input type="button" :id="moduleIndex+'Update'" :class="(JSON.stringify(originalObject) != JSON.stringify(singlescreen))? 'btnprimary' : 'btndisabled pointer-events-none'" @click="createPayload(moduleIndex)" value="Update">
									</div>
									<div class="flex justify-center items-center ml-6" v-if="singlescreen._id">
										<input type="button" :id="moduleIndex+'Preview'" :class="singlescreen._id ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="preview(singlescreen.screenType, singlescreen._id)" value="Preview">
									</div>
									<div class="flex justify-center items-center ml-6" v-else>
										<input type="button" :id="moduleIndex+'Save'" :class="!singlescreen._id ? 'btnprimary' : 'btndisabled pointer-events-none'" @click="createPayload(moduleIndex)" value="Save">
									</div>
									
								</div>
							</div>
							
						</div>
					</div>
				</div>
			</div>

			<div class="text-center flex justify-center items-center text-sm mt-8" v-else>
				<div>No Screens. Please start adding by clicking on Add button on top.</div>
			</div>
			<spinner v-if="spinnerLoading" />
		</div>
	</div>
</template>
<style scoped>
.not-allowed {
	cursor: not-allowed !important;
}
.restrictToggle{
	cursor: no-drop !important;
	pointer-events: none !important;
}
.borderstyle {
	border: 1px solid #e9e9e9;
	background-color: #e9e9e9;
	border-radius: 4px;
}
</style>
<script lang="ts">
import { defineComponent } from 'vue';
import { useToast } from 'vue-toastification';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import spinner from '../../components/spinner.vue';
import breadCrumb from '@/components/breadCrumb.vue'
import { toast } from '../../main';
import { requiredIf, requiredUnless } from '@vuelidate/validators';
import { mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash';
import { maxLength } from '@vuelidate/validators';
export default defineComponent({
	components: {
		spinner,
		breadCrumb
	},
	computed: {
		...mapGetters({ userInfo: 'userInfo' }),
		disableStatus: function (): any {
			let disableStatusCondition;
			console.log('Dynamicity');
			if (this.currentScreen._id) {
				console.log('this.currentScreen', this.currentScreen);
				let { isActive,toggleSourceInput, ...rest } = this.currentScreen;
				console.log('rest', rest);
				console.log('this.screens[this.modIndex]', this.dummy[this.modIndex]);
				if (_.isEqual(rest, this.dummy[this.modIndex])) {
					disableStatusCondition = false;
				} else {
					disableStatusCondition = true;
				}
			}
			return disableStatusCondition;
		},
		// checkUpdateStatus:function ():any{
		// 	let result = false;
		// 						// {{dummyTabObj}}
		// 						// 		{{singlescreen.tabs.label}}

		// 						// 		{{singlescreen.tabs.text}}
		// 						// 		{{singlescreen.tabs.audioUrl}}
		// 						// 		{{singlescreen.tabs.imageUrl}}
		// 	if(this.dummyTabObj.label == undefined){
		// 		return false
		// 	}

		// 	if()
		// }
	},

	data(): any {
		return {
			v$: useVuelidate(),
			screens: [],
			showvalidation: false,
			showvalidationforaccordions: false,
			spinnerLoading: false,
			screenNameValidation: false,
			totalScreens: [],
			optionListIndex: '',
			updateButton: false,
			checkIds: [],
			course: '',
			moduleName: '',
			imageUrl: '',
			videoUrl: '',
			videoScreen: false,
			currentScreen: {},
			modIndex: '',
			dummy: [],
			dummyObject:{},

			fileStatus: true,

			validCode:'',
			fileName:'',

			fileNameAudio:'',

			enableUpload:true,

			audioUrl:'',
			originalObject:null,
			originalIndex:null,
			dummyScreens:[],
			routesArray:[],
			radioScreenObject:{},
			dummyradioScreenObject:{},
			tabScreenObject:{},
			accordionOriginalObject:{},
			dummyAccordionObj:{},
			dummyTabObj:{},
			presentTab:{},
			presentbulletPoint:{},
			dummybulletPoint:{},
			compareTabObj:{},
			TabCopyObj:{},
			TabCopydummy:{},
			addObjectCopy:{}

		};
	},

	validations() {
		return {
			currentScreen: {
				screenName: { required },
				screenType: { required },

				headerLabel: {
					required:
					 requiredUnless(() => {
						return this.videoScreen;
					})
				},
				headerData: {
					required: requiredUnless(() => {
						return this.videoScreen;
					}),
					maxLengthValue:maxLength(300)
					
				},
				// optionsData:{ required },
				// tabs:{required},
			
				videoUrl: {
					required: requiredIf(() => {
						return this.videoScreen;
					})
				}
			},
			tabScreenObject:{
				label:{required},
				text:{
					required,
					maxLengthValue:maxLength(1000)
				}
				
			},
			accordionOriginalObject:{
				label:{required},
				text:{
					required,maxLengthValue:maxLength(400)
				}

			},
			presentbulletPoint:{
				text:{required}
			},
			radioScreenObject:{
				optionsData:{required}
			}
		};
	},
	methods: {
		async toggleTheSlider(obj:any,index:any){
			await this.screenSelect(obj, index,'fromToggleSlide');
		},
		async toggleTheEditName(obj:any,index:any){
			this.screens[index].toggleSourceInput = true
			await this.screenSelect(obj, index,'fromToggle');
		},
		async screenSelect(singlescreen: any, index: any,status:any) {
			
			if(this.modIndex != index && this.modIndex != ''){	
				await this.resetData(this.screens[this.modIndex])
			}

			this.validCode = ''
			this.screens.map((screen:any,screenIndex:any)=>{
				if(screenIndex == index){
					this.screens[index].isActive = !this.screens[index].isActive;
					if(this.screens[index].isActive == true){
						let presentDummyScreen = this.dummyScreens.find((obj:any)=>{return singlescreen._id == obj._id})
						
						this.originalObject = JSON.parse(JSON.stringify(singlescreen))
						
						this.originalObject.isActive = true;
						this.originalIndex = index;
					}
				}else{
					this.screens[screenIndex].isActive = false;
				}
			})
		    // if(status){
			// 	// singlescreen.isActive = !singlescreen.isActive;
			// }
			// else{
			// 	singlescreen.isActive = true;
			// }
			// this.singlescreen._id ? this.enableUpload = false:'';
			this.videoUrl = '';
			this.imageUrl = '';
			this.videoScreen = false;
			this.modIndex = index;
			this.currentScreen = this.screens[this.modIndex];
			this.currentScreen._id ? this.isScreenselected():'';
			
			// console.log("changethis.videoScreen",this.videoScreen);
		},

		isScreenselected(){
			if(!this.screens[this.modIndex].screenType){
				this.enableUpload = true;
				toast.info('Select atleast one Screen Type', {
						timeout: 1500,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
			}else{
				this.enableUpload = false;
			}

		},
		updateIndex(index: any) {
			console.log('index', index);
			this.modIndex = index;
			this.currentScreen = this.screens[this.modIndex];
		},
		async refreshScreens(moduleIndex: any) {
			this.fileStatus = true;
			await this.getallScreens();
			await this.screenSelect(this.screens[moduleIndex], moduleIndex);
			this.fileName='';
			this.fileNameAudio = '';
		},
		changedInputControlValue(screen: any, index: any) {
			this.videoScreen = screen.screenType == 'VideoScreen';
			this.enableUpload = false;
		},
		audioCheckFile(index:any){
			let file = [];
			this.fileNameAudio = '';
			this.currentScreen.screenType !== 'TabScreen' ? (file = this.$refs[`elementAudio${index}`][0].files[0]) : (file = this.$refs.fileInputAudio[0].files[0]);
			console.log("[0].files[0][0].files[0][0].files[0]",file);

			if(file != undefined){
				if (file.type == 'audio/mpeg') {
				let size = Number((file.size / 1048576).toFixed(2));
					if (size > 80) {
						toast.error('Audio should be atmost 80 MB', {
							timeout: 1000,
							closeOnClick: true,
							closeButton: 'button',
							icon: true
						});
						this.resetAudio()
					} 
					else {
						this.fileNameAudio = file.name;
						this.fileStatus = false;
					}
			} else {
					toast.error('Supported only mp3', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.resetAudio()
			}
			}

		},
		resetAudio(){
			if(this.currentScreen.screenType != 'TabScreen'){
				if(this.$refs[`elementAudio${this.modIndex}`].value){
						this.$refs[`elementAudio${this.modIndex}`].value='';
							
				}
			}
			else{
				if(this.$refs.fileInput.value){
					this.$refs.fileInput.value='';
				}
			}	
		},
		imageCheckFile(index: any,name:any) {
			let file = [];
			this.fileName = '';
			console.log("indexeee",index);
			
			if(name == 'element'){
				file = this.$refs[`element${index}`][0].files[0];
			}else{
				file = this.$refs.fileInput[0].files[0];
			}


			console.log("file",file);
			
			if (file != undefined) {
				if (this.currentScreen.screenType == 'VideoScreen') {
					this.videoCheck(file);
				} else {
					this.imgCheck(file);
				}
			}
			
		},
		imgCheck(file: any) {
			if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png' || file.type == 'image/svg') {
				let size = Number((file.size / 1048576).toFixed(2));
				if (size > 80) {
					toast.error('Image should be atmost 80 MB', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.resetFileUploads()
				} 
				else {
					this.fileName = file.name;
					this.fileStatus = false;
				}
			} else {
				toast.error('Supported only jpg, jpeg, png, svg', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				this.resetFileUploads()
			}
		},
		videoCheck(file: any) {
			if (file.type == 'video/mp4') {
				let size = Number((file.size / 1048576).toFixed(2));
				if (size > 80) {
					toast.error('Video should be atmost 80 MB', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.resetFileUploads()
				} else {
					this.fileName = file.name;
					this.fileStatus = false;
				}
			} else {
				toast.error('Supported only mp4', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				   this.resetFileUploads()
			}
		},
		resetFileUploads(){
			if(this.currentScreen.screenType != 'TabScreen'){
				if(this.$refs[`element${this.modIndex}`].value){
					this.$refs[`element${this.modIndex}`].value='';
						
				}	
			}
			else{
				if(this.$refs.fileInput.value){
					this.$refs.fileInput.value='';
				}
			}	
		},
		resetData(screen: any,index:any) {
			this.currentScreen={"screenType":""}
			
			if(screen._id){
				this.screens[this.modIndex] = JSON.parse(JSON.stringify(this.dummy[this.modIndex]));

				this.singlescreen = JSON.parse(JSON.stringify(this.originalObject));
				this.tabScreenObject = JSON.parse(JSON.stringify(this.dummyTabObj));
				this.accordionOriginalObject = JSON.parse(JSON.stringify(this.dummyAccordionObj));
				this.presentbulletPoint = JSON.parse(JSON.stringify(this.presentbulletPoint));

				
				this.tabScreenObject.label='';
				this.tabScreenObject.text='';

				this.accordionOriginalObject.label='';
				this.accordionOriginalObject.text=''

				if(this.fileName){
				    this.resetFileUploads();
					this.fileName='';
					this.fileStatus = true;
				}
				if(this.fileNameAudio){
					this.resetFileUploads();
					this.fileNameAudio ='';
					this.fileStatus = true;
				}
				this.v$.$reset();
			}
			else{
				this.resetFileUploads()
				this.fileName='';
				this.fileNameAudio ='';
				this.v$.$reset();
				(screen.screenName = 'Screen'),
				(screen.screenType = ''),
				(screen.isActive = true),
				(screen.order = ''),
				(screen.backgroundimage = ''),
				(screen.color = 'red'),
				(screen.headerLabel = ''),
				(screen.headerData = ''),
				(screen.optionsLabel = ''),
				(screen.optionsData = ''),
				(screen.options = []),
				(screen.accordions = []),
				(screen.textbullets = []),
				(screen.tabs = []),
				(screen.correctAnswer = false),
				(screen.answerVisibility = true),
				(screen.validationScreenName = false),
				(screen.isShowPreview = false),
				(screen.toggleSourceInput = false),
				(screen.audioUrl = ''),
				(screen.imageUrl = ''),
				(screen.videoUrl = ''),
				(screen.isScreenActive = true);
				this.enableUpload = true;

			}
				this.screenSelect(this.screens[this.modIndex], this.modIndex);
				console.log("RESETthis.currentScreen",this.currentScreen)
		},
		addScreen(): any {
			
			this.enableUpload = true;
			let freshObj:any = {
				screenName: 'Screen',
				screenType: '',
				isActive: false,
				order: '',
				backgroundimage: '',
				color: 'red',
				headerLabel: '',
				headerData: '',
				optionsLabel: '',
				optionsData: '',
				options: [],
				accordions: [],
				textbullets: [],
				tabs: [],
				correctAnswer: false,
				answerVisibility: true,
				validationScreenName: false,
				isShowPreview: false,
				toggleSourceInput: false,
				audioUrl: '',
				imageUrl: '',
				videoUrl: '',
				isScreenActive: false
			}
			this.screens.push(freshObj);

			this.addObjectCopy = {...freshObj}
			
			
		},
		preview(screenSelection: any, screenId: any): any {
			if (screenSelection == 'TextBoxScreen') {
				console.log(window.location.origin);
				window.open(`${window.location.origin}/admin/template/text/${screenId}`, '_blank');
			} else if (screenSelection == 'RadioScreen') {
				window.open(`${window.location.origin}/admin/template/radio/${screenId}`, '_blank');
			} else if (screenSelection == 'CheckBoxScreen') {
				window.open(`${window.location.origin}/admin/template/checkbox/${screenId}`, '_blank');
			} else if (screenSelection == 'VerticallyAccordionScreen') {
				window.open(`${window.location.origin}/admin/template/vaccordion/${screenId}`, '_blank');
			} else if (screenSelection == 'HorizantallyAccordionScreen') {
				window.open(`${window.location.origin}/admin/template/haccordion/${screenId}`, '_blank');
			} else if (screenSelection == 'TabScreen') {
				window.open(`${window.location.origin}/admin/template/tab/${screenId}`, '_blank');
			} else if (screenSelection == 'TextWithBulletPointsScreen') {
				window.open(`${window.location.origin}/admin/template/textbullet/${screenId}`, '_blank');
			} else if (screenSelection == 'VideoScreen') {
				window.open(`${window.location.origin}/admin/template/video/${screenId}`, '_blank');
			}
		},

		addOptions(moduleIndex: any, optionValue: string) {
			this.v$.radioScreenObject.$touch();
			if((!this.v$.radioScreenObject.$invalid)&&this.currentScreen.options.length <=2){
				this.v$.$reset();
			if (optionValue != '') {
				
				this.screens[moduleIndex].optionsData = this.radioScreenObject.optionsData
				if (this.screens[moduleIndex].screenType == 'RadioScreen') {
					this.saveRadiooptions(moduleIndex);
				} else {
					this.saveCheckBoxOptions(moduleIndex);
				}
			}
			}
		},

		onDelete(optionIndex: any, moduleIndex: any) {
			if (this.screens[moduleIndex].screenType == 'RadioScreen') {
				this.onradioDelete(optionIndex, moduleIndex);
			} else {
				this.onCheckBoxOptionsDelete(optionIndex, moduleIndex);
			}
		},
		onCheckBoxOptionsDelete(optionIndex: any, moduleIndex: any) {
			this.screens[moduleIndex].options.splice(optionIndex, 1);
		},
		// backtocourseEdit() {
		// 	console.log("Ssss44444",this.$route.params)
		// 	this.$router.push({ name: 'edit-course', params: { id: this.$route.params.courseId } });
		// },
		saveCheckBoxOptions(moduleIndex: any): any {
			this.screens[moduleIndex].options.push({
				label: this.radioScreenObject.optionsData,
				isCorrect: this.screens[moduleIndex].correctAnswer || false
			});
			(this.screens[moduleIndex].correctAnswer = ''), (this.radioScreenObject.optionsData = '');
		},
		async addOrUpdateScreen(moduleIndex: any) {
			console.log("hello");
			console.log("screenssss",this.screens);
			
			this.screens[moduleIndex].isActive = false;
			this.screens[moduleIndex].isShowPreview = true;
			let errorCondition = this.v$.currentScreen.$errors;
			// stop here if form is invalid
			if(this.currentScreen.options.length > 1 || this.currentScreen.options.length >= 1 || errorCondition.length == 0) {
				// this.v$.$reset();
				if (errorCondition.length == 0) {
				this.v$.$reset();
				var finalpayload;
				// delete this.screens[moduleIndex]['isActive'];
				let date = new Date();
				finalpayload = {
					screens: [
						{
							createdBy: this.userInfo.sub,
							updatedBy: this.userInfo.sub,
							createdAt: date,
							updatedAt: date,
							screenName: this.screens[moduleIndex].screenName,
							screenType: this.screens[moduleIndex].screenType,
							order: this.screens[moduleIndex].order,
							screenStyles: {
								backgroundimage: this.screens[moduleIndex].backgroundimage,
								color: this.screens[moduleIndex].color
							},
							data: {
								headerLabel: this.screens[moduleIndex].headerLabel,
								headerData: this.screens[moduleIndex].headerData,
								optionsLabel: this.screens[moduleIndex].optionsLabel,
								optionsData: this.screens[moduleIndex].optionsData,
								options: this.screens[moduleIndex].options,
								accordions: this.screens[moduleIndex].accordions,
								textbullets: this.screens[moduleIndex].textbullets,
								tabs: this.screens[moduleIndex].tabs,
								imageUrl: this.screens[moduleIndex].imageUrl,
								audioUrl: this.screens[moduleIndex].audioUrl,
								videoUrl: this.screens[moduleIndex].videoUrl,
								isScreenActive: this.screens[moduleIndex].isScreenActive
							},
							_id: this.screens[moduleIndex]._id
						}
					]
				};
				if (this.screens[moduleIndex]._id == undefined) {
					this.screens[moduleIndex].isActive = !this.screens[moduleIndex].isActive
					this.saveScreens(finalpayload, moduleIndex);
				} else {
					this.updateScreen(finalpayload, this.screens[moduleIndex]._id);
				}
			}

			}
			
		},
		uniqueScreenName(moduleIndex:any){
            this.screens[moduleIndex].validationScreenName = false;
            this.validCode = this.screens[moduleIndex].screenName;
			this.currentScreen.screenType == 'VideoScreen'?(this.currentScreen.videoUrl ==''? (this.currentScreen.videoUrl = 'exist',this.videoScreen = true): this.videoScreen = true):'';
            const uniqueValues = new Set(this.screens.map((v: any) => v.screenName));
            if (uniqueValues.size < this.screens.length) {
                this.screens[moduleIndex].validationScreenName = true;
                return false;
            }
			else{
				return true;
			}
        },
		async audioUpload(audioFile:any,moduleIndex:any){
			 if (audioFile.type == 'audio/mpeg') {
				console.log("Inside Audio")
				this.currentScreen.screenType !== 'TabScreen'? await this.sendData(audioFile):'';
				this.screens[moduleIndex].audioUrl = this.audioUrl;
				this.addOrUpdateScreen(moduleIndex);
			} 
		},
		async createPayload(moduleIndex: any) {
			this.currentScreen = this.screens[moduleIndex];
			this.v$.$touch();
			let unique = this.uniqueScreenName(moduleIndex);
		
			if (!this.v$.currentScreen.$invalid && unique) {
				let file = [];
				let audioFile = [];
				if(this.currentScreen.screenType !== 'VideoScreen')
				this.currentScreen.screenType !== 'TabScreen' ? (audioFile = this.$refs[`elementAudio${moduleIndex}`][0].files[0]) : (audioFile = this.$refs.fileInputAudio[0].files[0]);
				if (this.currentScreen.screenType !== 'VerticallyAccordionScreen' && this.currentScreen.screenType !== 'HorizantallyAccordionScreen') {
					this.currentScreen.screenType !== 'TabScreen' ? (file = this.$refs[`element${moduleIndex}`][0].files[0]) : (file = this.$refs.fileInput[0].files[0]);
					if (file) {
						if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png' || file.type == 'image/svg') {
							console.log('imageData', file);
							await this.sendData(file);
							this.screens[moduleIndex].imageUrl = this.imageUrl;
							audioFile != undefined ? '': this.addOrUpdateScreen(moduleIndex);
						} else if (file.type == 'video/mp4') {
							await this.sendData(file);
							this.screens[moduleIndex].videoUrl = this.videoUrl;
							this.$refs.player.load();
							this.addOrUpdateScreen(moduleIndex);
						} 
					} 
					if(audioFile && !this.videoScreen){
						await this.audioUpload(audioFile,moduleIndex)
					}
					else if(this.videoScreen && (file == undefined && this.currentScreen.videoUrl =='exist' ) ){
                        this.currentScreen.videoUrl ='';
                            toast.error('Upload Video', {
                            timeout: 1500,
                            closeOnClick: true,
                            closeButton: 'button',
                            icon: true
                            });
                    }
					else if(file == undefined && (audioFile == undefined || audioFile)) {
						this.addOrUpdateScreen(moduleIndex);
					}
				} 
				else  {
					this.fileNameAudio ? await this.audioUpload(audioFile,moduleIndex):this.addOrUpdateScreen(moduleIndex);
				}
			} else {
				toast.error('validation failed', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			}
			// this.screens[moduleIndex].isShowPreview = true;
		},

		async uploadImage(data: any) {
			console.log("data",data)
			return new Promise((resolve) => {
				const reader: any = new FileReader();
				reader.onload = function () {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(data);
			});
		},
		async sendData(data: any) {
			this.spinnerLoading = true;
			
			const file = await this.uploadImage(data);
			let fileExtension = data.name.split('.').pop();
			let id = uuidv4();
			let findId = this.checkIds.filter((x: any) => x === id);
			if (findId.length > 0) {
				id = uuidv4();
			}
			var payload;
			// if (!this.videoScreen) {
			// 	payload = { fileData: file, name: `${id}.${fileExtension}` };
			// } else {
				payload = { fileData: file, name: `${id}.${fileExtension}` };
			// }
			console.log('payload', payload);
			try {
				await this.$http
					.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${process.env.VUE_APP_FOLDER_NAME}/file/upload`, payload)
					//.post(`http://localhost:4030/services/filemanagement/bucket/${process.env.VUE_APP_TRAINING_API_PATH}/file/upload`, payload)
					.then((response: any) => {
						if (response.status == 200) {
							console.log('ISUE', response.data);
							this.videoScreen == true ? (this.videoUrl = response.data) : (fileExtension == 'mp3' ? (this.audioUrl = response.data):(this.imageUrl = response.data));
							
							console.log("this.audioUrl",this.audioUrl) 

							this.spinnerLoading = false;
						} else {
							console.log('response status', response.status);
						}
					});
			} catch (err) {
				console.error(err);
			}
		},

		async saveScreens(finalpayload: any, moduleIndex: any) {
			const toast = useToast();
			// this.spinnerLoading = true;
			
			await this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/screens/add`, finalpayload)
				.then((response: any) => {
					this.screens[moduleIndex]._id = response.data.updatedScreenId[0];
					this.refreshScreens(moduleIndex);
					toast.info('Screen Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});	
				})
				.catch((error: any) => {
					console.log('ee', error);
					// this.spinnerLoading = false;
					toast.error('Screen not Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
		},
		onradioDelete(optionIndex: any, moduleIndex: any) {
			if (this.screens[moduleIndex].options[optionIndex].isCorrect == true) {
				this.screens[moduleIndex].answerVisibility = true;
			}
			this.screens[moduleIndex].options.splice(optionIndex, 1);
		},

		saveRadiooptions(moduleIndex: any): any {
			var visibility = this.screens[moduleIndex].correctAnswer;
			if (visibility == true) {
				this.screens[moduleIndex].answerVisibility = false;
			}
			this.screens[moduleIndex].options.push({
				label: this.radioScreenObject.optionsData,
				isCorrect: this.screens[moduleIndex].correctAnswer || false
			});
			(this.screens[moduleIndex].correctAnswer = ''), (this.radioScreenObject.optionsData = '');
		},

		saveVerticallyChildAccordionData(moduleIndex: any) {
				this.v$.accordionOriginalObject.$touch();
				
				if((!this.v$.accordionOriginalObject.$invalid )&& this.currentScreen.accordions.length <=3){
					this.v$.$reset();
						this.screens[moduleIndex].accordions.push({
							label: this.accordionOriginalObject.label,
							text: this.accordionOriginalObject.text,
							isActive: false
						});
					(this.updateButton = false), (this.accordionOriginalObject.label = ''), (this.accordionOriginalObject.text = '');
				}
		},

		async saveTabData(moduleIndex: any) {
			
			this.v$.tabScreenObject.$touch();
			
			if(!this.v$.tabScreenObject.$invalid){
				this.v$.$reset();
				if (this.$refs.fileInput[0].files[0] != undefined) {
						await this.sendData(this.$refs.fileInput[0].files[0]);
					
				}
				if (this.$refs.fileInputAudio[0].files[0] != undefined) {
						await this.sendData(this.$refs.fileInputAudio[0].files[0]);
					
				}
				 else {
					this.imageUrl = this.screens[moduleIndex].imageUrl;
				}
			
			
				this.screens[moduleIndex].tabs.push({
					label: this.tabScreenObject.label,
					text: this.tabScreenObject.text,
					imageUrl: this.imageUrl,
					audioUrl: this.audioUrl,
					isActive: false
				});
				(this.updateButton = false), (this.tabScreenObject.audioUrl = ''), (this.tabScreenObject.label = ''), (this.tabScreenObject.text = ''), (this.tabScreenObject.imageUrl = '');
				this.fileName = '';
				this.fileNameAudio = '';

			}
			// }
		},

		async updateTabData(moduleIndex: any) {

			this.v$.tabScreenObject.$touch();
			if(!this.v$.tabScreenObject.$invalid){
				this.v$.$reset();
			if (this.$refs.fileInput[0].files[0] != undefined) {
				console.log('if');
				await this.sendData(this.$refs.fileInput[0].files[0]);
			} else {
				console.log('else');
				this.imageUrl = this.screens[moduleIndex].tabs.imageUrl;
			}
			
			
			(this.screens[moduleIndex].tabs[this.optionListIndex].label = this.tabScreenObject.label), 
			(this.screens[moduleIndex].tabs[this.optionListIndex].text = this.tabScreenObject.text);
			this.screens[moduleIndex].tabs[this.optionListIndex].imageUrl = this.imageUrl;
			this.screens[moduleIndex].tabs[this.optionListIndex].audioUrl = this.screens[moduleIndex].tabs.audioUrl;
				// console.log('imageUrl', this.imageUrl);

			this.fileName = '';
			this.fileNameAudio = '';
			this.optionListIndex = '';
			this.tabScreenObject.label = undefined;
			this.tabScreenObject.text = undefined;
			this.screens[moduleIndex].tabs.imageUrl = '';
			this.screens[moduleIndex].tabs.audioUrl = '';
			this.updateButton = false;
			}
		
		},
		onTabEdit(optionIndex: any, moduleIndex: any) {
			this.optionListIndex = optionIndex;
			this.updateButton = true;

			this.tabScreenObject.label = this.screens[moduleIndex].tabs[optionIndex].label;
			this.tabScreenObject.text = this.screens[moduleIndex].tabs[optionIndex].text;
			this.tabScreenObject.imageUrl = this.screens[moduleIndex].tabs[optionIndex].imageUrl;
			this.tabScreenObject.audioUrl = this.screens[moduleIndex].tabs[optionIndex].audioUrl;
			this.dummyTabObj = {...this.tabScreenObject}
		},
		onTabDelete(optionIndex: any, moduleIndex: any) {
			this.screens[moduleIndex].tabs.splice(optionIndex, 1);
		},
		onVerticallyChildAccordionDelete(optionIndex: any, moduleIndex: any) {
			this.screens[moduleIndex].accordions.splice(optionIndex, 1);
		},
		updateVerticallyChildAccordionData(moduleIndex: any) {
			this.v$.accordionOriginalObject.$touch();
			if(!this.v$.accordionOriginalObject.$invalid){
				this.v$.$reset();
			(this.screens[moduleIndex].accordions[this.optionListIndex].label = this.accordionOriginalObject.label), 
			(this.screens[moduleIndex].accordions[this.optionListIndex].text = this.accordionOriginalObject.text);

			this.optionListIndex = '';
			this.accordionOriginalObject.label = '';
			this.accordionOriginalObject.text = '';
			this.updateButton = false;
			}
		},
		updatetextbulletData(moduleIndex: any) {
			this.v$.presentbulletPoint.$touch();
			if(!this.v$.presentbulletPoint.$invalid){
			this.v$.$reset();
			this.screens[moduleIndex].textbullets[this.optionListIndex].text = this.presentbulletPoint.text;
			this.optionListIndex = '';
			this.presentbulletPoint.text = '';
			this.updateButton = false;
			}
		},
		savetextbulletData(moduleIndex: any) {
			// if(this.screens[moduleIndex].textbullets.text){
			this.v$.presentbulletPoint.$touch();

			if(!this.v$.presentbulletPoint.$invalid){
				this.v$.$reset();
			this.screens[moduleIndex].textbullets.push({
				text: this.presentbulletPoint.text,
				isActive: false
			});
			(this.updateButton = false), (this.presentbulletPoint.text = '');
			}
		},
		ontextbulletDelete(optionIndex: any, moduleIndex: any) {
			console.log('sssss');
			this.screens[moduleIndex].textbullets.splice(optionIndex, 1);
		},
		ontextbulletEdit(optionIndex: any, moduleIndex: any) {
			this.optionListIndex = optionIndex;
			this.updateButton = true;
			this.presentbulletPoint.text = this.screens[moduleIndex].textbullets[optionIndex].text;

			this.dummybulletPoint={...this.presentbulletPoint}
		},
		onVerticallyChildAccordionEdit(optionIndex: any, moduleIndex: any) {
			this.optionListIndex = optionIndex;
			this.updateButton = true;
			this.accordionOriginalObject.label = this.screens[moduleIndex].accordions[optionIndex].label;
			this.accordionOriginalObject.text = this.screens[moduleIndex].accordions[optionIndex].text;

			this.dummyAccordionObj={...this.accordionOriginalObject}
		},

		async getallScreens() {
			this.totalScreens = [];
			this.screens = [];
			this.dummyScreens = [];
			const toast = useToast();
			this.spinnerLoading = true;
			await this.$http.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/screens/getAll`).then((res: any) => {
				// this.spinnerLoading = false;
				this.totalScreens = res.data;

				for (var i = 0; i < this.totalScreens.length; i++) {
					
					let a = {
						screenName: this.totalScreens[i].screenName,
						screenType: this.totalScreens[i].screenType,
						order: this.totalScreens[i].order,
						backgroundimage: this.totalScreens[i].screenStyles.backgroundimage,
						color: this.totalScreens[i].screenStyles.color,
						headerLabel: this.totalScreens[i].data.headerLabel,
						headerData: this.totalScreens[i].data.headerData,
						optionsLabel: this.totalScreens[i].data.optionsLabel,
						optionsData: this.totalScreens[i].data.optionsData,
						options: this.totalScreens[i].data.options,
						accordions: this.totalScreens[i].data.accordions,
						tabs: this.totalScreens[i].data.tabs,
						textbullets: this.totalScreens[i].data.textbullets,
						audioUrl: this.totalScreens[i].data.audioUrl,
						imageUrl: this.totalScreens[i].data.imageUrl,
						videoUrl: this.totalScreens[i].data.videoUrl,
						isScreenActive: this.totalScreens[i].data.isScreenActive,
						_id: this.totalScreens[i]._id
					}
					this.screens.push(a);					
					this.dummyScreens.push({...a})					
					// for(let key in this.screens[i]){
					// 	if(key == 'isScreenActive'){break;}
					// }
					if (!Object.keys(this.totalScreens[i].data).includes('isScreenActive')) {
						this.screens[i]['isScreenActive'] = true;
					}
				}
				this.dummy = JSON.parse(JSON.stringify(this.screens));
			});
			this.spinnerLoading = false;
		},
		async updateScreen(singlescreen: any, singlescreenId: any) {
			
			// this.singlescreen.
			delete singlescreen.screens[0]._id;
			var screenId = singlescreenId;
			singlescreen.screens[0].updatedBy = this.userInfo.sub;
			singlescreen.screens[0].updatedAt = new Date();
			
			this.tabScreenObject={...this.dummyTabObj}
			this.TabCopyObj={...this.tabScreenObject}

			this.tabScreenObject.label=''
			this.tabScreenObject.text=''

			const toast = useToast();
			this.spinnerLoading = true;
			await this.$http
				.post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/screen/${screenId}/update`, singlescreen.screens[0])
				.then((res: any) => {
					this.spinnerLoading = false;
					toast.info('Screen updated', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					// this.refreshScreens(this.modIndex);
					this.tabScreenObject={...this.dummyTabObj}	
				})
				.catch((error: any) => {
					this.spinnerLoading = false;
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
				this.tabScreenObject.label=''
				this.tabScreenObject.text=''
				
			this.fileStatus = true;
			await this.getallScreens();
			await this.screenSelect(this.screens[this.modIndex], this.modIndex);
			this.fileName='';
			this.fileNameAudio='';
			console.log('this.totalScreens',this.totalScreens)
			let updateModuleCondition = this.totalScreens.every((obj:any)=>{
				return obj.data.isScreenActive == false;
			});
			if(updateModuleCondition){
				console.log('updatemodule To DEactive')
				console.log('do inactive')
				await this.updateModule(this.moduleInformation,false)
			}else{
				console.log('dont update')
				console.log('do active')
				await this.updateModule(this.moduleInformation,true)
			}
		},
		async getModules() {
			this.spinnerLoading = true;
			await this.$http
			.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/get`)
			.then((res: any) => {
				this.spinnerLoading = false;
				this.moduleInformation=res.data[0].modules[0];
				console.log("moduleInformationmoduleInformation",this.moduleInformation)
				
			})
			.catch((error: any) => {
				this.spinnerLoading = false;
				toast.error(error, {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
			});

        },
		async updateModule( singlemodule: any,boolean:any) {
            this.spinnerLoading = true;
            var moduleid = singlemodule._id;
            singlemodule.moduleToggle = true;
            delete singlemodule._id;
            // delete singlemodule.moduleToggle;
            delete singlemodule.errorClass;
            singlemodule.updatedBy = this.userInfo.sub;
            singlemodule.imageUrl=this.imageUrl
            singlemodule.updatedAt = new Date();
            singlemodule.isModuleActive=boolean;
			delete singlemodule.questionaire;
            await this.$http
                .post(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/module/${this.$route.params.moduleId}/update`, singlemodule)
                .then((response: any) => {
                    this.spinnerLoading = false;
                    // toast.info('Module Updated', {
                    //  timeout: 1000,
                    //  closeOnClick: true,
                    //  closeButton: 'button',
                    //  icon: true
                    // });
                    // this.getAllModules();
                })
                .catch((error: any) => {
                    // this.spinnerLoading = false;
                    // toast.error('Module not Updated', {
                    //  timeout: 1000,
                    //  closeOnClick: true,
                    //  closeButton: 'button',
                    //  icon: true
                    // });
                });
        },
		async getSingleCourse() {
			console.log('ss');
			this.spinnerLoading = true;
			await this.$http
				.get(`${process.env.VUE_APP_TRAINING_API_URL}/course/${this.$route.params.courseId}/get`)
				.then((res: any) => {
					this.course = res.data;
					console.log('sss', this.course);
					this.course.forEach((course: any) => {
						this.moduleName = course.modules.filter((module: any) => module._id == this.$route.params.moduleId);
					});
				})
				.catch((error: any) => {
					toast.error(error, {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
			this.dummy = JSON.parse(JSON.stringify(this.screens));
			this.spinnerLoading = false;
		},
		async getallImagesfromS3() {
			try {
				await this.$http
					.get(`${process.env.VUE_APP_FILE_API_URL}/bucket/files/getall`)
					//.get(`http://localhost:4030/services/filemanagement/bucket/files/getall`)
					.then((response: any) => {
						if (response.status == 200) {
							for (var i = 0; i < response.data.Contents.length; i++) {
								let ids = response.data.Contents[i].Key.substring(0, response.data.Contents[i].Key.lastIndexOf('.'));
								this.checkIds.push(ids);
							}
						}
					});
			} catch (err) {
				console.error(err);
			}
		}
	},
	async mounted() {
		this.getallImagesfromS3()
		await this.getModules()
		this.pagetype = this.$route.fullPath.includes('update') ? 'update' : 'edit';
		await this.getallScreens();
		await this.getSingleCourse();
		this.routesArray=[
			{name:'Courses',routeName:'courses'},
			{name:this.course[0].courseName,routeName:'edit-course',id:this.$route.params.courseId}, 	
			{name:this.moduleName[0].moduleName,routeName:'manage-screens'}
		]
	}
});
</script>